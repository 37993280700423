'use strict';

angular.module('cottonMeApp', [ //
	'adama-web' //
]);

'use strict';

angular.module('cottonMeApp').controller('WatchlistProductListCtrl', function($log, Principal, AlertService, NgTableParams, Product, watchListConf) {
	$log.getInstance('app.controllers.WatchlistProductListCtrl').debug('WatchlistProductListCtrl', watchListConf);
	var ctrl = this;
	ctrl.watchListConf = watchListConf;

	ctrl.tableParams = new NgTableParams({}, {
		total: 0,
		getData: function(params) {
			var sort = params.sorting();
			var sortValues = [];
			if (!angular.equals({}, sort)) {
				for (var key in sort) {
					if (sort.hasOwnProperty(key)) {
						sortValues.push(key + ',' + sort[key]);
					}
				}
			}
			var requestParams = angular.extend({}, {
				page: params.page() - 1,
				size: params.count(),
				sort: sortValues,
				search: params.filter().$
			}, {
				watchStatus: watchListConf.watchStatus
			});
			return Product.query(requestParams).$promise.then(function(entities) {
				angular.forEach(entities, function(entity) {
					if (entity.mustShippedDate) {
						entity.mustShippedDate = new Date(entity.mustShippedDate);
					}
				});
				params.total(entities.$metadata.totalItems);
				return entities;
			});
		}
	});

	ctrl.updateMustShippedDate = function(product) {
		if(product.watchStatus === 'URGENT')
		{
			product.$setUrgent().then(function() {
				AlertService.success('PRODUCT_WATCH_LIST_SUCCESS_MESSAGE');
				product.editMustDate = false;
			}).catch(function() {
				AlertService.error('CRUD_SAVE_ERROR');
			});
			return true;
		}

		if(product.watchStatus === 'WATCH')
		{
			product.$setWatch().then(function() {
				AlertService.success('PRODUCT_WATCH_LIST_SUCCESS_MESSAGE');
				product.editMustDate = false;
			}).catch(function() {
				AlertService.error('CRUD_SAVE_ERROR');
			});
			return true;
		}

		return true;
	};
});


'use strict';

angular.module('cottonMeApp').component('productUntrackSelected', {
	templateUrl: 'modules/watchlist/product-untrack-selected.html',
	bindings: {
		tableParams: '<',
		reloadAfterAction: '<'
	},
	controller: function($scope, Product) {
		var ctrl = this;
		ctrl.isListEmpty = true;
		var filterProduct = function(entityList) {
			return entityList.reduce(function(accu, entity) {
				if (entity.isSelected && entity.shirtProduct) {
					accu.push(entity);
				}
				return accu;
			}, []);
		};
		$scope.$watch(function() {
			return ctrl.tableParams.data;
		}, function() {
			if (ctrl.tableParams.data) {
				var selectedEntities = filterProduct(ctrl.tableParams.data);
				ctrl.isListEmpty = selectedEntities.length === 0;
			} else {
				ctrl.isListEmpty = true;
			}
		}, true);
		ctrl.isUiBlocked = false;
		ctrl.activate = function() {
			ctrl.isUiBlocked = true;
			var ids = filterProduct(ctrl.tableParams.data).map(function(entity) {
				return entity.id;
			});
			var idList = ids.join(',');
			Product.setUntracked({
				idList: idList
			}).$promise.then(function() {
				if (ctrl.reloadAfterAction) {
					ctrl.tableParams.reload();
				}
			}).finally(function() {
				ctrl.isUiBlocked = false;
			});
		};
	}
});


'use strict';

angular.module('cottonMeApp').config(function($stateProvider) {
	$stateProvider.state('app.watchlist', {
		abstract: true,
		url: '/watchlist',
		template: '<ui-view></ui-view>'
	});

	$stateProvider.state('app.watchlist.list', {
		url: '',
		templateUrl: 'modules/watchlist/watchlist-list.html',
		controller: 'WatchlistProductListCtrl',
		controllerAs: 'ctrl',
		resolve: {
			watchListConf: function() {
				return {
					watchStatus: ['URGENT', 'WATCH']
				};
			}
		},
		data: {
			pageTitle: 'WATCH_LIST_TITLE',
			authorities: ['ROLE_MANAGER', 'ROLE_ADMIN']
		}
	});
});

angular.module('cottonMeApp').config(function($translateProvider) {
	$translateProvider.translations('fr', {
		'WATCH_LIST': 'Watch List',
		'WATCH_LIST_TITLE': 'Watch List',
		'PRODUCT_BEST_SHIPPED_DATE': 'Best Shipped Date',
		'PRODUCT_MAX_SHIPPED_DATE': 'Max Shipped Date',
		'PRODUCT_BONUS_STATUS': 'Bonus/Malus',
		'PRODUCT_WATCH_STATUS': 'Watch status',
		'PRODUCT_MUST_SHIPPED_DATE': 'Must Shipped Date',
		'PRODUCT_SHIRT_BTN_UNTRACK_SELECTED': 'Untrack selected'
	});

	$translateProvider.translations('en', {
		'WATCH_LIST': 'Watch List',
		'WATCH_LIST_TITLE': 'Watch List',
		'PRODUCT_BEST_SHIPPED_DATE': 'Best Shipped Date',
		'PRODUCT_MAX_SHIPPED_DATE': 'Max Shipped Date',
		'PRODUCT_BONUS_STATUS': 'Bonus/Malus',
		'PRODUCT_WATCH_STATUS': 'Watch status',
		'PRODUCT_MUST_SHIPPED_DATE': 'Must Shipped Date',
		'PRODUCT_SHIRT_BTN_UNTRACK_SELECTED': 'Untrack selected'
	});

	$translateProvider.translations('zh', {
		'WATCH_LIST': 'Watch List',
		'WATCH_LIST_TITLE': 'Watch List',
		'PRODUCT_BEST_SHIPPED_DATE': 'Best Shipped Date',
		'PRODUCT_MAX_SHIPPED_DATE': 'Max Shipped Date',
		'PRODUCT_BONUS_STATUS': 'Bonus/Malus',
		'PRODUCT_WATCH_STATUS': 'Watch status',
		'PRODUCT_MUST_SHIPPED_DATE': 'Must Shipped Date',
		'PRODUCT_SHIRT_BTN_UNTRACK_SELECTED': 'Untrack selected'
	});
});

angular.module('cottonMeApp').config(function(menuServiceProvider) {
	menuServiceProvider.addItem({
		labelKey: 'WATCH_LIST',
		url: '#/app/watchlist',
		iconClass: 'fa fa-dashboard',
		rank: 1,
		badge: {
			event: 'WATCH_LIST-event',
			color: 'watchlist'
		}
	});
});

angular.module('cottonMeApp').factory('watchListService', function($http, $timeout, $rootScope, $log, $state, $q, adamaConstant) {
	var api = {};
	var monitoringDelayInMs = adamaConstant.dashboardRefreshInMiliss || 30000;
	var timer;

	var update = function() {
		return $http({
			method: 'GET',
			url: adamaConstant.apiBase + 'watchlist',
			ignoreLoadingBar: true
		}).then(function(response) {
			var watchlist = response.data;
			$rootScope.$broadcast(watchlist.type + '-event', {
				value: watchlist.value
			});
			
			return response.data;
		});
	};

	var resetTimer = function(delay) {
		if (!!timer) {
			$timeout.cancel(timer);
		}
		timer = $timeout(function() {
			var promise = $q.when();
			if ($state.current.name.indexOf('app.') === 0){
				promise = update();
			}
			promise.finally(function() {
				resetTimer(monitoringDelayInMs);
			});
		}, delay);
	};

	api.forceUpdate = function() {
		resetTimer(0);
	};

	api.startMonitor = function() {
		resetTimer(2000);
	};

	return api;

});

angular.module('cottonMeApp').run(function(watchListService) {
	watchListService.startMonitor();
});


'use strict';

angular.module('cottonMeApp').component('setUrlPassword', {
	templateUrl: 'modules/user/set-url-create-password.html',
	bindings: {
		user: '<'
	},
	controller: function($log, $scope, adamaConstant) {
		var log = $log.getInstance('app.component.setUrlPassword');
		var ctrl = this;
		$scope.$watch(function() {
			log.debug('watch user', ctrl.user);
			return ctrl.user;
		}, function() {
			if (ctrl.user) {
				ctrl.user.urlCreatePassword = adamaConstant.urlCreatePassword;
			}
		});
		$scope.$watch(function() {
			log.debug('watch authority', ctrl.user && ctrl.user.authority);
			return ctrl.user && ctrl.user.authority;
		}, function(newValue) {
			if (ctrl.user) {
				ctrl.user.urlCreatePassword = adamaConstant.urlCreatePassword;
				if (newValue !== 'ROLE_ADMIN') {
					ctrl.user.urlCreatePassword = adamaConstant.urlCreatePasswordMobile;
				}
			}
		});
	}
});

'use strict';

angular.module('cottonMeApp').config(function($translateProvider) {
	$translateProvider.translations('fr', {
		'USER_LIST_TENANT': 'Client B2B',
		'USER_FORM_TENANT': 'Client B2B'
	});

	$translateProvider.translations('en', {
		'USER_LIST_TENANT': 'Customer B2B',
		'USER_FORM_TENANT': 'Customer B2B'
	});

	$translateProvider.translations('zh', {
		'USER_LIST_TENANT': 'Customer B2B',
		'USER_FORM_TENANT': 'Customer B2B'
	});
});

angular.module('cottonMeApp').config(function(menuServiceProvider) {
	menuServiceProvider.addItem({
		labelKey: 'USER_MENU',
		url: '#/app/users',
		iconClass: 'fa fa-users',
		rank: 901
	});
});

'use strict';

angular.module('cottonMeApp').component('shippingPackageSyncCneInfo', {
	templateUrl: 'modules/shipping-package/shipping-package-sync-cne-info.html',
	bindings: {
		tableParams: '<',
		reloadAfterAction: '<'
	},
	controller: function(ShippingPackage) {
		var ctrl = this;
		ctrl.isUiBlocked = false;
		ctrl.activate = function() {
			ctrl.isUiBlocked = true;
			ShippingPackage.syncCNEInfo().$promise.then(function() {
				if (ctrl.reloadAfterAction) {
					ctrl.tableParams.reload();
				}
			}).finally(function() {
				ctrl.isUiBlocked = false;
			});
		};
	}
});

'use strict';

angular.module('cottonMeApp').component('shippingPackageSetAsShippedSelected', {
	templateUrl: 'modules/shipping-package/shipping-package-set-as-shipped-selected.html',
	bindings: {
		tableParams: '<',
		reloadAfterAction: '<'
	},
	controller: function($scope, $filter, ShippingPackage) {
		var ctrl = this;
		ctrl.isListEmpty = true;
		var filterProduct = function(entityList) {
			return entityList.reduce(function(accu, entity) {
				if (entity.isSelected) {
					accu.push(entity);
				}
				return accu;
			}, []);
		};
		$scope.$watch(function() {
			return ctrl.tableParams.data;
		}, function() {
			if (ctrl.tableParams.data) {
				var selectedEntities = filterProduct(ctrl.tableParams.data);
				ctrl.isListEmpty = selectedEntities.length === 0;
			} else {
				ctrl.isListEmpty = true;
			}
		}, true);
		ctrl.isUiBlocked = false;
		ctrl.activate = function() {
			ctrl.isUiBlocked = true;
			var ids = filterProduct(ctrl.tableParams.data).map(function(entity) {
				return entity.id;
			});
			var idList = ids.join(',');
			ShippingPackage.setAsShippedSelected({
				idList: idList
			}).$promise.then(function() {
				if (ctrl.reloadAfterAction) {
					ctrl.tableParams.reload();
				}
			}).finally(function() {
				ctrl.isUiBlocked = false;
			});
		};
	}
});


'use strict';

angular.module('cottonMeApp').component('shippingPackageSetAsDeliveredSelected', {
	templateUrl: 'modules/shipping-package/shipping-package-set-as-delivered-selected.html',
	bindings: {
		tableParams: '<',
		reloadAfterAction: '<'
	},
	controller: function($scope, $filter, ShippingPackage) {
		var ctrl = this;
		ctrl.isListEmpty = true;
		var filterProduct = function(entityList) {
			return entityList.reduce(function(accu, entity) {
				if (entity.isSelected) {
					accu.push(entity);
				}
				return accu;
			}, []);
		};
		$scope.$watch(function() {
			return ctrl.tableParams.data;
		}, function() {
			if (ctrl.tableParams.data) {
				var selectedEntities = filterProduct(ctrl.tableParams.data);
				ctrl.isListEmpty = selectedEntities.length === 0;
			} else {
				ctrl.isListEmpty = true;
			}
		}, true);
		ctrl.isUiBlocked = false;
		ctrl.activate = function() {
			ctrl.isUiBlocked = true;
			var ids = filterProduct(ctrl.tableParams.data).map(function(entity) {
				return entity.id;
			});
			var idList = ids.join(',');
			ShippingPackage.setAsDeliveredSelected({
				idList: idList
			}).$promise.then(function() {
				if (ctrl.reloadAfterAction) {
					ctrl.tableParams.reload();
				}
			}).finally(function() {
				ctrl.isUiBlocked = false;
			});
		};
	}
});

'use strict';

angular.module('cottonMeApp').component('shippingPackageSetAsCancelledSelected', {
	templateUrl: 'modules/shipping-package/shipping-package-set-as-cancelled-selected.html',
	bindings: {
		tableParams: '<',
		reloadAfterAction: '<'
	},
	controller: function($scope, $filter, ShippingPackage) {
		var ctrl = this;
		ctrl.isListEmpty = true;
		var filterProduct = function(entityList) {
			return entityList.reduce(function(accu, entity) {
				if (entity.isSelected) {
					accu.push(entity);
				}
				return accu;
			}, []);
		};
		$scope.$watch(function() {
			return ctrl.tableParams.data;
		}, function() {
			if (ctrl.tableParams.data) {
				var selectedEntities = filterProduct(ctrl.tableParams.data);
				ctrl.isListEmpty = selectedEntities.length === 0;
			} else {
				ctrl.isListEmpty = true;
			}
		}, true);
		ctrl.isUiBlocked = false;
		ctrl.activate = function() {
			ctrl.isUiBlocked = true;
			var ids = filterProduct(ctrl.tableParams.data).map(function(entity) {
				return entity.id;
			});
			var idList = ids.join(',');
			ShippingPackage.setAsCancelledSelected({
				idList: idList
			}).$promise.then(function() {
				if (ctrl.reloadAfterAction) {
					ctrl.tableParams.reload();
				}
			}).finally(function() {
				ctrl.isUiBlocked = false;
			});
		};
	}
});

'use strict';

angular.module('cottonMeApp').component('shippingPackageRetrieveInfoForSelected', {
	templateUrl: 'modules/shipping-package/shipping-package-retrieve-info-for-selected.html',
	bindings: {
		tableParams: '<',
		reloadAfterAction: '<'
	},
	controller: function($scope, $filter, ShippingPackage) {
		var ctrl = this;
		ctrl.isListEmpty = true;
		var filterProduct = function(entityList) {
			return entityList.reduce(function(accu, entity) {
				if (entity.isSelected) {
					accu.push(entity);
				}
				return accu;
			}, []);
		};
		$scope.$watch(function() {
			return ctrl.tableParams.data;
		}, function() {
			if (ctrl.tableParams.data) {
				var selectedEntities = filterProduct(ctrl.tableParams.data);
				ctrl.isListEmpty = selectedEntities.length === 0;
			} else {
				ctrl.isListEmpty = true;
			}
		}, true);
		ctrl.isUiBlocked = false;
		ctrl.activate = function() {
			ctrl.isUiBlocked = true;
			var ids = filterProduct(ctrl.tableParams.data).map(function(entity) {
				return entity.id;
			});
			var idList = ids.join(',');
			ShippingPackage.retrieveInfoForSelected({
				idList: idList
			}).$promise.then(function() {
				if (ctrl.reloadAfterAction) {
					ctrl.tableParams.reload();
				}
			}).finally(function() {
				ctrl.isUiBlocked = false;
			});
		};
	}
});

'use strict';

angular.module('cottonMeApp').component('shippingPackageRetrieveInfoForAllPrepared', {
	templateUrl: 'modules/shipping-package/shipping-package-retrieve-info-for-all-prepared.html',
	bindings: {
		tableParams: '<',
		reloadAfterAction: '<'
	},
	controller: function(ShippingPackage) {
		var ctrl = this;
		ctrl.isUiBlocked = false;
		ctrl.activate = function() {
			ctrl.isUiBlocked = true;
			ShippingPackage.retrieveInfoForAllPrepared().$promise.then(function() {
				if (ctrl.reloadAfterAction) {
					ctrl.tableParams.reload();
				}
			}).finally(function() {
				ctrl.isUiBlocked = false;
			});
		};
	}
});

'use strict';

angular.module('cottonMeApp').component('shippingPackageProductList', {
	templateUrl: 'modules/shipping-package/shipping-package-product-list.html',
	bindings: {
		shippingPackage: '<',
		isView: '<'
	},
	controller: function(NgTableParams, ShippingPackage) {
		var ctrl = this;
		ShippingPackage.getProductList({
			id: ctrl.shippingPackage.id
		}).$promise.then(function(entities) {
			ctrl.tableParams = new NgTableParams({}, {
				dataset: entities
			});
			ctrl.tableParams.settings().counts = [];
		});
	}
});

'use strict';

angular.module('cottonMeApp').config(function($stateProvider) {
	$stateProvider.state('app.shippingPackage', {
		abstract: true,
		url: '/shippingPackages',
		template: '<ui-view></ui-view>'
	});

	$stateProvider.state('app.shippingPackage.list', {
		url: '',
		templateUrl: 'modules/shipping-package/shipping-package-list.html',
		controller: 'CrudListCtrl',
		controllerAs: 'ctrl',
		resolve: {
			EntityGenericResource: function(ShippingPackage) {
				return ShippingPackage;
			}
		},
		data: {
			pageTitle: 'SHIPPINGPACKAGE_TITLE_LIST',
			authorities: ['ROLE_MANAGER', 'ROLE_ADMIN']
		}
	});

	$stateProvider.state('app.shippingPackage.view', {
		url: '/view/:entityId',
		templateUrl: 'modules/shipping-package/shipping-package-view.html',
		controller: 'CrudViewCtrl',
		controllerAs: 'ctrl',
		resolve: {
			entity: function($stateParams, ShippingPackage) {
				return ShippingPackage.get({
					id: $stateParams.entityId
				}).$promise;
			}
		},
		data: {
			pageTitle: 'SHIPPINGPACKAGE_TITLE_VIEW',
			authorities: ['ROLE_MANAGER', 'ROLE_ADMIN']
		}
	});

	$stateProvider.state('app.shippingPackage.edit', {
		url: '/edit/:entityId',
		templateUrl: 'modules/shipping-package/shipping-package-edit.html',
		controller: 'CrudEditCtrl',
		controllerAs: 'ctrl',
		resolve: {
			entity: function($stateParams, ShippingPackage) {
				return ShippingPackage.get({
					id: $stateParams.entityId
				}).$promise;
			},
			EntityGenericResource: function(ShippingPackage) {
				return ShippingPackage;
			}
		},
		data: {
			pageTitle: 'SHIPPINGPACKAGE_TITLE_EDIT',
			authorities: ['ROLE_MANAGER', 'ROLE_ADMIN']
		}
	});

	$stateProvider.state('app.shippingPackage.create', {
		url: '/new',
		templateUrl: 'modules/shipping-package/shipping-package-edit.html',
		controller: 'CrudEditCtrl',
		controllerAs: 'ctrl',
		resolve: {
			entity: function() {
				return undefined;
			},
			EntityGenericResource: function(ShippingPackage) {
				return ShippingPackage;
			}
		},
		data: {
			pageTitle: 'SHIPPINGPACKAGE_TITLE_NEW',
			authorities: ['ROLE_MANAGER', 'ROLE_ADMIN']
		}
	});

	var openModal = function($state, $uibModal, $stateParams, controllerName, templateName, title) {
		var resolveEntity;
		if ($stateParams) {
			resolveEntity = /* @ngInject */ function(ShippingPackage) {
				return ShippingPackage.get({
					id: $stateParams.entityId
				}).$promise;
			};
		}
		$uibModal.open({
			templateUrl: 'adama-web/crud/' + templateName,
			resolve: {
				entity: resolveEntity,
				EntityGenericResource: function(ShippingPackage) {
					return ShippingPackage;
				},
				title: function() {
					return title;
				}
			},
			controller: controllerName + ' as ctrl'
		}).result.then(function() {
			$state.go('^', {}, {
				reload: true
			});
		}).catch(function() {
			$state.go('^');
		});
	};

	$stateProvider.state('app.shippingPackage.delete', {
		url: '/delete/:entityId',
		parent: 'app.shippingPackage.list',
		onEnter: function($state, $uibModal, $stateParams) {
			openModal($state, $uibModal, $stateParams, 'CrudDeleteCtrl', 'crud-delete.html', 'SHIPPINGPACKAGE_TITLE_DELETE');
		},
		data: {
			pageTitle: 'SHIPPINGPACKAGE_TITLE_DELETE',
			authorities: ['ROLE_MANAGER', 'ROLE_ADMIN']
		}
	});

	$stateProvider.state('app.shippingPackage.importXls', {
		url: '/import-xls',
		parent: 'app.shippingPackage.list',
		onEnter: function($state, $uibModal) {
			openModal($state, $uibModal, undefined, 'CrudImportXlsCtrl', 'crud-import-xls.html', 'SHIPPINGPACKAGE_TITLE_IMPORT_XLS');
		},
		data: {
			pageTitle: 'SHIPPINGPACKAGE_TITLE_IMPORT_XLS',
			authorities: ['ROLE_MANAGER', 'ROLE_ADMIN']
		}
	});

	$stateProvider.state('app.shippingPackage.exportXls', {
		url: '/export-xls',
		parent: 'app.shippingPackage.list',
		onEnter: function($state, $uibModal) {
			openModal($state, $uibModal, undefined, 'CrudExportXlsCtrl', 'crud-export-xls.html', 'SHIPPINGPACKAGE_TITLE_EXPORT_XLS');
		},
		data: {
			pageTitle: 'SHIPPINGPACKAGE_TITLE_EXPORT_XLS',
			authorities: ['ROLE_MANAGER', 'ROLE_ADMIN']
		}
	});
});

angular.module('cottonMeApp').config(function($translateProvider) {
	$translateProvider.translations('fr', {
		'SHIPPINGPACKAGE_MENU': 'Colissage',
		'SHIPPINGPACKAGE_LIST_TRACKINGNUMBER': 'Numéro de suivi',
		'SHIPPINGPACKAGE_LIST_CARRIER': 'Transporteur',
		'SHIPPINGPACKAGE_LIST_SHIPPINGNUMBER': 'Numéro de livraison',
		'SHIPPINGPACKAGE_LIST_STATUS': 'Status',
		'SHIPPINGPACKAGE_LIST_QUANTITY': 'Quantité',
		'SHIPPINGPACKAGE_LIST_WEIGHT': 'Poids',
		'SHIPPINGPACKAGE_LIST_ADDRESS': 'Adresse',
		'SHIPPINGPACKAGE_LIST_DELIVERYDATE': 'Date de livraison',
		'SHIPPING_PACKAGE_BTN_SYNC_INFO_CNE': 'Synchroniser le status des colis avec CNE',
		'SHIPPING_PACKAGE_BTN_RETRIEVE_INFO_FOR_ALL_PREPARED': 'Récupérer les infos de livraison pour tous les colis préparés',
		'SHIPPING_PACKAGE_BTN_RETRIEVE_INFO_FOR_SELECTED': 'Récupérer les infos de livraison pour les colis sélectionnés',
		'SHIPPING_PACKAGE_BTN_SET_AS_SHIPPED_SELECTED': 'Set selected packages as shipped',
		'SHIPPING_PACKAGE_BTN_SET_AS_RECEIVED_SELECTED': 'Marquer les colis sélectionnés comme étant reçus',
		'SHIPPING_PACKAGE_BTN_SET_AS_CANCELLED_SELECTED': 'Marquer les colis sélectionnés comme étant annulés',
		'SHIPPINGPACKAGE_FORM_WEIGHT': 'Poids',
		'SHIPPINGPACKAGE_FORM_TRACKINGNUMBER': 'Numéro de suivi',
		'SHIPPINGPACKAGE_FORM_CARRIER': 'Transporteur',
		'SHIPPINGPACKAGE_FORM_SHIPPINGNUMBER': 'Numéro de livraison',
		'SHIPPINGPACKAGE_FORM_DELIVERYADDRESS': 'Adresse de livraison',
		'SHIPPINGPACKAGE_FORM_DELIVERYADDRESS_ADD': 'Ajouter une adresse de livraison',
		'SHIPPINGPACKAGE_FORM_STATUS': 'Status',
		'SHIPPINGPACKAGE_FORM_PRODUCTLIST': 'Liste de produit',
		'SHIPPINGPACKAGE_FORM_DELIVERYDATE': 'Date de livraison',
		'SHIPPINGPACKAGE_FORM_REFERENCE': 'Référence',
		'SHIPPINGPACKAGE_TITLE_DELETE': 'Suppression de colis',
		'SHIPPINGPACKAGE_TITLE_VIEW': 'Détails de colis',
		'SHIPPINGPACKAGE_TITLE_LIST': 'Liste des colis',
		'SHIPPINGPACKAGE_TITLE_EDIT': 'Editer un colis',
		'SHIPPINGPACKAGE_TITLE_NEW': 'Créer un colis',
		'SHIPPINGPACKAGE_TITLE_IMPORT_XLS': 'Importer en masse des colis',
		'SHIPPINGPACKAGE_TITLE_EXPORT_XLS': 'Exporter en masse des colis'
	});

	$translateProvider.translations('en', {
		'SHIPPINGPACKAGE_MENU': 'Shipping package',
		'SHIPPINGPACKAGE_LIST_TRACKINGNUMBER': 'Tracking number',
		'SHIPPINGPACKAGE_LIST_CARRIER': 'Carrier',
		'SHIPPINGPACKAGE_LIST_SHIPPINGNUMBER': 'Shipping number',
		'SHIPPINGPACKAGE_LIST_STATUS': 'Status',
		'SHIPPINGPACKAGE_LIST_QUANTITY': 'Quantity',
		'SHIPPINGPACKAGE_LIST_WEIGHT': 'Weight',
		'SHIPPINGPACKAGE_LIST_ADDRESS': 'Address',
		'SHIPPINGPACKAGE_LIST_DELIVERYDATE': 'Delivery date',
		'SHIPPING_PACKAGE_BTN_SYNC_INFO_CNE': 'Synchronise packages status from CNE',
		'SHIPPING_PACKAGE_BTN_RETRIEVE_INFO_FOR_ALL_PREPARED': 'Retrieve shipping info for all prepared packages',
		'SHIPPING_PACKAGE_BTN_RETRIEVE_INFO_FOR_SELECTED': 'Retrieve shipping info for selected packages',
		'SHIPPING_PACKAGE_BTN_SET_AS_SHIPPED_SELECTED': 'Set selected packages as shipped',
		'SHIPPING_PACKAGE_BTN_SET_AS_RECEIVED_SELECTED': 'Set selected packages as received',
		'SHIPPING_PACKAGE_BTN_SET_AS_CANCELLED_SELECTED': 'Set selected packages as cancelled',
		'SHIPPINGPACKAGE_FORM_WEIGHT': 'Weigth',
		'SHIPPINGPACKAGE_FORM_TRACKINGNUMBER': 'Tracking number',
		'SHIPPINGPACKAGE_FORM_CARRIER': 'Carrier',
		'SHIPPINGPACKAGE_FORM_SHIPPINGNUMBER': 'Shipping number',
		'SHIPPINGPACKAGE_FORM_DELIVERYADDRESS': 'Delivery address',
		'SHIPPINGPACKAGE_FORM_DELIVERYADDRESS_ADD': 'Add a delivery address',
		'SHIPPINGPACKAGE_FORM_STATUS': 'Status',
		'SHIPPINGPACKAGE_FORM_PRODUCTLIST': 'Product list',
		'SHIPPINGPACKAGE_FORM_DELIVERYDATE': 'Delivery date',
		'SHIPPINGPACKAGE_FORM_REFERENCE': 'Reference',
		'SHIPPINGPACKAGE_TITLE_DELETE': 'Shipping package delete',
		'SHIPPINGPACKAGE_TITLE_VIEW': 'Shipping package details',
		'SHIPPINGPACKAGE_TITLE_LIST': 'Shipping package list',
		'SHIPPINGPACKAGE_TITLE_EDIT': 'Shipping package edition',
		'SHIPPINGPACKAGE_TITLE_NEW': 'Shipping package creation',
		'SHIPPINGPACKAGE_TITLE_IMPORT_XLS': 'Shipping package mass import',
		'SHIPPINGPACKAGE_TITLE_EXPORT_XLS': 'Shipping package mass export'
	});

	$translateProvider.translations('zh', {
		'SHIPPINGPACKAGE_MENU': 'Shipping package',
		'SHIPPINGPACKAGE_LIST_TRACKINGNUMBER': 'Tracking number',
		'SHIPPINGPACKAGE_LIST_CARRIER': 'Carrier',
		'SHIPPINGPACKAGE_LIST_SHIPPINGNUMBER': 'Shipping number',
		'SHIPPINGPACKAGE_LIST_STATUS': 'Status',
		'SHIPPINGPACKAGE_LIST_QUANTITY': 'Quantity',
		'SHIPPINGPACKAGE_LIST_WEIGHT': 'Weight',
		'SHIPPINGPACKAGE_LIST_ADDRESS': 'Address',
		'SHIPPINGPACKAGE_LIST_DELIVERYDATE': 'Delivery date',
		'SHIPPING_PACKAGE_BTN_SYNC_INFO_CNE': 'Synchronise packages status from CNE',
		'SHIPPING_PACKAGE_BTN_RETRIEVE_INFO_FOR_ALL_PREPARED': 'Retrieve shipping info for all prepared packages',
		'SHIPPING_PACKAGE_BTN_RETRIEVE_INFO_FOR_SELECTED': 'Retrieve shipping info for selected packages',
		'SHIPPING_PACKAGE_BTN_SET_AS_SHIPPED_SELECTED': 'Set selected packages as shipped',
		'SHIPPING_PACKAGE_BTN_SET_AS_RECEIVED_SELECTED': 'Set selected packages as received',
		'SHIPPING_PACKAGE_BTN_SET_AS_CANCELLED_SELECTED': 'Set selected packages as cancelled',
		'SHIPPINGPACKAGE_FORM_WEIGHT': 'Weigth',
		'SHIPPINGPACKAGE_FORM_TRACKINGNUMBER': 'Tracking number',
		'SHIPPINGPACKAGE_FORM_CARRIER': 'Carrier',
		'SHIPPINGPACKAGE_FORM_SHIPPINGNUMBER': 'Shipping number',
		'SHIPPINGPACKAGE_FORM_DELIVERYADDRESS': 'Delivery address',
		'SHIPPINGPACKAGE_FORM_DELIVERYADDRESS_ADD': 'Add a delivery address',
		'SHIPPINGPACKAGE_FORM_STATUS': 'Status',
		'SHIPPINGPACKAGE_FORM_PRODUCTLIST': 'Product list',
		'SHIPPINGPACKAGE_FORM_DELIVERYDATE': 'Delivery date',
		'SHIPPINGPACKAGE_FORM_REFERENCE': 'Reference',
		'SHIPPINGPACKAGE_TITLE_DELETE': 'Shipping package delete',
		'SHIPPINGPACKAGE_TITLE_VIEW': 'Shipping package details',
		'SHIPPINGPACKAGE_TITLE_LIST': 'Shipping package list',
		'SHIPPINGPACKAGE_TITLE_EDIT': 'Shipping package edition',
		'SHIPPINGPACKAGE_TITLE_NEW': 'Shipping package creation',
		'SHIPPINGPACKAGE_TITLE_IMPORT_XLS': 'Shipping package mass import',
		'SHIPPINGPACKAGE_TITLE_EXPORT_XLS': 'Shipping package mass export'
	});
});

angular.module('cottonMeApp').config(function(menuServiceProvider) {
	menuServiceProvider.addItem({
		labelKey: 'SHIPPINGPACKAGE_MENU',
		url: '#/app/shippingPackages',
		iconClass: 'glyphicon glyphicon-equalizer',
		rank: 250
	});
});

'use strict';

angular.module('cottonMeApp').component('productTimeline', {
	templateUrl: 'modules/product/product-timeline.html',
	bindings: {
		product: '='
	},
	controller: function(Product) {
		var ctrl = this;
		ctrl.$postLink = function() {
			ctrl.loading = true;
			Product.getTimeline({
				id: ctrl.product.id
			}).$promise.then(function(timeline) {
				ctrl.timeline = timeline;
			}).finally(function() {
				ctrl.loading = false;
			});
		};
	}
});

'use strict';

angular.module('cottonMeApp').component('productShirtView', {
	templateUrl: 'modules/product/product-shirt-view.html',
	require: {
		ngModel: 'ngModel'
	},
	controller: function($scope, $filter, $log, productShirtFormBuilderService, shirtDetailsChoiceService) {
		var ctrl = this;
		var inchFilter = $filter('inch');
		ctrl.$onInit = function() {
			$scope.$watch(function() {
				return ctrl.ngModel.$modelValue;
			}, function() {
				var shirtProduct = ctrl.ngModel.$modelValue;
				var defineTable1Row = function(cell0, value, cell2, cell4) {
					var cell3 = value;
					if (cell4 === '寸') {
						cell3 = inchFilter(value);
					}
					return [cell0, value, cell2, cell3, cell4];
				};

				ctrl.table1 = [];
				angular.forEach(productShirtFormBuilderService.measurements, function(measurementDef) {
					var value = shirtProduct.measurement[measurementDef.modelAttribute];
					if (value) {
						if (measurementDef.possibleValues) {
							value = value.split('/');
							ctrl.table1.push([ //
								measurementDef.englishName, //
								value[0], //
								measurementDef.chineseName, //
								value[1], //
								'' //
							]);
						} else {
							ctrl.table1.push(defineTable1Row( //
								measurementDef.englishName, //
								value, //
								measurementDef.chineseName, //
								measurementDef.secondaryUnit //
							));
						}
					}
				});
				shirtDetailsChoiceService.getDictionary().then(function(dict) {
					var definedTable2Row = function(cell0, cell1, cell2, shirtChoiceCode) {
						var shirtChoice = dict[cell0][shirtChoiceCode];
						var chineseName = '';
						if (shirtChoice) {
							chineseName = shirtChoice.chineseOptionName;
						} else {
							$log.getInstance('app.components.productShirtView').error('Cannot find category=' + cell0 + ' code=' + shirtChoiceCode);
						}
						return [cell0, cell1, cell2, shirtChoiceCode, chineseName];
					};
					ctrl.table2 = [];
					angular.forEach(productShirtFormBuilderService.shirtDetailsChoices, function(sdc) {
						ctrl.table2.push(definedTable2Row( //
							sdc.index, //
							sdc.englishCategory, //
							sdc.chineseCategory, //
							shirtProduct[sdc.modelAttribute] //
						));
					});
				});
			});
		};
	}
});

'use strict';

angular.module('cottonMeApp').component('productShirtTagPrintSelected', {
	templateUrl: 'modules/product/product-shirt-tag-print-selected.html',
	bindings: {
		tableParams: '<',
		reloadAfterAction: '<',
        executeTransition: '<'
	},
	controller: function($scope, Product) {
		var ctrl = this;
		ctrl.isListEmpty = true;
		var filterProduct = function(entityList) {
			return entityList.reduce(function(accu, entity) {
				if (entity.isSelected && entity.shirtProduct) {
					accu.push(entity);
				}
				return accu;
			}, []);
		};
		$scope.$watch(function() {
			return ctrl.tableParams.data;
		}, function() {
			if (ctrl.tableParams.data) {
				var selectedEntities = filterProduct(ctrl.tableParams.data);
				ctrl.isListEmpty = selectedEntities.length === 0;
			} else {
				ctrl.isListEmpty = true;
			}
		}, true);
		ctrl.isUiBlocked = false;
		ctrl.activate = function() {
			ctrl.isUiBlocked = true;
			var ids = filterProduct(ctrl.tableParams.data).map(function(entity) {
				return entity.id;
			});
			var idList = ids.join(',');
            var method = ctrl.executeTransition ? Product.selectedShirtToTagPdf : Product.generateTagPdf;
			method({
				idList: idList
			}).$promise.then(function() {
				if (ctrl.reloadAfterAction) {
					ctrl.tableParams.reload();
				}
			}).finally(function() {
				ctrl.isUiBlocked = false;
			});
		};
	}
});

'use strict';

angular.module('cottonMeApp').component('productShirtTagPrintAllNotPrinted', {
	templateUrl: 'modules/product/product-shirt-tag-print-all-not-printed.html',
	bindings: {
		tableParams: '<',
		reloadAfterAction: '<',
        executeTransition: '<'
	},
	controller: function(Product) {
		var ctrl = this;
		ctrl.isUiBlocked = false;
		ctrl.activate = function() {
			ctrl.isUiBlocked = true;
            
            var method = ctrl.executeTransition ? Product.allQualityOkShirtToTagPdf : Product.allQualityOkShirtGeneratePdf;
			method().$promise.then(function() {
				if (ctrl.reloadAfterAction) {
					ctrl.tableParams.reload();
				}
			}).finally(function() {
				ctrl.isUiBlocked = false;
			});
		};
	}
});

'use strict';

angular.module('cottonMeApp').component('productShirtSheetPrint', {
	templateUrl: 'modules/product/product-shirt-sheet-print.html',
	bindings: {
		id: '<'
	},
	controller: function($scope, Product) {
		var ctrl = this;
		ctrl.isUiBlocked = false;

		ctrl.getPDF = function() {
			ctrl.isUiBlocked = true;
			Product.selectedShirtToSheetPdf({
				idList: ctrl.id
			}).$promise.then(function() {
				// Nothing
			}).finally(function() {
				ctrl.isUiBlocked = false;
			});
		};
	}
});

'use strict';

angular.module('cottonMeApp').component('productShirtSheetPrintSelected', {
	templateUrl: 'modules/product/product-shirt-sheet-print-selected.html',
	bindings: {
		tableParams: '<',
		reloadAfterAction: '<'
	},
	controller: function($scope, Product) {
		var ctrl = this;
		ctrl.isListEmpty = true;
		var filterProduct = function(entityList) {
			return entityList.reduce(function(accu, entity) {
				if (entity.isSelected && entity.shirtProduct) {
					accu.push(entity);
				}
				return accu;
			}, []);
		};
		$scope.$watch(function() {
			return ctrl.tableParams.data;
		}, function() {
			if (ctrl.tableParams.data) {
				var selectedEntities = filterProduct(ctrl.tableParams.data);
				ctrl.isListEmpty = selectedEntities.length === 0;
			} else {
				ctrl.isListEmpty = true;
			}
		}, true);
		ctrl.isUiBlocked = false;
		ctrl.activate = function() {
			ctrl.isUiBlocked = true;
			var ids = filterProduct(ctrl.tableParams.data).map(function(entity) {
				return entity.id;
			});
			var idList = ids.join(',');
			Product.selectedShirtToSheetPdf({
				idList: idList
			}).$promise.then(function() {
				if (ctrl.reloadAfterAction) {
					ctrl.tableParams.reload();
				}
			}).finally(function() {
				ctrl.isUiBlocked = false;
			});
		};
	}
});

'use strict';

angular.module('cottonMeApp').component('productShirtSheetPrintAllNotPrinted', {
	templateUrl: 'modules/product/product-shirt-sheet-print-all-not-printed.html',
	bindings: {
		tableParams: '<',
		reloadAfterAction: '<'
	},
	controller: function(Product) {
		var ctrl = this;
		ctrl.isUiBlocked = false;
		ctrl.activate = function() {
			ctrl.isUiBlocked = true;
			Product.allNotPrintedShirtToSheetPdf().$promise.then(function() {
				if (ctrl.reloadAfterAction) {
					ctrl.tableParams.reload();
				}
			}).finally(function() {
				ctrl.isUiBlocked = false;
			});
		};
	}
});

'use strict';

angular.module('cottonMeApp').factory('productShirtFormBuilderService', function() {
	var api = {};
	api.measurements = [{
		englishName: 'Height',
		chineseName: '身高',
		step: 1,
		modelAttribute: 'height',
		isOptional: true,
		mainUnit: 'cm',
		secondaryUnit: '寸'
	}, {
		englishName: 'Weight',
		chineseName: '体重',
		step: 1,
		modelAttribute: 'weight',
		isOptional: true,
		mainUnit: 'kg'
	}, {
		englishName: 'NECK',
		chineseName: '领围',
		step: 0.01,
		modelAttribute: 'neck',
		mainUnit: 'cm',
		secondaryUnit: '寸'
	}, {
		englishName: 'CHEST',
		chineseName: '胸围',
		step: 0.01,
		modelAttribute: 'chest',
		mainUnit: 'cm',
		secondaryUnit: '寸'
	}, {
		englishName: 'WAIST',
		chineseName: '腰围',
		step: 0.01,
		modelAttribute: 'waist',
		mainUnit: 'cm',
		secondaryUnit: '寸'
	}, {
		englishName: 'WAIST HIGH',
		chineseName: '腰围高度',
		step: 0.01,
		modelAttribute: 'waistHigh',
		isOptional: true,
		mainUnit: 'cm',
		secondaryUnit: '寸'
	}, {
		englishName: 'HIPS',
		chineseName: '臀围',
		step: 0.01,
		modelAttribute: 'hips',
		mainUnit: 'cm',
		secondaryUnit: '寸'
	}, {
		englishName: 'SHOULDERS',
		chineseName: '肩宽',
		step: 0.01,
		modelAttribute: 'shoulders',
		mainUnit: 'cm',
		secondaryUnit: '寸'
	}, {
		englishName: 'SHOULDERS High',
		chineseName: '肩高',
		modelAttribute: 'shouldersHigh',
		possibleValues: ['normal/－', 'straight/平肩', 'low/ 斜肩']
	}, {
		englishName: 'SHOULDERS Position',
		chineseName: '肩位',
		modelAttribute: 'shouldersPosition',
		possibleValues: ['normal/－', 'in front/前肩', 'in back/后肩']
	}, {
		englishName: 'SHIRT LENGHT BACK',
		chineseName: '衣长后',
		step: 0.01,
		modelAttribute: 'shirtLengthBack',
		mainUnit: 'cm',
		secondaryUnit: '寸'
	}, {
		englishName: 'SHIRT LENGHT FRONT',
		chineseName: '衣长前',
		step: 0.01,
		modelAttribute: 'shirtLengthFront',
		isOptional: true,
		mainUnit: 'cm',
		secondaryUnit: '寸'
	}, {
		englishName: 'SLEEVES R',
		chineseName: '袖长右',
		step: 0.01,
		modelAttribute: 'sleevesRight',
		mainUnit: 'cm',
		secondaryUnit: '寸'
	}, {
		englishName: 'SLEEVES L',
		chineseName: '袖长左',
		step: 0.01,
		modelAttribute: 'sleevesLeft',
		mainUnit: 'cm',
		secondaryUnit: '寸'
	}, {
		englishName: 'WRIST R',
		chineseName: '袖口右',
		step: 0.01,
		modelAttribute: 'wristRight',
		mainUnit: 'cm',
		secondaryUnit: '寸'
	}, {
		englishName: 'WRIST L',
		chineseName: '袖口左',
		step: 0.01,
		modelAttribute: 'wristLeft',
		mainUnit: 'cm',
		secondaryUnit: '寸'
	}, {
		englishName: 'ARMHOLE',
		chineseName: 'XF袖龙',
		step: 0.01,
		modelAttribute: 'armhole',
		isOptional: true,
		mainUnit: 'cm',
		secondaryUnit: '寸'
	}, {
		englishName: 'BICEPS',
		chineseName: 'XF袖肥',
		step: 0.01,
		modelAttribute: 'biceps',
		isOptional: true,
		mainUnit: 'cm',
		secondaryUnit: '寸'
	}, {
		englishName: 'FRONT CHEST',
		chineseName: '前胸',
		step: 0.01,
		modelAttribute: 'frontChest',
		isOptional: true,
		mainUnit: 'cm',
		secondaryUnit: '寸'
	}, {
		englishName: 'BACK CHEST',
		chineseName: '后胸',
		step: 0.01,
		modelAttribute: 'backChest',
		isOptional: true,
		mainUnit: 'cm',
		secondaryUnit: '寸'
	}];
	api.shirtDetailsChoices = [{
		index: '1',
		englishCategory: 'Collar style',
		chineseCategory: '领型',
		modelAttribute: 'collarStyle'
	}, {
		index: '2',
		englishCategory: 'Wrist Style',
		chineseCategory: '袖口型',
		modelAttribute: 'wristStyle'
	}, {
		index: '3',
		englishCategory: 'Cut Style',
		chineseCategory: '合体程度',
		modelAttribute: 'cutStyle'
	}, {
		index: '4',
		englishCategory: 'Pleat Style',
		chineseCategory: '门襟',
		modelAttribute: 'pleatStyle'
	}, {
		index: '5',
		englishCategory: 'Back Stitch',
		chineseCategory: '背部折裥',
		modelAttribute: 'backStitch'
	}, {
		index: '6',
		englishCategory: 'Pocket',
		chineseCategory: '口袋',
		modelAttribute: 'pocket'
	}, {
		index: '7',
		englishCategory: 'Buttons Style',
		chineseCategory: '??',
		modelAttribute: 'buttonStyle'
	}, {
		index: '8',
		englishCategory: 'Buttons Hole Color',
		chineseCategory: '扣眼颜色',
		modelAttribute: 'buttonHoleColor'
	}, {
		index: '9',
		englishCategory: 'Initials Type',
		chineseCategory: '字母',
		modelAttribute: 'initialsType'
	}, {
		index: '10',
		englishCategory: 'Initials Color',
		chineseCategory: '字母颜色',
		modelAttribute: 'initialsColor'
	}, {
		index: '11',
		englishCategory: 'Initials Position',
		chineseCategory: '字母位置',
		modelAttribute: 'initialsPosition'
	}, {
		index: '12',
		englishCategory: 'Inside Collar Fabric',
		chineseCategory: '??',
		modelAttribute: 'insideCollarFabric'
	}, {
		index: '13',
		englishCategory: 'Outside Collar Fabric',
		chineseCategory: '??',
		modelAttribute: 'outsideCollarFabric'
	}, {
		index: '14',
		englishCategory: 'Inside Cuff Fabric',
		chineseCategory: '??',
		modelAttribute: 'insideCuffFabric'
	}, {
		index: '15',
		englishCategory: 'Inner Fabric',
		chineseCategory: '??',
		modelAttribute: 'innerFabric'
	}, {
		index: '16',
		englishCategory: 'Collar Stay',
		chineseCategory: '??',
		modelAttribute: 'collarStay'
	}, {
		index: '17',
		englishCategory: 'Colar Thickness',
		chineseCategory: '??',
		modelAttribute: 'colarThickness'
	}, {
		index: '18',
		englishCategory: 'Collar White',
		chineseCategory: '??',
		modelAttribute: 'collarWhite'
	}, {
		index: '19',
		englishCategory: 'Shirt Bottom',
		chineseCategory: '??',
		modelAttribute: 'shirtBottom'
	}];
	return api;
});

'use strict';

angular.module('cottonMeApp').component('productShirtEdit', {
	templateUrl: 'modules/product/product-shirt-edit.html',
	bindings: {
		form: '<'
	},
	require: {
		ngModel: 'ngModel'
	},
	controller: function(productShirtFormBuilderService) {
		var ctrl = this;
		ctrl.measurements = productShirtFormBuilderService.measurements;
		ctrl.shirtDetailsChoices = productShirtFormBuilderService.shirtDetailsChoices;
	}
});

'use strict';

angular.module('cottonMeApp').component('productRetrieveBillingInfoForSelected', {
	templateUrl: 'modules/product/product-retrieve-billing-info-for-selected.html',
	bindings: {
		tableParams: '<',
		reloadAfterAction: '<'
	},
	controller: function($scope, Product) {
		var ctrl = this;
		ctrl.isListEmpty = true;
		var filterProduct = function(entityList) {
			return entityList.reduce(function(accu, entity) {
				if (entity.isSelected) {
					accu.push(entity);
				}
				return accu;
			}, []);
		};
		$scope.$watch(function() {
			return ctrl.tableParams.data;
		}, function() {
			if (ctrl.tableParams.data) {
				var selectedEntities = filterProduct(ctrl.tableParams.data);
				ctrl.isListEmpty = selectedEntities.length === 0;
			} else {
				ctrl.isListEmpty = true;
			}
		}, true);
		ctrl.isUiBlocked = false;
		ctrl.activate = function() {
			ctrl.isUiBlocked = true;
			var ids = filterProduct(ctrl.tableParams.data).map(function(entity) {
				return entity.id;
			});
			var idList = ids.join(',');
			Product.retrieveBillingInfoForSelected({
				idList: idList
			}).$promise.then(function() {
				if (ctrl.reloadAfterAction) {
					ctrl.tableParams.reload();
				}
			}).finally(function() {
				ctrl.isUiBlocked = false;
			});
		};
	}
});

'use strict';

angular.module('cottonMeApp').component('productRetrieveBillingInfoForAll', {
	templateUrl: 'modules/product/product-retrieve-billing-info-for-all.html',
	bindings: {
		customerB2B: '=',
		tableParams: '<',
		reloadAfterAction: '<'
	},
	controller: function($scope, Product) {
		var ctrl = this;
		ctrl.isUiBlocked = false;
		ctrl.activate = function() {
			ctrl.isUiBlocked = true;
			Product.rerieveBillingInfoForAll({
				customerB2BId: ctrl.customerB2B.id
			}).$promise.then(function() {
				if (ctrl.reloadAfterAction) {
					ctrl.tableParams.reload();
				}
			}).finally(function() {
				ctrl.isUiBlocked = false;
			});
		};
	}
});

'use strict';

angular.module('cottonMeApp').directive('dsProductNew', function($parse, $state, adamaConstant) {
	return {
		scope : false,
		link : function(scope, element, attrs) {
			var entity = {};
			// init order
			var resolve = $state.$current.locals.resolve.$$promises;
			var order = resolve.originalOrder.$$state.value;
			entity.order = order;
			// init default product unit
			var productUnit = adamaConstant.productUnits[0];
			if (order.customerB2B && order.customerB2B.productUnitDefault) {
				productUnit = order.customerB2B.productUnitDefault;
			}
			entity.productUnit = productUnit;
			// assign entity
			$parse(attrs.data).assign(scope, entity);
		}
	};
});

'use strict';

angular.module('cottonMeApp').component('productMassMoveSelected', {
	templateUrl: 'modules/product/product-mass-move-selected.html',
	bindings: {
		tableParams: '<',
		reloadAfterAction: '<'
	},
	controller: function($scope, Product, $uibModal) {
		var ctrl = this;
		ctrl.isListEmpty = true;
		var filterProduct = function(entityList) {
			return entityList.reduce(function(accu, entity) {
				if (entity.isSelected && entity.shirtProduct) {
					accu.push(entity);
				}
				return accu;
			}, []);
		};
		$scope.$watch(function() {
			return ctrl.tableParams.data;
		}, function() {
			if (ctrl.tableParams.data) {
				var selectedEntities = filterProduct(ctrl.tableParams.data);
				ctrl.isListEmpty = selectedEntities.length === 0;
			} else {
				ctrl.isListEmpty = true;
			}
		}, true);
		ctrl.isUiBlocked = false;
		ctrl.activate = function() {
			var ids = filterProduct(ctrl.tableParams.data).map(function(entity) {
				return entity.id;
			});

			$uibModal.open({
				templateUrl: 'modules/product/modal-mass-move.html',
				resolve: {
					idList: function(){
						return ids;
					},
					product: function(){
						return Product;
					},
					controller: function(){
						return ctrl;
					}
				},
				controller: 'MassMoveCtrl as ctrl'
			}).result.then(function() {
				
			}).catch(function() {
			});
		};
	}
});
'use strict';

angular.module('cottonMeApp').component('productFabricView', {
	templateUrl: 'modules/product/product-fabric-view.html',
	require: {
		ngModel: 'ngModel'
	}
});

'use strict';

angular.module('cottonMeApp').component('productFabricSheetPrintSelected', {
	templateUrl: 'modules/product/product-fabric-sheet-print-selected.html',
	bindings: {
		tableParams: '<',
		reloadAfterAction: '<'
	},
	controller: function($scope, Product) {
		var ctrl = this;
		ctrl.isListEmpty = true;
		var filterProduct = function(entityList) {
			return entityList.reduce(function(accu, entity) {
				if (entity.isSelected && entity.fabricProduct) {
					accu.push(entity);
				}
				return accu;
			}, []);
		};
		$scope.$watch(function() {
			return ctrl.tableParams.data;
		}, function() {
			if (ctrl.tableParams.data) {
				var selectedEntities = filterProduct(ctrl.tableParams.data);
				ctrl.isListEmpty = selectedEntities.length === 0;
			} else {
				ctrl.isListEmpty = true;
			}
		}, true);
		ctrl.isUiBlocked = false;
		ctrl.activate = function() {
			ctrl.isUiBlocked = true;
			var ids = filterProduct(ctrl.tableParams.data).map(function(entity) {
				return entity.id;
			});
			var idList = ids.join(',');
			Product.selectedFabricToSheetPdf({
				idList: idList
			}).$promise.then(function() {
				if (ctrl.reloadAfterAction) {
					ctrl.tableParams.reload();
				}
			}).finally(function() {
				ctrl.isUiBlocked = false;
			});
		};
	}
});

'use strict';

angular.module('cottonMeApp').component('productFabricSheetPrintAllNotPrinted', {
	templateUrl: 'modules/product/product-fabric-sheet-print-all-not-printed.html',
	bindings: {
		tableParams: '<',
		reloadAfterAction: '<'
	},
	controller: function(Product) {
		var ctrl = this;
		ctrl.isUiBlocked = false;
		ctrl.activate = function() {
			ctrl.isUiBlocked = true;
			Product.allNotPrintedFabricToSheetPdf().$promise.then(function() {
				if (ctrl.reloadAfterAction) {
					ctrl.tableParams.reload();
				}
			}).finally(function() {
				ctrl.isUiBlocked = false;
			});
		};
	}
});

'use strict';

angular.module('cottonMeApp').component('productFabricEdit', {
	templateUrl: 'modules/product/product-fabric-edit.html',
	bindings: {
		form: '<'
	},
	require: {
		ngModel: 'ngModel'
	}
});

'use strict';

angular.module('cottonMeApp').controller('MassMoveCtrl', function($uibModalInstance, AlertService, idList, Product, controller) {
	var ctrl = this;
    ctrl.value = '';
    ctrl.listCount = idList.length;
    ctrl.title= 'PRODUCT_MASS_MOVE_TITLE';

	ctrl.isUiBlocked = true;
	ctrl.save = function(){
		Product.moveToEvent({
			idList: idList.join(','),
			timelineEventStatus: ctrl.value
		}).$promise.then(function() {
			if (controller.reloadAfterAction) {
				controller.tableParams.reload();
			}
		}).finally(function() {
			ctrl.isUiBlocked = false;
			$uibModalInstance.close();
		});
	};
});

'use strict';

angular.module('cottonMeApp').component('exportAllShirts', {
	templateUrl: 'modules/product/export-all-shirts.html',
	bindings: {
		tableParams: '<',
		reloadAfterAction: '<'
	},
	controller: function(Product) {
		var ctrl = this;
		ctrl.isUiBlocked = false;
		ctrl.activate = function() {
			ctrl.isUiBlocked = true;
			Product.exportAllShirts().$promise.then(function() {
				if (ctrl.reloadAfterAction) {
					ctrl.tableParams.reload();
				}
			}).finally(function() {
				ctrl.isUiBlocked = false;
			});
		};
	}
});

'use strict';

angular.module('cottonMeApp').config(function($stateProvider) {
	$stateProvider.state('app.product', {
		abstract: true,
		url: '/products',
		template: '<ui-view></ui-view>'
	});

	$stateProvider.state('app.product.list', {
		url: '',
		templateUrl: 'modules/product/product-list.html',
		controller: 'CrudListCtrl',
		controllerAs: 'ctrl',
		resolve: {
			EntityGenericResource: function(Product) {
				return Product;
			}
		},
		data: {
			pageTitle: 'PRODUCT_TITLE_LIST',
			authorities: ['ROLE_MANAGER', 'ROLE_ADMIN']
		}
	});

	$stateProvider.state('app.product.view', {
		url: '/view/:entityId',
		templateUrl: 'modules/product/product-view.html',
		controller: 'CrudViewCtrl',
		controllerAs: 'ctrl',
		resolve: {
			entity: function($stateParams, Product) {
				return Product.get({
					id: $stateParams.entityId
				}).$promise;
			}
		},
		data: {
			pageTitle: 'PRODUCT_TITLE_VIEW',
			authorities: ['ROLE_MANAGER', 'ROLE_ADMIN']
		}
	});

	$stateProvider.state('app.product.edit', {
		url: '/edit/:entityId',
		templateUrl: 'modules/product/product-edit.html',
		controller: 'CrudEditCtrl',
		controllerAs: 'ctrl',
		resolve: {
			entity: function($stateParams, Product) {
				return Product.get({
					id: $stateParams.entityId
				}).$promise;
			},
			EntityGenericResource: function(Product) {
				return Product;
			},
			isAdmin: function() {
				return false;
			}
		},
		data: {
			pageTitle: 'PRODUCT_TITLE_EDIT',
			authorities: ['ROLE_MANAGER', 'ROLE_ADMIN']
		}
	});

	$stateProvider.state('app.product.create', {
		url: '/new?orderId',
		templateUrl: 'modules/product/product-edit.html',
		controller: 'CrudEditCtrl',
		controllerAs: 'ctrl',
		resolve: {
			entity: function() {
				return undefined;
			},
			EntityGenericResource: function(Product) {
				return Product;
			},
			originalOrder: function($stateParams, Order) {
				if ($stateParams.originalOrder) {
					return $stateParams.originalOrder;
				}
				return Order.get({
					id: $stateParams.orderId
				}).$promise;
			}
		},
		params: {
			originalOrder: undefined
		},
		data: {
			pageTitle: 'PRODUCT_TITLE_NEW',
			authorities: ['ROLE_MANAGER', 'ROLE_ADMIN']
		}
	});

	var openModal = function($state, $uibModal, $stateParams, controllerName, templateName, title) {
		var resolveEntity;
		if ($stateParams) {
			resolveEntity = /* @ngInject */ function(Product) {
				return Product.get({
					id: $stateParams.entityId
				}).$promise;
			};
		}
		$uibModal.open({
			templateUrl: 'adama-web/crud/' + templateName,
			resolve: {
				entity: resolveEntity,
				EntityGenericResource: function(Product) {
					return Product;
				},
				title: function() {
					return title;
				}
			},
			controller: controllerName + ' as ctrl'
		}).result.then(function() {
			$state.go('^', {}, {
				reload: true
			});
		}).catch(function() {
			$state.go('^');
		});
	};

	$stateProvider.state('app.product.delete', {
		url: '/delete/:entityId',
		parent: 'app.product.list',
		onEnter: function($state, $uibModal, $stateParams) {
			openModal($state, $uibModal, $stateParams, 'CrudDeleteCtrl', 'crud-delete.html', 'PRODUCT_TITLE_DELETE');
		},
		data: {
			pageTitle: 'PRODUCT_TITLE_DELETE',
			authorities: ['ROLE_MANAGER', 'ROLE_ADMIN']
		}
	});

	$stateProvider.state('app.product.importXls', {
		url: '/import-xls',
		parent: 'app.product.list',
		onEnter: function($state, $uibModal) {
			openModal($state, $uibModal, undefined, 'CrudImportXlsCtrl', 'crud-import-xls.html', 'PRODUCT_TITLE_IMPORT_XLS');
		},
		data: {
			pageTitle: 'PRODUCT_TITLE_IMPORT_XLS',
			authorities: ['ROLE_MANAGER', 'ROLE_ADMIN']
		}
	});

	$stateProvider.state('app.product.exportXls', {
		url: '/export-xls',
		parent: 'app.product.list',
		onEnter: function($state, $uibModal) {
			openModal($state, $uibModal, undefined, 'CrudExportXlsCtrl', 'crud-export-xls.html', 'PRODUCT_TITLE_EXPORT_XLS');
		},
		data: {
			pageTitle: 'PRODUCT_TITLE_EXPORT_XLS',
			authorities: ['ROLE_MANAGER', 'ROLE_ADMIN']
		}
	});
});

angular.module('cottonMeApp').config(function($translateProvider) {
	$translateProvider.translations('fr', {
		'PRODUCT_MENU': 'Produit',
		'PRODUCT_LIST_PRODUCT_TYPE': 'Type',
		'PRODUCT_LIST_ORDER': 'Commande',
		'PRODUCT_LIST_SHIPPINGPACKAGE': 'Colis',
		'PRODUCT_LIST_REFERENCE': 'Référence',
		'PRODUCT_LIST_PRINTED': 'Imprimé',
		'PRODUCT_LIST_BILLED': 'Facturé',
		'PRODUCT_LIST_CUSTOMERB2B': 'Client B2B',
		'PRODUCT_LIST_STATUS': 'Status',
		'PRODUCT_LIST_UNIT': 'Service',
		'PRODUCT_FORM_TAB_GENERAL': 'Informations générales',
		'PRODUCT_FORM_TAB_SHIRT': 'Détails produit chemise',
		'PRODUCT_FORM_TAB_FABRIC': 'Détails produit tissus',
		'PRODUCT_FORM_ADD_SHIRT_PRODUCT': 'Info chemise',
		'PRODUCT_FORM_ADD_FABRIC_PRODUCT': 'Info tissus',
		'PRODUCT_FORM_ORDER': 'Commande',
		'PRODUCT_FORM_ORDER_CUSTOMERB2B': 'Client B2B',
		'PRODUCT_FORM_ORDER_FINALCUSTOMER': 'Client final',
		'PRODUCT_FORM_SHIPPINGPACKAGE': 'Colis',
		'PRODUCT_FORM_SUPPLIER': 'Fournisseur',
		'PRODUCT_FORM_SUPPLIERREFERENCE': 'Réf. fournisseur',
		'PRODUCT_FORM_REFERENCE': 'Référence',
		'PRODUCT_FORM_EXTERNALATTRIBUTEID': 'Identifiant externe',
		'PRODUCT_FORM_PRODUCTUNIT': 'Service produit',
		'PRODUCT_FORM_COMMENT': 'Commentaires',
		'PRODUCT_FORM_PRINTED': 'Imprimé',
		'PRODUCT_FORM_BILLED': 'Facturé',
		'PRODUCT_FORM_FABRICPRODUCT_FABRIC': 'Tissus',
		'PRODUCT_FORM_FABRICPRODUCT_CUTLENGH': 'Longueur de découpe',
		'PRODUCT_FORM_SHIRT_IMAGE': 'Image',
		'PRODUCT_FORM_SHIRT_MEASUREMENT_PROFILE': 'Profile',
		'PRODUCT_FORM_SHIRT_MEASUREMENT_MODIFIEDDATE': 'Date de modification',
		'PRODUCT_FORM_SHIRT_MEASUREMENT_YEAROFBIRTH': 'Année de naissance',
		'PRODUCT_FORM_SHIRT_MEASUREMENT_COMMENT': 'Commentaire de mesure',
		'PRODUCT_FORM_SHIRT_MEASUREMENT_PATRONID': 'Identifiant patron',
		'PRODUCT_FORM_SHIRT_MEASUREMENT_STANDARDSIZEREF': 'Référence taille standard',
		'PRODUCT_FORM_SHIRT_SELECT_OPTION': 'Choisir option',
		'PRODUCT_BACK_TO_ORDER': 'Retour à la commande',
		'PRODUCT_DOWNLOAD_PDF': 'Télécharger le PDF',
		'EXPORT_ALL_SHIRTS': 'Exporter toutes les chemises',
		'PRODUCT_FABRIC_BTN_PRINT_SHEET_ALL_NOT_PRINTED': 'Imprimer toutes les fiches "tissus" jamais imprimées',
		'PRODUCT_FABRIC_BTN_PRINT_SHEET_SELECTED': 'Imprimer les fiches "tissus" sélectionnées',
		'PRODUCT_SHIRT_BTN_PRINT_SHEET_ALL_NOT_PRINTED': 'Imprimer toutes les fiches "chemise" jamais imprimées',
		'PRODUCT_SHIRT_BTN_PRINT_SHEET_SELECTED': 'Imprimer les fiches "chemise" sélectionnées',
		'PRODUCT_SHIRT_BTN_PRINT_TAG_ALL_NOT_PRINTED': 'Imprimer toutes les étiquettes "chemise" jamais imprimées',
		'PRODUCT_SHIRT_BTN_PRINT_TAG_SELECTED': 'Imprimer les étiquettes "chemise" sélectionnées',
		'PRODUCT_BTN_RETRIEVE_BILLING_INFO_FOR_ALL': 'Récupérer les informations de facturations pour tous les produits',
		'PRODUCT_BTN_RETRIEVE_BILLING_INFO_FOR_SELECTED': 'Récupérer les informations de facturations pour les produits sélectionnés',
		'PRODUCT_TIMELINE_LOADING': 'Chargement des événements en cours',
		'PRODUCT_TIMELINE_NOT_YET': 'Pas d\'événement à ce jour',
		'PRODUCT_TIMELINE_FABRIC_CUT_REF': 'Tissus : {{ fabricInternalReference }}',
		'PRODUCT_TIMELINE_FABRIC_CUT_SIZE': 'Longueur de découpe : {{ fabricCutSize }}',
		'PRODUCT_TITLE_DELETE': 'Suppression de produit',
		'PRODUCT_TITLE_VIEW': 'Détails de produit',
		'PRODUCT_TITLE_LIST': 'Liste des produit',
		'PRODUCT_TITLE_EDIT': 'Editer un produit',
		'PRODUCT_TITLE_NEW': 'Créer un produit',
		'PRODUCT_TITLE_IMPORT_XLS': 'Importer en masse des produit',
		'PRODUCT_TITLE_EXPORT_XLS': 'Exporter en masse des produit',
		'PRODUCT_FORM_SENT_TO_SHOP': 'Livraison à la boutique'
	});

	$translateProvider.translations('en', {
		'PRODUCT_MENU': 'Product',
		'PRODUCT_LIST_PRODUCT_TYPE': 'Type',
		'PRODUCT_LIST_ORDER': 'Order',
		'PRODUCT_LIST_SHIPPINGPACKAGE': 'Shipping package',
		'PRODUCT_LIST_REFERENCE': 'Reference',
		'PRODUCT_LIST_PRINTED': 'Printed',
		'PRODUCT_LIST_BILLED': 'Billed',
		'PRODUCT_LIST_CUSTOMERB2B': 'Customer B2B',
		'PRODUCT_LIST_STATUS': 'Status',
		'PRODUCT_LIST_UNIT': 'Unit',
		'PRODUCT_FORM_TAB_GENERAL': 'General info',
		'PRODUCT_FORM_TAB_SHIRT': 'Shirt product details',
		'PRODUCT_FORM_TAB_FABRIC': 'Fabric product details',
		'PRODUCT_FORM_ADD_SHIRT_PRODUCT': 'Shirt info',
		'PRODUCT_FORM_ADD_FABRIC_PRODUCT': 'Fabric info',
		'PRODUCT_FORM_ORDER': 'Order',
		'PRODUCT_FORM_ORDER_CUSTOMERB2B': 'Customer B2B',
		'PRODUCT_FORM_ORDER_FINALCUSTOMER': 'Final customer',
		'PRODUCT_FORM_SHIPPINGPACKAGE': 'Shipping package',
		'PRODUCT_FORM_SUPPLIER': 'Supplier',
		'PRODUCT_FORM_SUPPLIERREFERENCE': 'Supplier ref.',
		'PRODUCT_FORM_REFERENCE': 'Reference',
		'PRODUCT_FORM_EXTERNALATTRIBUTEID': 'External attribute ID',
		'PRODUCT_FORM_PRODUCTUNIT': 'Product unit',
		'PRODUCT_FORM_COMMENT': 'Comments',
		'PRODUCT_FORM_PRINTED': 'Printed',
		'PRODUCT_FORM_BILLED': 'Billed',
		'PRODUCT_FORM_FABRICPRODUCT_FABRIC': 'Fabric',
		'PRODUCT_FORM_FABRICPRODUCT_CUTLENGH': 'Cut length',
		'PRODUCT_FORM_SHIRT_IMAGE': 'Image',
		'PRODUCT_FORM_SHIRT_MEASUREMENT_PROFILE': 'Profile',
		'PRODUCT_FORM_SHIRT_MEASUREMENT_MODIFIEDDATE': 'Modification date',
		'PRODUCT_FORM_SHIRT_MEASUREMENT_YEAROFBIRTH': 'Year of birth',
		'PRODUCT_FORM_SHIRT_MEASUREMENT_COMMENT': 'Measurement comments',
		'PRODUCT_FORM_SHIRT_MEASUREMENT_PATRONID': 'Patron ID',
		'PRODUCT_FORM_SHIRT_MEASUREMENT_STANDARDSIZEREF': 'Standard size reference',
		'PRODUCT_FORM_SHIRT_SELECT_OPTION': 'Select option',
		'PRODUCT_BACK_TO_ORDER': 'Back to order',
		'PRODUCT_DOWNLOAD_PDF': 'Download PDF',
		'EXPORT_ALL_SHIRTS': 'Export all shirts',
		'PRODUCT_FABRIC_BTN_PRINT_SHEET_ALL_NOT_PRINTED': 'Print all unprint "fabric" sheets',
		'PRODUCT_FABRIC_BTN_PRINT_SHEET_SELECTED': 'Print selected "fabric" sheets',
		'PRODUCT_SHIRT_BTN_PRINT_SHEET_ALL_NOT_PRINTED': 'Print all unprint "shirt" sheets',
		'PRODUCT_SHIRT_BTN_PRINT_SHEET_SELECTED': 'Print selected "shirt" sheets',
		'PRODUCT_SHIRT_BTN_PRINT_TAG_ALL_NOT_PRINTED': 'Print all unprint "shirt" tags',
		'PRODUCT_SHIRT_BTN_PRINT_TAG_SELECTED': 'Print selected "shirt" tags',
		'PRODUCT_BTN_RETRIEVE_BILLING_INFO_FOR_ALL': 'Retrieve billing info for all products',
		'PRODUCT_BTN_RETRIEVE_BILLING_INFO_FOR_SELECTED': 'Retrieve billing info for selected products',
		'PRODUCT_TIMELINE_LOADING': 'Loading events',
		'PRODUCT_TIMELINE_NOT_YET': 'No event yet',
		'PRODUCT_TIMELINE_FABRIC_CUT_REF': 'Fabric : {{ fabricInternalReference }}',
		'PRODUCT_TIMELINE_FABRIC_CUT_SIZE': 'Cut length : {{ fabricCutSize }}',
		'PRODUCT_TITLE_DELETE': 'Product delete',
		'PRODUCT_TITLE_VIEW': 'Product details',
		'PRODUCT_TITLE_LIST': 'Product list',
		'PRODUCT_TITLE_EDIT': 'Product edition',
		'PRODUCT_TITLE_NEW': 'Product creation',
		'PRODUCT_TITLE_IMPORT_XLS': 'Product mass import',
		'PRODUCT_TITLE_EXPORT_XLS': 'Product mass export',
		'PRODUCT_FORM_SENT_TO_SHOP': 'Delivery to shop'
	});

	$translateProvider.translations('zh', {
		'PRODUCT_MENU': 'Product',
		'PRODUCT_LIST_PRODUCT_TYPE': 'Type',
		'PRODUCT_LIST_ORDER': 'Order',
		'PRODUCT_LIST_SHIPPINGPACKAGE': 'Shipping package',
		'PRODUCT_LIST_REFERENCE': 'Reference',
		'PRODUCT_LIST_PRINTED': 'Printed',
		'PRODUCT_LIST_BILLED': 'Billed',
		'PRODUCT_LIST_CUSTOMERB2B': 'Customer B2B',
		'PRODUCT_LIST_STATUS': 'Status',
		'PRODUCT_LIST_UNIT': 'Unit',
		'PRODUCT_FORM_TAB_GENERAL': 'General info',
		'PRODUCT_FORM_TAB_SHIRT': 'Shirt product details',
		'PRODUCT_FORM_TAB_FABRIC': 'Fabric product details',
		'PRODUCT_FORM_ADD_SHIRT_PRODUCT': 'Shirt info',
		'PRODUCT_FORM_ADD_FABRIC_PRODUCT': 'Fabric info',
		'PRODUCT_FORM_ORDER': 'Order',
		'PRODUCT_FORM_ORDER_CUSTOMERB2B': 'Customer B2B',
		'PRODUCT_FORM_ORDER_FINALCUSTOMER': 'Final customer',
		'PRODUCT_FORM_SHIPPINGPACKAGE': 'Shipping package',
		'PRODUCT_FORM_SUPPLIER': 'Supplier',
		'PRODUCT_FORM_SUPPLIERREFERENCE': 'Supplier ref.',
		'PRODUCT_FORM_REFERENCE': 'Reference',
		'PRODUCT_FORM_EXTERNALATTRIBUTEID': 'External attribute ID',
		'PRODUCT_FORM_PRODUCTUNIT': 'Product unit',
		'PRODUCT_FORM_COMMENT': 'Comments',
		'PRODUCT_FORM_PRINTED': 'Printed',
		'PRODUCT_FORM_BILLED': 'Billed',
		'PRODUCT_FORM_FABRICPRODUCT_FABRIC': 'Fabric',
		'PRODUCT_FORM_FABRICPRODUCT_CUTLENGH': 'Cut length',
		'PRODUCT_FORM_SHIRT_IMAGE': 'Image',
		'PRODUCT_FORM_SHIRT_MEASUREMENT_PROFILE': 'Profile',
		'PRODUCT_FORM_SHIRT_MEASUREMENT_MODIFIEDDATE': 'Modification date',
		'PRODUCT_FORM_SHIRT_MEASUREMENT_YEAROFBIRTH': 'Year of birth',
		'PRODUCT_FORM_SHIRT_MEASUREMENT_COMMENT': 'Measurement comments',
		'PRODUCT_FORM_SHIRT_MEASUREMENT_PATRONID': 'Patron ID',
		'PRODUCT_FORM_SHIRT_MEASUREMENT_STANDARDSIZEREF': 'Standard size reference',
		'PRODUCT_FORM_SHIRT_SELECT_OPTION': 'Select option',
		'PRODUCT_BACK_TO_ORDER': 'Back to order',
		'PRODUCT_DOWNLOAD_PDF': 'Download PDF',
		'EXPORT_ALL_SHIRTS': 'Export all shirts',
		'PRODUCT_FABRIC_BTN_PRINT_SHEET_ALL_NOT_PRINTED': 'Print all unprint "fabric" sheets',
		'PRODUCT_FABRIC_BTN_PRINT_SHEET_SELECTED': 'Print selected "fabric" sheets',
		'PRODUCT_SHIRT_BTN_PRINT_SHEET_ALL_NOT_PRINTED': 'Print all unprint "shirt" sheets',
		'PRODUCT_SHIRT_BTN_PRINT_SHEET_SELECTED': 'Print selected "shirt" sheets',
		'PRODUCT_SHIRT_BTN_PRINT_TAG_ALL_NOT_PRINTED': 'Print all unprint "shirt" tags',
		'PRODUCT_SHIRT_BTN_PRINT_TAG_SELECTED': 'Print selected "shirt" tags',
		'PRODUCT_BTN_RETRIEVE_BILLING_INFO_FOR_ALL': 'Retrieve billing info for all products',
		'PRODUCT_BTN_RETRIEVE_BILLING_INFO_FOR_SELECTED': 'Retrieve billing info for selected products',
		'PRODUCT_TIMELINE_LOADING': 'Loading events',
		'PRODUCT_TIMELINE_NOT_YET': 'No event yet',
		'PRODUCT_TIMELINE_FABRIC_CUT_REF': 'Fabric : {{ fabricInternalReference }}',
		'PRODUCT_TIMELINE_FABRIC_CUT_SIZE': 'Cut length : {{ fabricCutSize }}',
		'PRODUCT_TITLE_DELETE': 'Product delete',
		'PRODUCT_TITLE_VIEW': 'Product details',
		'PRODUCT_TITLE_LIST': 'Product list',
		'PRODUCT_TITLE_EDIT': 'Product edition',
		'PRODUCT_TITLE_NEW': 'Product creation',
		'PRODUCT_TITLE_IMPORT_XLS': 'Product mass import',
		'PRODUCT_TITLE_EXPORT_XLS': 'Product mass export',
		'PRODUCT_FORM_SENT_TO_SHOP': 'Delivery to shop'
	});
});

angular.module('cottonMeApp').config(function(menuServiceProvider) {
	menuServiceProvider.addItem({
		labelKey: 'PRODUCT_MENU',
		url: '#/app/products',
		iconClass: 'glyphicon glyphicon-equalizer',
		rank: 240
	});
});

'use strict';

angular.module('cottonMeApp').controller('SettingsCtrl', function($log, Principal, AlertService, Settings) {
	$log.getInstance('app.controllers.SettingsCtrl');
    var ctrl = this;

    Settings.getSettings().$promise.then(function(data){
        ctrl.entity = data;
    }).catch(function(error){
		console.log(error);
        AlertService.error('SETTINGS_GET_ERROR');
    });

    ctrl.save = function(){
		ctrl.entity.$save(function() {
			AlertService.success('SETTINGS_SUCCESS_MESSAGE');
		}).catch(function(error) {
			console.log(error);
			AlertService.error('SETTINGS_SAVE_ERROR');
		});
		return true;
    };
});

'use strict';

angular.module('cottonMeApp').config(function ($stateProvider) {
    $stateProvider.state('app.settings', {
        abstract: true,
        url: '/settings',
        template: '<ui-view></ui-view>'
    });

    $stateProvider.state('app.settings.edit', {
        url: '',
        templateUrl: 'modules/settings/settings-view.html',
        controller: 'SettingsCtrl',
        controllerAs: 'ctrl',
        resolve: {

        },

        data: {
            pageTitle: 'SETTINGS_TITLE',
            authorities: ['ROLE_ADMIN']
		}

    });
});

angular.module('cottonMeApp').config(function ($translateProvider) {

    $translateProvider.translations('fr', {
        'SETTING_MENU': 'Settings',
        'SETTING_TITLE_EDIT': 'Edit element',
        'SETTING_FORM_MAX_SHIPPED_DATE': 'Max Shipped Date',
		'SETTING_FORM_BEST_SHIPPED_DATE': 'Best Shipped Date',
        'SETTINGS_GET_ERROR': 'Error when trying to retrieve data',
        'SETTINGS_SUCCESS_MESSAGE': 'Settings updated successfully',
        'SETTINGS_FORM_CURRENCY': 'Currency',
        'SETTINGS_FORM_SHIPPING_PRICE_B2B': 'Shipping price B2B',
        'SETTINGS_FORM_WRAPPING_PRICE_B2B': 'Wrapping price B2B',
        'SETTINGS_FORM_SHIPPING_PRICE_B2C': 'Shipping price B2C',
        'SETTINGS_FORM_WRAPPING_PRICE_B2C': 'Wrapping price B2C'

    });

    $translateProvider.translations('en', {
        'SETTING_MENU': 'Settings',
        'SETTINGS_TITLE': 'Settings',
        'SETTING_TITLE_EDIT': 'Edit element',
        'SETTING_FORM_MAX_SHIPPED_DATE': 'Max Shipped Date',
		'SETTING_FORM_BEST_SHIPPED_DATE': 'Best Shipped Date',
		'SETTINGS_GET_ERROR': 'Error when trying to retrieve data',
        'SETTINGS_SUCCESS_MESSAGE': 'Settings updated successfully',
        'SETTINGS_FORM_CURRENCY': 'Currency',
        'SETTINGS_FORM_SHIPPING_PRICE_B2B': 'Shipping price B2B',
        'SETTINGS_FORM_WRAPPING_PRICE_B2B': 'Wrapping price B2B',
        'SETTINGS_FORM_SHIPPING_PRICE_B2C': 'Shipping price B2C',
        'SETTINGS_FORM_WRAPPING_PRICE_B2C': 'Wrapping price B2C'
    });

    $translateProvider.translations('zh', {
        'SETTING_MENU': 'Settings',
        'SETTING_TITLE_EDIT': 'Edit element',
        'SETTING_FORM_MAX_SHIPPED_DATE': 'Max Shipped Date',
		'SETTING_FORM_BEST_SHIPPED_DATE': 'Best Shipped Date',
		'SETTINGS_GET_ERROR': 'Error when trying to retrieve data',
        'SETTINGS_SUCCESS_MESSAGE': 'Settings updated successfully',
        'SETTINGS_FORM_CURRENCY': 'Currency',
        'SETTINGS_FORM_SHIPPING_PRICE_B2B': 'Shipping price B2B',
        'SETTINGS_FORM_WRAPPING_PRICE_B2B': 'Wrapping price B2B',
        'SETTINGS_FORM_SHIPPING_PRICE_B2C': 'Shipping price B2C',
        'SETTINGS_FORM_WRAPPING_PRICE_B2C': 'Wrapping price B2C'
    });
});

angular.module('cottonMeApp').config(function (menuServiceProvider) {
    menuServiceProvider.addItem({
        labelKey: 'SETTING_MENU',
        url: '#/app/settings',
        iconClass: 'fa fa-cog',
        rank: 902
    });
});

'use strict';

angular.module('cottonMeApp').component('orderProductList', {
	templateUrl: 'modules/order/order-product-list.html',
	bindings: {
		order: '<',
		isView: '<'
	},
	controller: function(NgTableParams, Order) {
		var ctrl = this;
		ctrl.tableParams = new NgTableParams({}, {
			total: 0,
			getData: function(params) {
				return Order.getProductList({
					id: ctrl.order.id
				}).$promise.then(function(entities) {
					params.total(entities.length);
					return entities;
				});
			}
		});
		ctrl.tableParams.settings().counts = [];
	}
});

'use strict';

angular.module('cottonMeApp').config(function($stateProvider) {
	$stateProvider.state('app.order', {
		abstract: true,
		url: '/orders',
		template: '<ui-view></ui-view>'
	});

	$stateProvider.state('app.order.list', {
		url: '',
		templateUrl: 'modules/order/order-list.html',
		controller: 'CrudListCtrl',
		controllerAs: 'ctrl',
		resolve: {
			EntityGenericResource: function(Order) {
				return Order;
			}
		},
		data: {
			pageTitle: 'ORDER_TITLE_LIST',
			authorities: ['ROLE_MANAGER', 'ROLE_ADMIN']
		}
	});

	$stateProvider.state('app.order.view', {
		url: '/view/:entityId',
		templateUrl: 'modules/order/order-view.html',
		controller: 'CrudViewCtrl',
		controllerAs: 'ctrl',
		resolve: {
			entity: function($stateParams, Order) {
				return Order.get({
					id: $stateParams.entityId
				}).$promise;
			}
		},
		data: {
			pageTitle: 'ORDER_TITLE_VIEW',
			authorities: ['ROLE_MANAGER', 'ROLE_ADMIN']
		}
	});

	$stateProvider.state('app.order.edit', {
		url: '/edit/:entityId',
		templateUrl: 'modules/order/order-edit.html',
		controller: 'CrudEditCtrl',
		controllerAs: 'ctrl',
		resolve: {
			entity: function($stateParams, Order) {
				return Order.get({
					id: $stateParams.entityId
				}).$promise;
			},
			EntityGenericResource: function(Order) {
				return Order;
			}
		},
		data: {
			pageTitle: 'ORDER_TITLE_EDIT',
			authorities: ['ROLE_MANAGER', 'ROLE_ADMIN']
		}
	});

	$stateProvider.state('app.order.create', {
		url: '/new',
		templateUrl: 'modules/order/order-edit.html',
		controller: 'CrudEditCtrl',
		controllerAs: 'ctrl',
		resolve: {
			entity: function() {
				return undefined;
			},
			EntityGenericResource: function(Order) {
				return Order;
			}
		},
		data: {
			pageTitle: 'ORDER_TITLE_NEW',
			authorities: ['ROLE_MANAGER', 'ROLE_ADMIN']
		}
	});

	$stateProvider.state('app.order.edit.delete-product', {
		url: '/delete/:productId',
		onEnter: function($state, $uibModal, $stateParams) {
			$uibModal.open({
				templateUrl: 'adama-web/crud/crud-delete.html',
				resolve: {
					entity: function(Product) {
						return Product.get({
							id: $stateParams.productId
						}).$promise;
					},
					title: function() {
						return 'ORDER_TITLE_DELETE_PRODUCT';
					}
				},
				controller: 'CrudDeleteCtrl as ctrl'
			}).result.then(function() {
				if ($stateParams.tableParams) {
					return $stateParams.tableParams.reload();
				}
			}).finally(function() {
				$state.go('^');
			});
		},
		data: {
			pageTitle: 'ORDER_TITLE_DELETE_PRODUCT',
			authorities: ['ROLE_MANAGER', 'ROLE_ADMIN']
		},
		params: {
			tableParams: undefined
		}
	});

	var openModal = function($state, $uibModal, $stateParams, controllerName, templateName, title) {
		var resolveEntity;
		if ($stateParams) {
			resolveEntity = /* @ngInject */ function(Order) {
				return Order.get({
					id: $stateParams.entityId
				}).$promise;
			};
		}
		$uibModal.open({
			templateUrl: 'adama-web/crud/' + templateName,
			resolve: {
				entity: resolveEntity,
				EntityGenericResource: function(Order) {
					return Order;
				},
				title: function() {
					return title;
				}
			},
			controller: controllerName + ' as ctrl'
		}).result.then(function() {
			$state.go('^', {}, {
				reload: true
			});
		}).catch(function() {
			$state.go('^');
		});
	};

	$stateProvider.state('app.order.delete', {
		url: '/delete/:entityId',
		parent: 'app.order.list',
		onEnter: function($state, $uibModal, $stateParams) {
			openModal($state, $uibModal, $stateParams, 'CrudDeleteCtrl', 'crud-delete.html', 'ORDER_TITLE_DELETE');
		},
		data: {
			pageTitle: 'ORDER_TITLE_DELETE',
			authorities: ['ROLE_MANAGER', 'ROLE_ADMIN']
		}
	});

	$stateProvider.state('app.order.importXls', {
		url: '/import-xls',
		parent: 'app.order.list',
		onEnter: function($state, $uibModal) {
			openModal($state, $uibModal, undefined, 'CrudImportXlsCtrl', 'crud-import-xls.html', 'ORDER_TITLE_IMPORT_XLS');
		},
		data: {
			pageTitle: 'ORDER_TITLE_IMPORT_XLS',
			authorities: ['ROLE_MANAGER', 'ROLE_ADMIN']
		}
	});

	$stateProvider.state('app.order.exportXls', {
		url: '/export-xls',
		parent: 'app.order.list',
		onEnter: function($state, $uibModal) {
			openModal($state, $uibModal, undefined, 'CrudExportXlsCtrl', 'crud-export-xls.html', 'ORDER_TITLE_EXPORT_XLS');
		},
		data: {
			pageTitle: 'ORDER_TITLE_EXPORT_XLS',
			authorities: ['ROLE_MANAGER', 'ROLE_ADMIN']
		}
	});
});

angular.module('cottonMeApp').config(function($translateProvider) {
	$translateProvider.translations('fr', {
		'ORDER_MENU': 'Commande',
		'ORDER_LIST_ORDERNUMBER': 'Numéro',
		'ORDER_LIST_CUSTOMERB2B': 'Client B2B',
		'ORDER_LIST_FINALCUSTOMER': 'Client final',
		'ORDER_FORM_TAB_GENERAL': 'Informations générales',
		'ORDER_FORM_TAB_PRODUCT_LIST': 'Liste des produits',
		'ORDER_FORM_ORDERNUMBER': 'Numéro de commande',
		'ORDER_FORM_ORDER': 'Client B2B',
		'ORDER_FORM_CUSTOMERB2B': 'Client B2B',
		'ORDER_FORM_FINALCUSTOMER': 'Client final',
		'ORDER_FORM_FINALCUSTOMER_FIRSTNAME': 'Prénom',
		'ORDER_FORM_FINALCUSTOMER_LASTNAME': 'Nom',
		'ORDER_FORM_FINALCUSTOMER_EXTERNALID': 'Identifiant externe',
		'ORDER_FORM_FINALCUSTOMER_EMAIL': 'Email',
		'ORDER_FORM_FINALCUSTOMER_EXTERNALVENDORID': 'Identifiant vendeur externe',
		'ORDER_FORM_DELIVERYADDRESS': 'Adresse de livraison',
		'ORDER_FORM_DELIVERYADDRESS_ADD': 'Ajouter une adresse de livraison',
		'ORDER_FORM_INVOICEADDRESS': 'Adresse de facturation',
		'ORDER_FORM_INVOICEADDRESS_ADD': 'Ajouter une adresse de facturation',
		'ORDER_FORM_CURRENCYISOCODE': 'Code ISO monnaie',
		'ORDER_FORM_COSTPRICEFINAL': 'Prix coût final',
		'ORDER_FORM_BILLEDPRICE': 'Prix facturé',
		'ORDER_FORM_INVOICENUM': 'Numéro facture',
		'ORDER_FORM_INVOICEDATE': 'Date facturation',
		'ORDER_FORM_CARRIERID': 'Identifiant transporteur',
		'ORDER_FORM_PAYMENTREF': 'Réf. paiement',
		'ORDER_FORM_PAYMENTDATE': 'Date paiement',
		'ORDER_FORM_PAYMENTTYPE': 'Type paiement',
		'ORDER_FORM_NEW_PRODUCT': 'Ajouter un produit',
		'ORDER_PRODUCT_LIST_DELETE': 'Supprimer le produit',
		'ORDER_TITLE_DELETE_PRODUCT': 'Suppression de produit',
		'ORDER_TITLE_DELETE': 'Suppression de commande',
		'ORDER_TITLE_VIEW': 'Détails de commande',
		'ORDER_TITLE_LIST': 'Liste des commandes',
		'ORDER_TITLE_EDIT': 'Editer une commande',
		'ORDER_TITLE_NEW': 'Créer une commande',
		'ORDER_TITLE_IMPORT_XLS': 'Importer en masse des commandes',
		'ORDER_TITLE_EXPORT_XLS': 'Exporter en masse des commandes'
	});

	$translateProvider.translations('en', {
		'ORDER_MENU': 'Order',
		'ORDER_LIST_ORDERNUMBER': 'Number',
		'ORDER_LIST_ORDER': 'Customer B2B',
		'ORDER_LIST_CUSTOMERB2B': 'Customer B2B',
		'ORDER_LIST_FINALCUSTOMER': 'Final customer',
		'ORDER_FORM_TAB_GENERAL': 'General info',
		'ORDER_FORM_TAB_PRODUCT_LIST': 'Product list',
		'ORDER_FORM_ORDERNUMBER': 'Order number',
		'ORDER_FORM_CUSTOMERB2B': 'Customer B2B',
		'ORDER_FORM_FINALCUSTOMER': 'Final customer',
		'ORDER_FORM_FINALCUSTOMER_FIRSTNAME': 'Firstname',
		'ORDER_FORM_FINALCUSTOMER_LASTNAME': 'Lastname',
		'ORDER_FORM_FINALCUSTOMER_EXTERNALID': 'External ID',
		'ORDER_FORM_FINALCUSTOMER_EMAIL': 'Email',
		'ORDER_FORM_FINALCUSTOMER_EXTERNALVENDORID': 'External vendor ID',
		'ORDER_FORM_DELIVERYADDRESS': 'Delivery address',
		'ORDER_FORM_DELIVERYADDRESS_ADD': 'Add a delivery address',
		'ORDER_FORM_INVOICEADDRESS': 'Invoice address',
		'ORDER_FORM_INVOICEADDRESS_ADD': 'Add an invoice address',
		'ORDER_FORM_CURRENCYISOCODE': 'Currency ISO code',
		'ORDER_FORM_COSTPRICEFINAL': 'Cost price final',
		'ORDER_FORM_BILLEDPRICE': 'Billed price',
		'ORDER_FORM_INVOICENUM': 'Invoice number',
		'ORDER_FORM_INVOICEDATE': 'Invoice date',
		'ORDER_FORM_CARRIERID': 'Carrier id',
		'ORDER_FORM_PAYMENTREF': 'Payment ref.',
		'ORDER_FORM_PAYMENTDATE': 'Payment date',
		'ORDER_FORM_PAYMENTTYPE': 'Payment type',
		'ORDER_FORM_NEW_PRODUCT': 'Add a product',
		'ORDER_PRODUCT_LIST_DELETE': 'Delete product',
		'ORDER_TITLE_DELETE_PRODUCT': 'Product delete',
		'ORDER_TITLE_DELETE': 'Order delete',
		'ORDER_TITLE_VIEW': 'Order details',
		'ORDER_TITLE_LIST': 'Order list',
		'ORDER_TITLE_EDIT': 'Order edition',
		'ORDER_TITLE_NEW': 'Order creation',
		'ORDER_TITLE_IMPORT_XLS': 'Order mass import',
		'ORDER_TITLE_EXPORT_XLS': 'Order mass export'
	});

	$translateProvider.translations('zh', {
		'ORDER_MENU': 'Order',
		'ORDER_LIST_ORDERNUMBER': 'Number',
		'ORDER_LIST_ORDER': 'Customer B2B',
		'ORDER_LIST_CUSTOMERB2B': 'Customer B2B',
		'ORDER_LIST_FINALCUSTOMER': 'Final customer',
		'ORDER_FORM_TAB_GENERAL': 'General info',
		'ORDER_FORM_TAB_PRODUCT_LIST': 'Product list',
		'ORDER_FORM_ORDERNUMBER': 'Order number',
		'ORDER_FORM_CUSTOMERB2B': 'Customer B2B',
		'ORDER_FORM_FINALCUSTOMER': 'Final customer',
		'ORDER_FORM_FINALCUSTOMER_FIRSTNAME': 'Firstname',
		'ORDER_FORM_FINALCUSTOMER_LASTNAME': 'Lastname',
		'ORDER_FORM_FINALCUSTOMER_EXTERNALID': 'External ID',
		'ORDER_FORM_FINALCUSTOMER_EMAIL': 'Email',
		'ORDER_FORM_FINALCUSTOMER_EXTERNALVENDORID': 'External vendor ID',
		'ORDER_FORM_DELIVERYADDRESS': 'Delivery address',
		'ORDER_FORM_DELIVERYADDRESS_ADD': 'Add a delivery address',
		'ORDER_FORM_INVOICEADDRESS': 'Invoice address',
		'ORDER_FORM_INVOICEADDRESS_ADD': 'Add an invoice address',
		'ORDER_FORM_CURRENCYISOCODE': 'Currency ISO code',
		'ORDER_FORM_COSTPRICEFINAL': 'Cost price final',
		'ORDER_FORM_BILLEDPRICE': 'Billed price',
		'ORDER_FORM_INVOICENUM': 'Invoice number',
		'ORDER_FORM_INVOICEDATE': 'Invoice date',
		'ORDER_FORM_CARRIERID': 'Carrier id',
		'ORDER_FORM_PAYMENTREF': 'Payment ref.',
		'ORDER_FORM_PAYMENTDATE': 'Payment date',
		'ORDER_FORM_PAYMENTTYPE': 'Payment type',
		'ORDER_FORM_NEW_PRODUCT': 'Add a product',
		'ORDER_PRODUCT_LIST_DELETE': 'Delete product',
		'ORDER_TITLE_DELETE_PRODUCT': 'Product delete',
		'ORDER_TITLE_DELETE': 'Order delete',
		'ORDER_TITLE_VIEW': 'Order details',
		'ORDER_TITLE_LIST': 'Order list',
		'ORDER_TITLE_EDIT': 'Order edition',
		'ORDER_TITLE_NEW': 'Order creation',
		'ORDER_TITLE_IMPORT_XLS': 'Order mass import',
		'ORDER_TITLE_EXPORT_XLS': 'Order mass export'
	});
});

angular.module('cottonMeApp').config(function(menuServiceProvider) {
	menuServiceProvider.addItem({
		labelKey: 'ORDER_MENU',
		url: '#/app/orders',
		iconClass: 'glyphicon glyphicon-equalizer',
		rank: 230
	});
});

'use strict';

angular.module('cottonMeApp').config(function($stateProvider){
    $stateProvider.state('app.options', {
		abstract: true,
		url: '/options',
		template: '<ui-view></ui-view>'
    });

    $stateProvider.state('app.options.list', {
		url: '',
		templateUrl: 'modules/options/options-list.html',
		controller: 'CrudListCtrl',
		controllerAs: 'ctrl',
		resolve: {
		EntityGenericResource: function(Options) {
			console.log(Options);
			return Options;
		}
		},
		data: {
			pageTitle: 'OPTIONS_LIST',
			authorities: ['ROLE_MANAGER', 'ROLE_ADMIN']
		}
	});

	$stateProvider.state('app.options.view', {
		url: '/view/:entityId',
		templateUrl: 'modules/options/options-view.html',
		controller: 'CrudViewCtrl',
		controllerAs: 'ctrl',
		resolve: {
			entity: function($stateParams, Options) {
				return Options.get({
					id: $stateParams.entityId
				}).$promise;
			}
		},
		data: {
			pageTitle: 'OPTIONS_TITLE_VIEW',
			authorities: ['ROLE_MANAGER', 'ROLE_ADMIN']
		}
	});
});

angular.module('cottonMeApp').config(function($translateProvider) {
	$translateProvider.translations('fr', {
        'OPTIONS_MENU': 'Options',
        'OPTIONS_LIST': 'Options',
		'OPTION_TITLE_LIST': 'Liste des options',
		'OPTIONS_OPTION_CATEGORY': 'Catégorie',
		'OPTIONS_OPTION_NAME': 'Nom',
		'OPTIONS_CODE': 'Code',
		'OPTIONS_ENGLISH_NAME': 'Nom Anglais',
		'OPTIONS_CHINESE_NAME': 'Nom Chinois',
		'OPTIONS_OPTION_SIZE_1': 'Taille 1',
		'OPTIONS_OPTION_SIZE_2': 'Taille 2',
		'OPTIONS_OPTION_FILENAME': 'Nom de fichier',
		'OPTIONS_OPTION_PRICE': 'Prix',
		'OPTIONS_OPTION_DEFAULT_VALUE': 'Valeur par défaut',
		'OPTIONS_OPTION_INDEX': 'Index',
		'OPTIONS_TITLE_VIEW': 'Détail d\'une option',
		'OPTION_TITLE_EDIT': 'Editer une option'
	});

	$translateProvider.translations('en', {
        'OPTIONS_MENU': 'Options',
        'OPTIONS_LIST': 'Options',
		'OPTION_TITLE_LIST': 'Options list',
		'OPTIONS_OPTION_CATEGORY': 'Category',
		'OPTIONS_OPTION_NAME': 'Name',
		'OPTIONS_CODE': 'Code',
		'OPTIONS_ENGLISH_NAME': 'English Name',
		'OPTIONS_CHINESE_NAME': 'Chinese Name',
		'OPTIONS_OPTION_SIZE_1': 'Size 1',
		'OPTIONS_OPTION_SIZE_2': 'Size 2',
		'OPTIONS_OPTION_FILENAME': 'Filename',
		'OPTIONS_OPTION_PRICE': 'Price',
		'OPTIONS_OPTION_DEFAULT_VALUE': 'Default value',
		'OPTIONS_OPTION_INDEX': 'Index',
		'OPTIONS_TITLE_VIEW': 'Option details',
		'OPTION_TITLE_EDIT': 'Option edition'
	});

	$translateProvider.translations('zh', {
        'OPTIONS_MENU': 'Options',
        'OPTIONS_LIST': 'Options',
		'OPTION_TITLE_LIST': 'Options list',
		'OPTIONS_OPTION_CATEGORY': 'Category',
		'OPTIONS_OPTION_NAME': 'Name',
		'OPTIONS_CODE': 'Code',
		'OPTIONS_ENGLISH_NAME': 'English Name',
		'OPTIONS_CHINESE_NAME': 'Chinese Name',
		'OPTIONS_OPTION_SIZE_1': 'Size 1',
		'OPTIONS_OPTION_SIZE_2': 'Size 2',
		'OPTIONS_OPTION_FILENAME': 'Filename',
		'OPTIONS_OPTION_PRICE': 'Price',
		'OPTIONS_OPTION_DEFAULT_VALUE': 'Default value',
		'OPTIONS_OPTION_INDEX': 'Index',
		'OPTIONS_TITLE_VIEW': 'Option details',
		'OPTION_TITLE_EDIT': 'Option edition'
	});
});

angular.module('cottonMeApp').config(function(menuServiceProvider) {
	menuServiceProvider.addItem({
		labelKey: 'OPTIONS_MENU',
		url: '#/app/options',
		iconClass: 'glyphicon glyphicon-equalizer',
		rank: 220
	});
});

'use strict';

angular.module('cottonMeApp').controller('MainCtrl', function($log, Principal, AlertService, Stats) {
	$log.getInstance('app.controllers.SettingsCtrl');
	var ctrl = this;

	Stats.getKPI().$promise.then(function(data){
		ctrl.entity = data;
    }).catch(function(error){
		console.log(error);
        AlertService.error('STATS_GET_ERROR');
    });
});

'use strict';

angular.module('cottonMeApp').config(function($stateProvider) {
	$stateProvider.state('app.main', {
		url: '/',
		templateUrl: 'modules/main/main.html',
		controller: 'MainCtrl',
		controllerAs: 'ctrl',
		data: {
			pageTitle: 'HOME'
		}
	});
});

angular.module('cottonMeApp').config(function($translateProvider) {
	$translateProvider.translations('fr', {
		'HOME': 'Accueil',
		'STATS_GET_ERROR': 'Error when trying to retrieve data',
		'STATS_FACTORY': 'Factory',
		'STATS_OFFICE': 'Office',
		'STATS_TOTAL_FACTORY': 'Tot QT in factory',
		'STATS_TOTAL_URGENT': 'Total URG products',
		'STATS_TOTAL_IN': 'Total in',
		'STATS_TOTAL_OUT': 'Total out',
		'STATS_ON_THE_WAY': 'On the way',
		'STATS_AVERAGE_TIME_FACTORY': 'average Total Time in factory',
		'STATS_NEW_SHIRT_IN': 'Today new shirts in',
		'STATS_SENT_TO_FATORY': 'Sent to factory',
		'STATS_IN_FACTORY': 'In factory',
		'STATS_QUALITY_CONTROL_OK': 'Quality control OK',
		'STATS_SHIPPED' : 'Shipped',
		'STATS_AVERAGE_PRE_PROCESSING_TIME': 'Average pre-processing time',
		'STATS_AVERAGE_POST_PROCESSING_TIME': 'Average post-processing time',
		'STATS_DATE': 'Date'
	});

	$translateProvider.translations('en', {
		'HOME': 'Home',
		'STATS_GET_ERROR': 'Error when trying to retrieve data',
		'STATS_FACTORY': 'Factory',
		'STATS_OFFICE': 'Office',
		'STATS_TOTAL_FACTORY': 'Tot QT in factory',
		'STATS_TOTAL_URGENT': 'Total URG products',
		'STATS_TOTAL_IN': 'Total in',
		'STATS_TOTAL_OUT': 'Total out',
		'STATS_ON_THE_WAY': 'On the way',
		'STATS_AVERAGE_TIME_FACTORY': 'average Total Time in factory',
		'STATS_NEW_SHIRT_IN': 'Today new shirts in',
		'STATS_SENT_TO_FATORY': 'Sent to factory',
		'STATS_IN_FACTORY': 'In factory',
		'STATS_QUALITY_CONTROL_OK': 'Quality control OK',
		'STATS_SHIPPED' : 'Shipped',
		'STATS_AVERAGE_PRE_PROCESSING_TIME': 'Average pre-processing time',
		'STATS_AVERAGE_POST_PROCESSING_TIME': 'Average post-processing time',
		'STATS_DATE': 'Date'
	});

	$translateProvider.translations('zh', {
		'HOME': 'Home',
		'STATS_GET_ERROR': 'Error when trying to retrieve data',
		'STATS_FACTORY': 'Factory',
		'STATS_OFFICE': 'Office',
		'STATS_TOTAL_FACTORY': 'Tot QT in factory',
		'STATS_TOTAL_URGENT': 'Total URG products',
		'STATS_TOTAL_IN': 'Total in',
		'STATS_TOTAL_OUT': 'Total out',
		'STATS_ON_THE_WAY': 'On the way',
		'STATS_AVERAGE_TIME_FACTORY': 'average Total Time in factory',
		'STATS_NEW_SHIRT_IN': 'Today new shirts in',
		'STATS_SENT_TO_FATORY': 'Sent to factory',
		'STATS_IN_FACTORY': 'In factory',
		'STATS_QUALITY_CONTROL_OK': 'Quality control OK',
		'STATS_SHIPPED' : 'Shipped',
		'STATS_AVERAGE_PRE_PROCESSING_TIME': 'Average pre-processing time',
		'STATS_AVERAGE_POST_PROCESSING_TIME': 'Average post-processing time',
		'STATS_DATE': 'Date'
	});

});

'use strict';

angular.module('cottonMeApp').config(function ($stateProvider) {
    $stateProvider.state('app.invoices', {
        abstract: true,
        url: '/invoices',
        template: '<ui-view></ui-view>'
    });

    $stateProvider.state('app.invoices.list', {
        url: '',
        templateUrl: 'modules/invoices/invoices-list.html',
        controller: 'CrudListCtrl',
        controllerAs: 'ctrl',
        resolve: {
            EntityGenericResource: function(Invoices) {
                return Invoices;
            }
        },
        data: {
            pageTitle: 'INVOICES_TITLE_LIST',
            authorities: ['ROLE_MANAGER', 'ROLE_ADMIN']
        }
    });
});

angular.module('cottonMeApp').config(function(menuServiceProvider) {
    menuServiceProvider.addItem({
        labelKey: 'INVOICES_MENU',
        url: '#/app/invoices',
        iconClass: 'icon-fabric',
        rank: 250
    });
});

angular.module('cottonMeApp').config(function($translateProvider) {
    $translateProvider.translations('en', {
        'INVOICES_MENU': 'Invoices',
        'INVOICES_TITLE_LIST': 'Invoices',
        'INVOICE_NUMBER': 'Invoice number',
        'INVOICE_CUSTOMER_B2B': 'CustomerB2B',
        'INVOICE_TOTAL': 'Total',
        'INVOICE_TOTAL_PAID': 'Total paid',
        'INVOICE_STATUS': 'Status'
    });
    $translateProvider.translations('zh', {
        'INVOICES_MENU': 'Invoices',
        'INVOICES_TITLE_LIST': 'Invoices',
        'INVOICE_NUMBER': 'Invoice number',
        'INVOICE_CUSTOMER_B2B': 'CustomerB2B',
        'INVOICE_TOTAL': 'Total',
        'INVOICE_TOTAL_PAID': 'Total paid',
        'INVOICE_STATUS': 'Status'
    });
    $translateProvider.translations('fr', {
        'INVOICES_MENU': 'Invoices',
        'INVOICES_TITLE_LIST': 'Invoices',
        'INVOICE_NUMBER': 'Invoice number',
        'INVOICE_CUSTOMER_B2B': 'CustomerB2B',
        'INVOICE_TOTAL': 'Total',
        'INVOICE_TOTAL_PAID': 'Total paid',
        'INVOICE_STATUS': 'Status'
    });
});

'use strict';

angular.module('cottonMeApp').controller('FabricAdjustmentEditCtrl', function ($state, entity, EntityGenericResource) {
  var ctrl = this;
  ctrl.isEdition = !!entity && !!entity.id;
  ctrl.entity = entity;
  ctrl.entityGenericResource = EntityGenericResource;
  ctrl.afterActionCallback = function (newEntity) {
    if (ctrl.isEdition) {
      ctrl.entity = newEntity;
    } else {
      $state.go('^.edit', {
        entityId: newEntity.id
      });
    }
  };
});

'use strict';

angular.module('cottonMeApp').config(function ($stateProvider) {
  $stateProvider.state('app.fabricadjustments', {
    abstract: true,
    url: '/fabricadjustments',
    template: '<ui-view></ui-view>'
  });

  $stateProvider.state('app.fabricadjustments.list', {
    url: '',
    templateUrl: 'modules/fabricAdjustment/fabric-adjustment-list.html',
    controller: 'CrudListCtrl',
    controllerAs: 'ctrl',
    resolve: {
      EntityGenericResource: function (FabricAdjustment) {
        return FabricAdjustment;
      }
    },
    data: {
      pageTitle: 'FABRIC_ADJUSTMENT_TITLE_LIST',
      authorities: ['ROLE_MANAGER', 'ROLE_ADMIN']
    }
  });

  $stateProvider.state('app.fabricadjustments.create', {
    url: '/new',
    templateUrl: 'modules/fabricAdjustment/fabric-adjustment-edit.html',
    controller: 'FabricAdjustmentEditCtrl',
    controllerAs: 'ctrl',
    resolve: {
      entity: function () {
        return {
          adjustmentType: 'MANUAL_ADJUSTMENT'
        };
      },
      EntityGenericResource: function (FabricAdjustment) {
        return FabricAdjustment;
      }
    },
    data: {
      pageTitle: 'FABRIC_ADJUSTMENT_TITLE_NEW',
      authorities: ['ROLE_MANAGER', 'ROLE_ADMIN']
    }
  });
});

angular.module('cottonMeApp').config(function ($translateProvider) {
  $translateProvider.translations('en', {
    'FABRIC_ADJUSTMENT_MENU': 'Fabric Adjustment',
    'FABRIC_ADJUSTMENT_TITLE_LIST': 'Fabric adjustments',
    'FABRIC_ADJUSTMENT_TITLE_NEW': 'New manual fabric adjustment',
    'FABRIC_ADJUSTMENT_TITLE_EDIT': 'Edit manual fabric adjustment',
    'FABRIC_ADJUSTMENT_LIST_FABRIC_REFERENCE': 'Fabric reference',
    'FABRIC_ADJUSTMENT_LIST_PRODUCT_REFERENCE': 'Product reference',
    'FABRIC_ADJUSTMENT_LIST_ADJUSTMENT_AMOUNT': 'Adjustment length (cm)',
    'FABRIC_ADJUSTMENT_LIST_ADJUSTMENT_TYPE': 'Adjustment type',
    'FABRIC_ADJUSTMENT_LIST_ADJUSTMENT_RESPONSIBLE': 'Created by',
    'FABRIC_ADJUSTMENT_LIST_ADJUSTMENT_DATE': 'Created on',
    'FABRIC_ADJUSTMENT_FORM_FABRIC': 'Fabric',
    'FABRIC_ADJUSTMENT_FORM_ADJUSTMENT_LENGTH': 'Adjustment length (can be negative and should be in centimeter with no decimals)',
    'FABRIC_ADJUSTMENT_FORM_PRODUCT': 'Product',
    'FABRIC_ADJUSTMENT_FORM_DESCRIPTION': 'Description',
    'FABRIC_ADJUSTMENT_LIST_DESCRIPTION': 'Description',
    'FABRIC_ADJUSTMENT_LIST_ORDER': 'Order',
    'FABRIC_ADJUSTMENT_LIST_CUSTOMER': 'Customer'
  });
  $translateProvider.translations('zh', {
    'FABRIC_ADJUSTMENT_MENU': 'Fabric Adjustment',
    'FABRIC_ADJUSTMENT_TITLE_LIST': 'Fabric adjustments',
    'FABRIC_ADJUSTMENT_TITLE_NEW': 'New manual fabric adjustment',
    'FABRIC_ADJUSTMENT_TITLE_EDIT': 'Edit manual fabric adjustment',
    'FABRIC_ADJUSTMENT_LIST_FABRIC_REFERENCE': 'Fabric reference',
    'FABRIC_ADJUSTMENT_LIST_PRODUCT_REFERENCE': 'Product reference',
    'FABRIC_ADJUSTMENT_LIST_ADJUSTMENT_AMOUNT': 'Adjustment length (cm)',
    'FABRIC_ADJUSTMENT_LIST_ADJUSTMENT_TYPE': 'Adjustment type',
    'FABRIC_ADJUSTMENT_LIST_ADJUSTMENT_RESPONSIBLE': 'Created by',
    'FABRIC_ADJUSTMENT_LIST_ADJUSTMENT_DATE': 'Created on',
    'FABRIC_ADJUSTMENT_FORM_FABRIC': 'Fabric',
    'FABRIC_ADJUSTMENT_FORM_ADJUSTMENT_LENGTH': 'Adjustment length (can be negative and should be in centimeter with no decimals)',
    'FABRIC_ADJUSTMENT_FORM_PRODUCT': 'Product',
    'FABRIC_ADJUSTMENT_FORM_DESCRIPTION': 'Description',
    'FABRIC_ADJUSTMENT_LIST_DESCRIPTION': 'Description',
    'FABRIC_ADJUSTMENT_LIST_ORDER': 'Order',
    'FABRIC_ADJUSTMENT_LIST_CUSTOMER': 'Customer'
  });
  $translateProvider.translations('fr', {
    'FABRIC_ADJUSTMENT_MENU': 'Fabric Adjustment',
    'FABRIC_ADJUSTMENT_TITLE_LIST': 'Fabric adjustments',
    'FABRIC_ADJUSTMENT_TITLE_NEW': 'New manual fabric adjustment',
    'FABRIC_ADJUSTMENT_TITLE_EDIT': 'Edit manual fabric adjustment',
    'FABRIC_ADJUSTMENT_LIST_FABRIC_REFERENCE': 'Fabric reference',
    'FABRIC_ADJUSTMENT_LIST_PRODUCT_REFERENCE': 'Product reference',
    'FABRIC_ADJUSTMENT_LIST_ADJUSTMENT_AMOUNT': 'Adjustment length (cm)',
    'FABRIC_ADJUSTMENT_LIST_ADJUSTMENT_TYPE': 'Adjustment type',
    'FABRIC_ADJUSTMENT_LIST_ADJUSTMENT_RESPONSIBLE': 'Created by',
    'FABRIC_ADJUSTMENT_LIST_ADJUSTMENT_DATE': 'Created on',
    'FABRIC_ADJUSTMENT_FORM_FABRIC': 'Fabric',
    'FABRIC_ADJUSTMENT_FORM_ADJUSTMENT_LENGTH': 'Adjustment length (can be negative and should be in centimeter with no decimals)',
    'FABRIC_ADJUSTMENT_FORM_PRODUCT': 'Product',
    'FABRIC_ADJUSTMENT_FORM_DESCRIPTION': 'Description',
    'FABRIC_ADJUSTMENT_LIST_DESCRIPTION': 'Description',
    'FABRIC_ADJUSTMENT_LIST_ORDER': 'Order',
    'FABRIC_ADJUSTMENT_LIST_CUSTOMER': 'Customer'
  });
});

angular.module('cottonMeApp').config(function (menuServiceProvider) {
  menuServiceProvider.addItem({
    labelKey: 'FABRIC_ADJUSTMENT_MENU',
    url: '#/app/fabricadjustments',
    iconClass: 'icon-fabric',
    rank: 211
  });
});

'use strict';

angular.module('cottonMeApp').config(function($stateProvider) {
	$stateProvider.state('app.fabric', {
		abstract: true,
		url: '/fabrics',
		template: '<ui-view></ui-view>'
	});

	$stateProvider.state('app.fabric.list', {
		url: '',
		templateUrl: 'modules/fabric/fabric-list.html',
		controller: 'CrudListCtrl',
		controllerAs: 'ctrl',
		resolve: {
			EntityGenericResource: function(Fabric) {
				return Fabric;
			}
		},
		data: {
			pageTitle: 'FABRIC_TITLE_LIST',
			authorities: ['ROLE_MANAGER', 'ROLE_ADMIN']
		}
	});

	$stateProvider.state('app.fabric.view', {
		url: '/view/:entityId',
		templateUrl: 'modules/fabric/fabric-view.html',
		controller: 'CrudViewCtrl',
		controllerAs: 'ctrl',
		resolve: {
			entity: function($stateParams, Fabric) {
				return Fabric.get({
					id: $stateParams.entityId
				}).$promise;
			}
		},
		data: {
			pageTitle: 'FABRIC_TITLE_VIEW',
			authorities: ['ROLE_MANAGER', 'ROLE_ADMIN']
		}
	});

	$stateProvider.state('app.fabric.edit', {
		url: '/edit/:entityId',
		templateUrl: 'modules/fabric/fabric-edit.html',
		controller: 'CrudEditCtrl',
		controllerAs: 'ctrl',
		resolve: {
			entity: function($stateParams, Fabric) {
				return Fabric.get({
					id: $stateParams.entityId
				}).$promise;
			},
			EntityGenericResource: function(Fabric) {
				return Fabric;
			}
		},
		data: {
			pageTitle: 'FABRIC_TITLE_EDIT',
			authorities: ['ROLE_MANAGER', 'ROLE_ADMIN']
		}
	});

	$stateProvider.state('app.fabric.create', {
		url: '/new',
		templateUrl: 'modules/fabric/fabric-edit.html',
		controller: 'CrudEditCtrl',
		controllerAs: 'ctrl',
		resolve: {
			entity: function() {
				return undefined;
			},
			EntityGenericResource: function(Fabric) {
				return Fabric;
			}
		},
		data: {
			pageTitle: 'FABRIC_TITLE_NEW',
			authorities: ['ROLE_MANAGER', 'ROLE_ADMIN']
		}
	});

	var openModal = function($state, $uibModal, $stateParams, controllerName, templateName, title) {
		var resolveEntity;
		if ($stateParams) {
			resolveEntity = /* @ngInject */ function(Fabric) {
				return Fabric.get({
					id: $stateParams.entityId
				}).$promise;
			};
		}
		$uibModal.open({
			templateUrl: 'adama-web/crud/' + templateName,
			resolve: {
				entity: resolveEntity,
				EntityGenericResource: function(Fabric) {
					return Fabric;
				},
				title: function() {
					return title;
				}
			},
			controller: controllerName + ' as ctrl'
		}).result.then(function() {
			$state.go('^', {}, {
				reload: true
			});
		}).catch(function() {
			$state.go('^');
		});
	};

	$stateProvider.state('app.fabric.delete', {
		url: '/delete/:entityId',
		parent: 'app.fabric.list',
		onEnter: function($state, $uibModal, $stateParams) {
			openModal($state, $uibModal, $stateParams, 'CrudDeleteCtrl', 'crud-delete.html', 'FABRIC_TITLE_DELETE');
		},
		data: {
			pageTitle: 'FABRIC_TITLE_DELETE',
			authorities: ['ROLE_MANAGER', 'ROLE_ADMIN']
		}
	});

	$stateProvider.state('app.fabric.importXls', {
		url: '/import-xls',
		parent: 'app.fabric.list',
		onEnter: function($state, $uibModal) {
			openModal($state, $uibModal, undefined, 'CrudImportXlsCtrl', 'crud-import-xls.html', 'FABRIC_TITLE_IMPORT_XLS');
		},
		data: {
			pageTitle: 'FABRIC_TITLE_IMPORT_XLS',
			authorities: ['ROLE_MANAGER', 'ROLE_ADMIN']
		}
	});

	$stateProvider.state('app.fabric.exportXls', {
		url: '/export-xls',
		parent: 'app.fabric.list',
		onEnter: function($state, $uibModal) {
			openModal($state, $uibModal, undefined, 'CrudExportXlsCtrl', 'crud-export-xls.html', 'FABRIC_TITLE_EXPORT_XLS');
		},
		data: {
			pageTitle: 'FABRIC_TITLE_EXPORT_XLS',
			authorities: ['ROLE_MANAGER', 'ROLE_ADMIN']
		}
	});
});

angular.module('cottonMeApp').config(function($translateProvider) {
	$translateProvider.translations('fr', {
		'FABRIC_MENU': 'Tissus',
		'FABRIC_LIST_SUPPLIERREF': 'Référence fournisseur',
		'FABRIC_LIST_INTERNALREFERENCE': 'Référence interne',
		'FABRIC_LIST_COLOR': 'Couleur',
		'FABRIC_LIST_METERSTOCK': 'Mêtre en stock',
		'FABRIC_LIST_WEAVING': 'Tissage',
		'FABRIC_LIST_PATTERN': 'Motif',
		'FABRIC_FORM_SUPPLIERREF': 'Référence fournisseur',
		'FABRIC_FORM_SUPPLIERCOLLECTION': 'Collection fournisseur',
		'FABRIC_FORM_INTERNALREFERENCE': 'Référence interne',
		'FABRIC_FORM_INTERNALCOLLECTION': 'Collection interne',
		'FABRIC_FORM_WEAVING': 'Tissage',
		'FABRIC_FORM_PATTERN': 'Motif',
		'FABRIC_FORM_COLOR': 'Couleur',
		'FABRIC_FORM_COLOR2': 'Couleur 2',
		'FABRIC_FORM_DOUBLETWISTED': 'Double retors',
		'FABRIC_FORM_WEBPRICEALLTAXINCLUDED': 'Prix web TTC',
		'FABRIC_FORM_BUYINGPRICEBYMETERTAXEXCLUDED': 'Prix achat au mêtre tax non comprise',
		'FABRIC_FORM_BUYINGPRICETAXEXCLUDED': 'Prix achat taxe non comprise',
		'FABRIC_FORM_COMPOSITION': 'Composition',
		'FABRIC_FORM_ORIGIN': 'Origine',
		'FABRIC_FORM_GRAMBYSQUAREMETER': 'Gramme par mêtre carré',
		'FABRIC_FORM_WEIGHTBYSHIRT': 'Poids par chemise',
		'FABRIC_FORM_FULLTITRATION': 'Titration complète',
		'FABRIC_FORM_TITRATION': 'Titration',
		'FABRIC_FORM_SUPPLIER': 'Fournisseur',
		'FABRIC_FORM_METERSTOCK': 'Mêtre en stock',
		'FABRIC_FORM_SHIRTSTOCK': 'Chemise en stock',
		'FABRIC_FORM_DOUBLECOUNT': 'Double comptage',
		'FABRIC_FORM_EASYIRONING': 'Repassage facile',
		'FABRIC_FORM_BESTSELLERS': 'Meilleure vente',
		'FABRIC_FORM_NEWPRODUCT': 'Nouveau produit',
		'FABRIC_FORM_LINING': 'Doublure',
		'FABRIC_FORM_PICTURE': 'Visuel',
		'FABRIC_FORM_COMMENTS': 'Commentaires',
		'FABRIC_FORM_FABRICOWNER': 'Propriétaire',
		'FABRIC_FORM_FABRICWIDTH': 'Largeur du tissus',
		'FABRIC_TITLE_DELETE': 'Suppression d\'un tissus',
		'FABRIC_TITLE_VIEW': 'Détails d\'un tissus',
		'FABRIC_TITLE_LIST': 'Liste des tissus',
		'FABRIC_TITLE_EDIT': 'Editer un tissus',
		'FABRIC_TITLE_NEW': 'Créer un tissus',
		'FABRIC_TITLE_IMPORT_XLS': 'Importer en masse des tissus',
		'FABRIC_TITLE_EXPORT_XLS': 'Exporter en masse des tissus'
	});

	$translateProvider.translations('en', {
		'FABRIC_MENU': 'Fabric',
		'FABRIC_LIST_SUPPLIERREF': 'Supplier ref',
		'FABRIC_LIST_INTERNALREFERENCE': 'Internal reference',
		'FABRIC_LIST_COLOR': 'Color',
		'FABRIC_LIST_METERSTOCK': 'Meter in stock',
		'FABRIC_LIST_WEAVING': 'Weaving',
		'FABRIC_LIST_PATTERN': 'Pattern',
		'FABRIC_FORM_SUPPLIERREF': 'Supplier reference',
		'FABRIC_FORM_SUPPLIERCOLLECTION': 'Supplier collection',
		'FABRIC_FORM_INTERNALREFERENCE': 'Internal reference',
		'FABRIC_FORM_INTERNALCOLLECTION': 'Internal collection',
		'FABRIC_FORM_WEAVING': 'Weaving',
		'FABRIC_FORM_PATTERN': 'Pattern',
		'FABRIC_FORM_COLOR': 'Color',
		'FABRIC_FORM_COLOR2': 'Color2',
		'FABRIC_FORM_DOUBLETWISTED': 'Double twisted',
		'FABRIC_FORM_WEBPRICEALLTAXINCLUDED': 'Web price all taxes included',
		'FABRIC_FORM_BUYINGPRICEBYMETERTAXEXCLUDED': 'Buying price by meter taxes excluded',
		'FABRIC_FORM_BUYINGPRICETAXEXCLUDED': 'Buying price taxes excluded',
		'FABRIC_FORM_COMPOSITION': 'Composition',
		'FABRIC_FORM_ORIGIN': 'Origin',
		'FABRIC_FORM_GRAMBYSQUAREMETER': 'Gram by square meter',
		'FABRIC_FORM_WEIGHTBYSHIRT': 'Weight by shirt',
		'FABRIC_FORM_FULLTITRATION': 'Full titration',
		'FABRIC_FORM_TITRATION': 'Titration',
		'FABRIC_FORM_SUPPLIER': 'Supplier',
		'FABRIC_FORM_METERSTOCK': 'Meter in stock',
		'FABRIC_FORM_SHIRTSTOCK': 'Shirt in stock',
		'FABRIC_FORM_DOUBLECOUNT': 'Double count',
		'FABRIC_FORM_EASYIRONING': 'Easy ironing',
		'FABRIC_FORM_BESTSELLERS': 'Best sellers',
		'FABRIC_FORM_NEWPRODUCT': 'New product',
		'FABRIC_FORM_LINING': 'Lining',
		'FABRIC_FORM_PICTURE': 'Picture',
		'FABRIC_FORM_COMMENTS': 'Comments',
		'FABRIC_FORM_FABRICOWNER': 'Owner',
		'FABRIC_FORM_FABRICWIDTH': 'Fabric width',
		'FABRIC_TITLE_DELETE': 'Fabric delete',
		'FABRIC_TITLE_VIEW': 'Fabric details',
		'FABRIC_TITLE_LIST': 'Fabric list',
		'FABRIC_TITLE_EDIT': 'Fabric edition',
		'FABRIC_TITLE_NEW': 'Fabric creation',
		'FABRIC_TITLE_IMPORT_XLS': 'Fabrics mass import',
		'FABRIC_TITLE_EXPORT_XLS': 'Fabrics mass export'
	});

	$translateProvider.translations('zh', {
		'FABRIC_MENU': 'Fabric',
		'FABRIC_LIST_SUPPLIERREF': 'Supplier ref',
		'FABRIC_LIST_INTERNALREFERENCE': 'Internal reference',
		'FABRIC_LIST_COLOR': 'Color',
		'FABRIC_LIST_METERSTOCK': 'Meter in stock',
		'FABRIC_LIST_WEAVING': 'Weaving',
		'FABRIC_LIST_PATTERN': 'Pattern',
		'FABRIC_FORM_SUPPLIERREF': 'Supplier reference',
		'FABRIC_FORM_SUPPLIERCOLLECTION': 'Supplier collection',
		'FABRIC_FORM_INTERNALREFERENCE': 'Internal reference',
		'FABRIC_FORM_INTERNALCOLLECTION': 'Internal collection',
		'FABRIC_FORM_WEAVING': 'Weaving',
		'FABRIC_FORM_PATTERN': 'Pattern',
		'FABRIC_FORM_COLOR': 'Color',
		'FABRIC_FORM_COLOR2': 'Color2',
		'FABRIC_FORM_DOUBLETWISTED': 'Double twisted',
		'FABRIC_FORM_WEBPRICEALLTAXINCLUDED': 'Web price all taxes included',
		'FABRIC_FORM_BUYINGPRICEBYMETERTAXEXCLUDED': 'Buying price by meter taxes excluded',
		'FABRIC_FORM_BUYINGPRICETAXEXCLUDED': 'Buying price taxes excluded',
		'FABRIC_FORM_COMPOSITION': 'Composition',
		'FABRIC_FORM_ORIGIN': 'Origin',
		'FABRIC_FORM_GRAMBYSQUAREMETER': 'Gram by square meter',
		'FABRIC_FORM_WEIGHTBYSHIRT': 'Weight by shirt',
		'FABRIC_FORM_FULLTITRATION': 'Full titration',
		'FABRIC_FORM_TITRATION': 'Titration',
		'FABRIC_FORM_SUPPLIER': 'Supplier',
		'FABRIC_FORM_METERSTOCK': 'Meter in stock',
		'FABRIC_FORM_SHIRTSTOCK': 'Shirt in stock',
		'FABRIC_FORM_DOUBLECOUNT': 'Double count',
		'FABRIC_FORM_EASYIRONING': 'Easy ironing',
		'FABRIC_FORM_BESTSELLERS': 'Best sellers',
		'FABRIC_FORM_NEWPRODUCT': 'New product',
		'FABRIC_FORM_LINING': 'Lining',
		'FABRIC_FORM_PICTURE': 'Picture',
		'FABRIC_FORM_COMMENTS': 'Comments',
		'FABRIC_FORM_FABRICOWNER': 'Owner',
		'FABRIC_FORM_FABRICWIDTH': 'Fabric width',
		'FABRIC_TITLE_DELETE': 'Fabric delete',
		'FABRIC_TITLE_VIEW': 'Fabric details',
		'FABRIC_TITLE_LIST': 'Fabric list',
		'FABRIC_TITLE_EDIT': 'Fabric edition',
		'FABRIC_TITLE_NEW': 'Fabric creation',
		'FABRIC_TITLE_IMPORT_XLS': 'Fabrics mass import',
		'FABRIC_TITLE_EXPORT_XLS': 'Fabrics mass export'
	});
});

angular.module('cottonMeApp').config(function(menuServiceProvider) {
	menuServiceProvider.addItem({
		labelKey: 'FABRIC_MENU',
		url: '#/app/fabrics',
		iconClass: 'icon-fabric',
		rank: 210
	});
});

'use strict';

angular.module('adama-web').controller('WatchStatusCtrl', function($uibModalInstance, AlertService, entity, title) {
	var ctrl = this;
	ctrl.entity = entity;
	ctrl.title = title;

	ctrl.missingStatus = false;

	ctrl.setWatch = function() {
		ctrl.missingStatus = false;
		if(ctrl.entity.watchStatus === 'URGENT')
		{
			ctrl.entity.$setUrgent().then(function() {
				AlertService.success('PRODUCT_WATCH_LIST_SUCCESS_MESSAGE');
				$uibModalInstance.close();
			}).catch(function() {
				AlertService.error('CRUD_SAVE_ERROR');
			});
			return;
		}

		if(ctrl.entity.watchStatus === 'WATCH')
		{
			ctrl.entity.$setWatch().then(function() {
				AlertService.success('PRODUCT_WATCH_LIST_SUCCESS_MESSAGE');
				$uibModalInstance.close();
			}).catch(function() {
				AlertService.error('CRUD_SAVE_ERROR');
			});
			return;
		}

		ctrl.missingStatus = true;
	};
});


'use strict';

angular.module('cottonMeApp').factory('dashboardService', function($http, $timeout, $rootScope, $log, $state, $q, adamaConstant) {
	var log = $log.getInstance('app.service.dashboardService');
	var api = {};
	var monitoringDelayInMs = adamaConstant.dashboardRefreshInMiliss || 30000;
	var timer;

	var update = function() {
		return $http({
			method: 'GET',
			url: adamaConstant.apiBase + 'dashboard',
			ignoreLoadingBar: true
		}).then(function(response) {
			var dashboards = response.data;
			log.debug('dashboards', dashboards);
			angular.forEach(dashboards, function(dashboard) {
				$rootScope.$broadcast(dashboard.type + '-event', {
					value: dashboard.value
				});
			});
			return response.data;
		});
	};

	var resetTimer = function(delay) {
		if (!!timer) {
			$timeout.cancel(timer);
		}
		timer = $timeout(function() {
			var promise = $q.when();
			if ($state.current.name.indexOf('app.') === 0){
				promise = update();
			}
			promise.finally(function() {
				resetTimer(monitoringDelayInMs);
			});
		}, delay);
	};

	api.forceUpdate = function() {
		resetTimer(0);
	};

	api.startMonitor = function() {
		resetTimer(2000);
	};

	return api;
});

'use strict';

angular.module('cottonMeApp').controller('DashboardShippingPackageListCtrl', function($log, NgTableParams, ShippingPackage, dashboardConf) {
	$log.getInstance('app.controllers.DashboardShippingPackageListCtrl').debug('DashboardProductListCtrl', dashboardConf);
	var ctrl = this;
	ctrl.titleAddon = 'EVENT_STATUS_' + dashboardConf.step.toUpperCase();
	ctrl.dashboardConf = dashboardConf;

	ctrl.tableParams = new NgTableParams({}, {
		total: 0,
		getData: function(params) {
			var sort = params.sorting();
			var sortValues = [];
			if (!angular.equals({}, sort)) {
				for (var key in sort) {
					if (sort.hasOwnProperty(key)) {
						sortValues.push(key + ',' + sort[key]);
					}
				}
			}
			var requestParams = angular.extend({}, {
				page: params.page() - 1,
				size: params.count(),
				sort: sortValues,
				search: params.filter().$
			}, {
				status: dashboardConf.step.toUpperCase()
			});
			return ShippingPackage.query(requestParams).$promise.then(function(entities) {
				params.total(entities.$metadata.totalItems);
				return entities;
			});
		}
	});
});

'use strict';

angular.module('cottonMeApp').controller('DashboardProductListCtrl', function($log, $state, NgTableParams, Product, dashboardConf) {
	$log.getInstance('app.controllers.DashboardProductListCtrl').debug('DashboardProductListCtrl', dashboardConf);
	var ctrl = this;
	ctrl.titleAddon = dashboardConf.titleKey;
	if (!ctrl.titleAddon) {
		ctrl.titleAddon = 'EVENT_STATUS_' + dashboardConf.step.toUpperCase();
	}
	ctrl.dashboardConf = dashboardConf;

	ctrl.tableParams = new NgTableParams({}, {
		total: 0,
		getData: function(params) {
			var sort = params.sorting();
			var sortValues = [];
			if (!angular.equals({}, sort)) {
				for (var key in sort) {
					if (sort.hasOwnProperty(key)) {
						sortValues.push(key + ',' + sort[key]);
					}
				}
			}
			var requestParams = angular.extend({}, {
				page: params.page() - 1,
				size: params.count(),
				sort: sortValues,
				search: params.filter().$
			}, {
				timelineEventStatus: dashboardConf.step.toUpperCase()
			});
			return Product.query(requestParams).$promise.then(function(entities) {
				params.total(entities.$metadata.totalItems);
				return entities;
			});
		}
	});
});

'use strict';

angular.module('cottonMeApp').controller('DashboardBillableProductListCtrl', function($log, NgTableParams, Product, dashboardConf) {
	$log.getInstance('app.controllers').debug('DashboardBillableProductListCtrl', dashboardConf);
	var ctrl = this;
	ctrl.titleAddon = dashboardConf.titleKey;
	ctrl.dashboardConf = dashboardConf;

	ctrl.tableParams = new NgTableParams({}, {
		total: 0,
		getData: function(params) {
			var sort = params.sorting();
			var sortValues = [];
			if (!angular.equals({}, sort)) {
				for (var key in sort) {
					if (sort.hasOwnProperty(key)) {
						sortValues.push(key + ',' + sort[key]);
					}
				}
			}
			var customerB2BId;
			if (ctrl.searchCustomerB2B) {
				customerB2BId = ctrl.searchCustomerB2B.id;
			}
			var requestParams = angular.extend({}, {
				page: params.page() - 1,
				size: params.count(),
				sort: sortValues,
				search: params.filter().$
			}, {
				timelineEventStatus: 'READY_FOR_SHIPPING',
				billed: false,
				customerB2BId: customerB2BId
			});
			return Product.query(requestParams).$promise.then(function(entities) {
				params.total(entities.$metadata.totalItems);
				return entities;
			});
		}
	});
});

'use strict';

angular.module('cottonMeApp').config(function($translateProvider) {
	$translateProvider.translations('fr', {
		'MENU_DASHBOARD_FAMILY_PREMAKING': 'Pré-confection',
		'MENU_DASHBOARD_FAMILY_MAKING': 'Confection',
		'MENU_DASHBOARD_FAMILY_POSTMAKING': 'Post-confection',
		'MENU_DASHBOARD_FAMILY_SHIPPING': 'Envoi',
		'MENU_DASHBOARD_BILLING': 'Facturation',
		'DASHBOARD_PRODUCT_LIST_PRODUCT_TYPE': 'Type',
		'DASHBOARD_PRODUCT_LIST_ORDER': 'Commande',
		'DASHBOARD_PRODUCT_LIST_ORDER_CUSTOMERB2B': 'Client B2B',
		'DASHBOARD_PRODUCT_LIST_REFERENCE': 'Référence',
		'DASHBOARD_PRODUCT_TITLE_LIST': 'Liste des produits',
		'DASHBOARD_PRODUCT_LIST_ORDER_FINALCUSTOMER': 'Client final',
		'DASHBOARD_PRODUCT_LIST_ORDER_FABRICREFERENCE': 'Référence tissus',
		'DASHBOARD_PRODUCT_LIST_ORDER_COMMENTS': 'Commentaires',
		'DASHBOARD_PRODUCT_LIST_ORDER_INITIALSINFO': 'Initiales',
		'DASHBOARD_PRODUCT_SELECT_CUSTOMERB2B': 'Client B2B ?',
		'DASHBOARD_SHIPPINGPACKAGE_TITLE_LIST': 'Liste des colis',
		'DASHBOARD_SHIPPINGPACKAGE_LIST_TRACKINGNUMBER': 'Numéro de suivi',
		'DASHBOARD_SHIPPINGPACKAGE_LIST_REFERENCE': 'Référence',
		'DASHBOARD_SHIPPINGPACKAGE_LIST_STATUS': 'Status',
		'DASHBOARD_SHIPPINGPACKAGE_LIST_ADDRESS': 'Addresse',
		'DASHBOARD_SHIPPINGPACKAGE_LIST_WEIGHT': 'Poids',
		'PRODUCT_ADD_TO_WATCHLIST_MESSAGE': 'Add this product to the Watch list',
		'PRODUCT_TITLE_WATCH': 'Add to the Watch list',
		'PRODUCT_WATCH_LIST_SUCCESS_MESSAGE': 'Product set to the Watch list!',
		'PRODUCT_WATCH_UNSET_STATUS_ERROR': 'You must set a Watch status!',
		'PRODUCT_MASS_MOVE_TITLE': 'Change status of selected products',
		'PRODUCT_SHIRT_MASS_MOVE': 'Mass move',
		'PRODUCTS_SELECTED': 'Products selected',
		'CRUD_CANCEL': 'Cancel',
		'CRUD_CONFIRM_SAVE': 'Save'
	});

	$translateProvider.translations('en', {
		'MENU_DASHBOARD_FAMILY_PREMAKING': 'Pre-making',
		'MENU_DASHBOARD_FAMILY_MAKING': 'Making',
		'MENU_DASHBOARD_FAMILY_POSTMAKING': 'Post-making',
		'MENU_DASHBOARD_FAMILY_SHIPPING': 'Shipping',
		'MENU_DASHBOARD_BILLING': 'Billing',
		'DASHBOARD_PRODUCT_LIST_PRODUCT_TYPE': 'Type',
		'DASHBOARD_PRODUCT_LIST_ORDER': 'Order',
		'DASHBOARD_PRODUCT_LIST_ORDER_CUSTOMERB2B': 'Customer B2B',
		'DASHBOARD_PRODUCT_LIST_REFERENCE': 'Reference',
		'DASHBOARD_PRODUCT_LIST_ORDER_FINALCUSTOMER': 'Final customer',
		'DASHBOARD_PRODUCT_LIST_ORDER_FABRICREFERENCE': 'Fabric reference',
		'DASHBOARD_PRODUCT_LIST_ORDER_COMMENTS': 'Comments',
		'DASHBOARD_PRODUCT_LIST_ORDER_INITIALSINFO': 'Initials',
		'DASHBOARD_PRODUCT_SELECT_CUSTOMERB2B': 'Customer B2B ?',
		'DASHBOARD_PRODUCT_TITLE_LIST': 'Product list',
		'DASHBOARD_SHIPPINGPACKAGE_LIST_TRACKINGNUMBER': 'Tracking number',
		'DASHBOARD_SHIPPINGPACKAGE_TITLE_LIST': 'Shipping package list',
		'DASHBOARD_SHIPPINGPACKAGE_LIST_REFERENCE': 'Reference',
		'DASHBOARD_SHIPPINGPACKAGE_LIST_STATUS': 'Status',
		'DASHBOARD_SHIPPINGPACKAGE_LIST_ADDRESS': 'Address',
		'DASHBOARD_SHIPPINGPACKAGE_LIST_WEIGHT': 'Weight',
		'PRODUCT_ADD_TO_WATCHLIST_MESSAGE': 'Add this product to the Watch list',
		'PRODUCT_TITLE_WATCH': 'Add to the Watch list',
		'PRODUCT_WATCH_LIST_SUCCESS_MESSAGE': 'Product set to the Watch list!',
		'PRODUCT_WATCH_UNSET_STATUS_ERROR': 'You must set a Watch status!',
		'PRODUCT_MASS_MOVE_TITLE': 'Change status of selected products',
		'PRODUCT_SHIRT_MASS_MOVE': 'Mass move',
		'PRODUCTS_SELECTED': 'Products selected',
		'CRUD_CANCEL': 'Cancel',
		'CRUD_CONFIRM_SAVE': 'Save'
	});
	$translateProvider.translations('zh', {
		'MENU_DASHBOARD_FAMILY_PREMAKING': 'Pre-making',
		'MENU_DASHBOARD_FAMILY_MAKING': 'Making',
		'MENU_DASHBOARD_FAMILY_POSTMAKING': 'Post-making',
		'MENU_DASHBOARD_FAMILY_SHIPPING': 'Shipping',
		'MENU_DASHBOARD_BILLING': 'Billing',
		'DASHBOARD_PRODUCT_LIST_PRODUCT_TYPE': 'Type',
		'DASHBOARD_PRODUCT_LIST_ORDER': 'Order',
		'DASHBOARD_PRODUCT_LIST_ORDER_CUSTOMERB2B': 'Customer B2B',
		'DASHBOARD_PRODUCT_LIST_REFERENCE': 'Reference',
		'DASHBOARD_PRODUCT_LIST_ORDER_FINALCUSTOMER': 'Final customer',
		'DASHBOARD_PRODUCT_LIST_ORDER_FABRICREFERENCE': 'Fabric reference',
		'DASHBOARD_PRODUCT_LIST_ORDER_COMMENTS': 'Comments',
		'DASHBOARD_PRODUCT_LIST_ORDER_INITIALSINFO': 'Initials',
		'DASHBOARD_PRODUCT_SELECT_CUSTOMERB2B': 'Customer B2B ?',
		'DASHBOARD_PRODUCT_TITLE_LIST': 'Product list',
		'DASHBOARD_SHIPPINGPACKAGE_LIST_TRACKINGNUMBER': 'Tracking number',
		'DASHBOARD_SHIPPINGPACKAGE_TITLE_LIST': 'Shipping package list',
		'DASHBOARD_SHIPPINGPACKAGE_LIST_REFERENCE': 'Reference',
		'DASHBOARD_SHIPPINGPACKAGE_LIST_STATUS': 'Status',
		'DASHBOARD_SHIPPINGPACKAGE_LIST_ADDRESS': 'Address',
		'DASHBOARD_SHIPPINGPACKAGE_LIST_WEIGHT': 'Weight',
		'PRODUCT_ADD_TO_WATCHLIST_MESSAGE': 'Add this product to the Watch list',
		'PRODUCT_TITLE_WATCH': 'Add to the Watch list',
		'PRODUCT_WATCH_LIST_SUCCESS_MESSAGE': 'Product set to the Watch list!',
		'PRODUCT_WATCH_UNSET_STATUS_ERROR': 'You must set a Watch status!',
		'PRODUCT_MASS_MOVE_TITLE': 'Change status of selected products',
		'PRODUCT_SHIRT_MASS_MOVE': 'Mass move',
		'PRODUCTS_SELECTED': 'Products selected',
		'CRUD_CANCEL': 'Cancel',
		'CRUD_CONFIRM_SAVE': 'Save'
	});
});

angular.module('cottonMeApp').constant('dashboardConfig', [{
	step: 'Cancel',
	type: 'NA'
}, {
	step: 'Start',
	type: 'premaking',
	displayFinalCustomer: true,
	displayFabricReference: true,
	displayComments: true,
	displaySyncErrors: true,
	actions: {
		printSheetPdf: true
	}
}, {
	step: 'Print',
	type: 'premaking',
	displayFinalCustomer: true,
	displayFabricReference: true,
	displayComments: true
}, {
	step: 'Fabric_Cut',
	type: 'premaking',
	displayFinalCustomer: true,
	displayFabricReference: true,
	displayComments: true
}, {
	step: 'Fabric_Cut_Wait',
	type: 'premaking',
	displayFinalCustomer: true,
	displayFabricReference: true,
	displayComments: true
}, {
	step: 'Fabric_Waiting',
	type: 'premaking',
	displayFinalCustomer: true,
	displayFabricReference: true,
	displayComments: true
}, {
	step: 'Fabric_No_More',
	type: 'premaking',
	displayFinalCustomer: true,
	displayFabricReference: true,
	displayComments: true
}, {
	step: 'Office_Out',
	type: 'making',
	displayFinalCustomer: true
}, {
	step: 'Workshop_In',
	type: 'making',
	displayFinalCustomer: true
}, {
	step: 'Pattern_Cut',
	type: 'making',
	displayFinalCustomer: true
}, {
	step: 'Pattern_Modif_Ok',
	type: 'making',
	displayFinalCustomer: true
}, {
	step: 'Pattern_Ok',
	type: 'making',
	displayFinalCustomer: true
}, {
	step: 'Pattern_Prob',
	type: 'making',
	displayFinalCustomer: true
}, {
	step: 'Workshop_Out',
	type: 'making',
	displayFinalCustomer: true
}, {
	step: 'Initials',
	type: 'making',
	displayInitialsinfo: true
}, {
	step: 'Quality_Ok',
	type: 'postmaking',
	displayFinalCustomer: true,
	displayFabricReference: true,
	actions: {
		printTagPdf: true
	}
}, {
	step: 'Tagged',
	type: 'postmaking',
	displayFinalCustomer: true
}, {
	step: 'Packaged',
	type: 'NA'
}, {
	step: 'Ready_For_Shipping',
	type: 'NA'
}, {
	step: 'Delivered',
	type: 'NA'
}, {
	step: 'Package_Prepared',
	type: 'shipping',
	isPackage: true,
	iconClass: 'fa fa-dropbox',
	actions: {
		retrievePackageInfo: true
	}
}, {
	step: 'Package_Print',
	type: 'shipping',
	isPackage: true,
	iconClass: 'fa fa-archive',
	actions: {
		retrievePackageInfo: true,
		setAsCancelled: true
	}
}, {
	step: 'Package_Ready_For_Shipping',
	type: 'shipping',
	displayTrackingNumber: true,
	isPackage: true,
	iconClass: 'fa fa-archive',
	actions: {
		retrievePackageInfo: true,
		setAsDelivered: true,
		setAsCancelled: true
	}
}, {
	step: 'Package_Shipped',
	type: 'shipping',
	displayTrackingNumber: true,
	isPackage: true,
	iconClass: 'fa fa-archive',
	actions: {
		retrievePackageInfo: true,
		setAsDelivered: true,
		setAsCancelled: true
	}
}, {
	step: 'Package_Delivered',
	type: 'shipping',
	isPackage: true,
	iconClass: 'fa fa-archive'
}, {
	step: 'Delivered_To_Shop',
	type: 'shipping',
	displayFinalCustomer: true,
	iconClass: 'fa fa-archive'
}
]);

angular.module('cottonMeApp').config(function($stateProvider, dashboardConfig) {
	angular.forEach(dashboardConfig, function(conf) {
		var step = conf.step;
		var templateFileName = 'dashboard-product-list.html';
		var controllerName = 'DashboardProductListCtrl';
		if (conf.isPackage) {
			templateFileName = 'dashboard-shipping-package-list.html';
			controllerName = 'DashboardShippingPackageListCtrl';
		}
		$stateProvider.state('app.dashboard-' + step.toLowerCase(), {
			url: '/dashboard/' + step.toLowerCase(),
			templateUrl: 'modules/dashboard/' + templateFileName,
			controller: controllerName,
			controllerAs: 'ctrl',
			resolve: {
				dashboardConf: function() {
					return conf;
				}
			},
			data: {
				pageTitle: 'EVENT_STATUS_' + step.toUpperCase()
			}
		});

		$stateProvider.state('app.dashboard-' + step.toLowerCase() + '.watch', {
			url: '/watch/:entityId',
			onEnter: function($state, $uibModal, $stateParams) {
				var resolveEntity;
				if ($stateParams) {
					resolveEntity = /* @ngInject */ function(Product) {
						return Product.get({
							id: $stateParams.entityId
						}).$promise;
					};
				}
				$uibModal.open({
					templateUrl: 'modules/dashboard/modal-watch-status.html',
					resolve: {
						entity: resolveEntity,
						EntityGenericResource: function(Product) {
							return Product;
						},
						title: function() {
							return 'PRODUCT_TITLE_WATCH';
						}
					},
					controller: 'WatchStatusCtrl as ctrl'
				}).result.then(function() {
					$state.go('^', {}, {
						reload: true
					});
				}).catch(function() {
					$state.go('^');
				});
			},
			data: {
				pageTitle: 'PRODUCT_TITLE_WATCH',
				authorities: ['ROLE_MANAGER', 'ROLE_ADMIN']
			}
		});
	});
});

angular.module('cottonMeApp').config(function(menuServiceProvider, dashboardConfig) {
	var addAcceleratorFamily = function(acceleratorFamilyName, acceleratorType) {
		var dashboards = dashboardConfig.reduce(function(previousValue, currentValue) {
			if (currentValue.type === acceleratorType) {
				previousValue.push(currentValue);
			}
			return previousValue;
		}, []);
		menuServiceProvider.addItem({
			labelKey: acceleratorFamilyName,
			divider: true,
			rank: 100
		});
		angular.forEach(dashboards, function(dashboard) {
			var iconClass = 'glyphicon glyphicon-equalizer';
			if (dashboard.iconClass) {
				iconClass = dashboard.iconClass;
			}
			menuServiceProvider.addItem({
				labelKey: 'EVENT_STATUS_' + dashboard.step.toUpperCase(),
				url: '#/app/dashboard/' + dashboard.step.toLowerCase(),
				iconClass: iconClass,
				rank: 100,
				badge: {
					event: dashboard.step.toUpperCase() + '-event',
					color: acceleratorType
				}
			});
		});
	};
	addAcceleratorFamily('MENU_DASHBOARD_FAMILY_PREMAKING', 'premaking');
	addAcceleratorFamily('MENU_DASHBOARD_FAMILY_MAKING', 'making');
	addAcceleratorFamily('MENU_DASHBOARD_FAMILY_POSTMAKING', 'postmaking');
	addAcceleratorFamily('MENU_DASHBOARD_FAMILY_SHIPPING', 'shipping');
});

angular.module('cottonMeApp').config(function($stateProvider) {
	$stateProvider.state('app.dashboard-billing', {
		url: '/dashboard/billing',
		templateUrl: 'modules/dashboard/dashboard-product-list.html',
		controller: 'DashboardBillableProductListCtrl',
		controllerAs: 'ctrl',
		resolve: {
			dashboardConf: function() {
				return {
					titleKey: 'MENU_DASHBOARD_BILLING',
					actions: {
						billing: true
					}
				};
			}
		},
		data: {
			pageTitle: 'MENU_DASHBOARD_BILLING'
		}
	});
});

angular.module('cottonMeApp').config(function(menuServiceProvider) {
	menuServiceProvider.addItem({
		labelKey: 'MENU_DASHBOARD_BILLING',
		url: '#/app/dashboard/billing',
		iconClass: 'fa fa-money',
		rank: 110
	});

});

angular.module('cottonMeApp').run(function(dashboardService) {
	dashboardService.startMonitor();
});

'use strict';

angular.module('cottonMeApp').config(function($stateProvider) {
	$stateProvider.state('app.customerB2B', {
		abstract: true,
		url: '/customersB2B',
		template: '<ui-view></ui-view>'
	});

	$stateProvider.state('app.customerB2B.list', {
		url: '',
		templateUrl: 'modules/customer-b-2-b/customer-b-2-b-list.html',
		controller: 'CrudListCtrl',
		controllerAs: 'ctrl',
		resolve: {
			EntityGenericResource: function(CustomerB2B) {
				return CustomerB2B;
			}
		},
		data: {
			pageTitle: 'CUSTOMERB2B_TITLE_LIST',
			authorities: ['ROLE_MANAGER', 'ROLE_ADMIN']
		}
	});

	$stateProvider.state('app.customerB2B.view', {
		url: '/view/:entityId',
		templateUrl: 'modules/customer-b-2-b/customer-b-2-b-view.html',
		controller: 'CrudViewCtrl',
		controllerAs: 'ctrl',
		resolve: {
			entity: function($stateParams, CustomerB2B) {
				return CustomerB2B.get({
					id: $stateParams.entityId
				}).$promise;
			}
		},
		data: {
			pageTitle: 'CUSTOMERB2B_TITLE_VIEW',
			authorities: ['ROLE_MANAGER', 'ROLE_ADMIN']
		}
	});

	$stateProvider.state('app.customerB2B.edit', {
		url: '/edit/:entityId',
		templateUrl: 'modules/customer-b-2-b/customer-b-2-b-edit.html',
		controller: 'CrudEditCtrl',
		controllerAs: 'ctrl',
		resolve: {
			entity: function($stateParams, CustomerB2B) {
				return CustomerB2B.get({
					id: $stateParams.entityId
				}).$promise;
			},
			EntityGenericResource: function(CustomerB2B) {
				return CustomerB2B;
			}
		},
		data: {
			pageTitle: 'CUSTOMERB2B_TITLE_EDIT',
			authorities: ['ROLE_MANAGER', 'ROLE_ADMIN']
		}
	});

	$stateProvider.state('app.customerB2B.create', {
		url: '/new',
		templateUrl: 'modules/customer-b-2-b/customer-b-2-b-edit.html',
		controller: 'CrudEditCtrl',
		controllerAs: 'ctrl',
		resolve: {
			entity: function() {
				return undefined;
			},
			EntityGenericResource: function(CustomerB2B) {
				return CustomerB2B;
			}
		},
		data: {
			pageTitle: 'CUSTOMERB2B_TITLE_NEW',
			authorities: ['ROLE_MANAGER', 'ROLE_ADMIN']
		}
	});

	var openModal = function($state, $uibModal, $stateParams, controllerName, templateName, title) {
		var resolveEntity;
		if ($stateParams) {
			resolveEntity = /* @ngInject */ function(CustomerB2B) {
				return CustomerB2B.get({
					id: $stateParams.entityId
				}).$promise;
			};
		}
		$uibModal.open({
			templateUrl: 'adama-web/crud/' + templateName,
			resolve: {
				entity: resolveEntity,
				EntityGenericResource: function(CustomerB2B) {
					return CustomerB2B;
				},
				title: function() {
					return title;
				}
			},
			controller: controllerName + ' as ctrl'
		}).result.then(function() {
			$state.go('^', {}, {
				reload: true
			});
		}).catch(function() {
			$state.go('^');
		});
	};

	$stateProvider.state('app.customerB2B.delete', {
		url: '/delete/:entityId',
		parent: 'app.customerB2B.list',
		onEnter: function($state, $uibModal, $stateParams) {
			openModal($state, $uibModal, $stateParams, 'CrudDeleteCtrl', 'crud-delete.html', 'CUSTOMERB2B_TITLE_DELETE');
		},
		data: {
			pageTitle: 'CUSTOMERB2B_TITLE_DELETE',
			authorities: ['ROLE_MANAGER', 'ROLE_ADMIN']
		}
	});

	$stateProvider.state('app.customerB2B.importXls', {
		url: '/import-xls',
		parent: 'app.customerB2B.list',
		onEnter: function($state, $uibModal) {
			openModal($state, $uibModal, undefined, 'CrudImportXlsCtrl', 'crud-import-xls.html', 'CUSTOMERB2B_TITLE_IMPORT_XLS');
		},
		data: {
			pageTitle: 'CUSTOMERB2B_TITLE_IMPORT_XLS',
			authorities: ['ROLE_MANAGER', 'ROLE_ADMIN']
		}
	});

	$stateProvider.state('app.customerB2B.exportXls', {
		url: '/export-xls',
		parent: 'app.customerB2B.list',
		onEnter: function($state, $uibModal) {
			openModal($state, $uibModal, undefined, 'CrudExportXlsCtrl', 'crud-export-xls.html', 'CUSTOMERB2B_TITLE_EXPORT_XLS');
		},
		data: {
			pageTitle: 'CUSTOMERB2B_TITLE_EXPORT_XLS',
			authorities: ['ROLE_MANAGER', 'ROLE_ADMIN']
		}
	});
});

angular.module('cottonMeApp').config(function($translateProvider) {
	$translateProvider.translations('fr', {
		'CUSTOMERB2B_MENU': 'Client B2B',
		'CUSTOMERB2B_LIST_COMPANY': 'Entreprise',
		'CUSTOMERB2B_LIST_MAINCONTACTUSER': 'Contact principal',
		'CUSTOMERB2B_LIST_COMMENT': 'Commentaires',
		'CUSTOMERB2B_FORM_COMPANY': 'Entreprise',
		'CUSTOMERB2B_FORM_MAINCONTACTUSER': 'Contact principal',
		'CUSTOMERB2B_FORM_DELIVERYADDRESS': 'Adresse de livraison',
		'CUSTOMERB2B_FORM_DELIVERYADDRESS_ADD': 'Ajouter une adresse de livraison',
		'CUSTOMERB2B_FORM_EXTERNALID': 'Id externe',
		'CUSTOMERB2B_FORM_INVOICEADDRESS': 'Adresse de facturation',
		'CUSTOMERB2B_FORM_INVOICEADDRESS_ADD': 'Ajouter une adresse de facturation',
		'CUSTOMERB2B_FORM_CURRENCY': 'Monnaie',
		'CUSTOMERB2B_FORM_ACCOUNT_CODE': 'Account code',
		'CUSTOMERB2B_FORM_PRODUCT_UNIT_DEFAULT': 'Service produit par défaut',
		'CUSTOMERB2B_FORM_FABRIC_RANGE_A': 'Prix tissus A',
		'CUSTOMERB2B_FORM_FABRIC_RANGE_B': 'Prix tissus B',
		'CUSTOMERB2B_FORM_FABRIC_RANGE_C': 'Prix tissus C',
		'CUSTOMERB2B_FORM_FABRIC_RANGE_D': 'Prix tissus D',
		'CUSTOMERB2B_FORM_FABRIC_RANGE_E': 'Prix tissus E',
		'CUSTOMERB2B_FORM_FABRIC_RANGE_AA': 'Prix tissus AA',
		'CUSTOMERB2B_FORM_FABRIC_RANGE_AAA': 'Prix tissus AAA',
		'CUSTOMERB2B_FORM_PRODUCT_UNIT_FABRIC_CUT': 'Prix découpe tissu',
		'CUSTOMERB2B_FORM_PRODUCT_UNIT_SHIRT_MAKING_STANDARD': 'Prix confection : chemise standard',
		'CUSTOMERB2B_FORM_PRODUCT_UNIT_SHIRT_MAKING_PACK': 'Prix confection : chemise en lot',
		'CUSTOMERB2B_FORM_PRODUCT_UNIT_SHIRT_MAKING_B2B': 'Prix confection : chemise B2B',
		'CUSTOMERB2B_FORM_PRODUCT_UNIT_SHIRT_REMAKE': 'Prix refonte',
		'CUSTOMERB2B_FORM_PRODUCT_UNIT_SHIRT_FIX': 'Prix réparation',
		'CUSTOMERB2B_FORM_PRODUCT_UNIT_COLLAR_MAKING': 'Prix confection : cou',
		'CUSTOMERB2B_FORM_PRODUCT_UNIT_CUFF_MAKING': 'Prix confection : manche',
		'CUSTOMERB2B_FORM_PRODUCT_UNIT_FREE_REMAKE': 'Prix refonte gratuite',
		'CUSTOMERB2B_FORM_PRODUCT_UNIT_INSPECTION': 'Prix inspection et contrôle',
		'CUSTOMERB2B_FORM_PRODUCT_UNIT_SAMPLE': 'Prix échantillon',
		'CUSTOMERB2B_FORM_SHIPPINGPRICEB2B': 'Prix envoi B2B',
		'CUSTOMERB2B_FORM_WRAPPINGPRICEB2B': 'Cout d\'emballage B2B',
		'CUSTOMERB2B_FORM_SHIPPINGPRICEB2C': 'Prix envoi B2C',
		'CUSTOMERB2B_FORM_WRAPPINGPRICEB2C': 'Cout d\'emballage B2C',
		'CUSTOMERB2B_FORM_COMMENT': 'Commentaires',
		'CUSTOMERB2B_FORM_BLOCK_PRICE': 'Prix',
		'CUSTOMERB2B_TITLE_DELETE': 'Suppression d\'un client B2B',
		'CUSTOMERB2B_TITLE_VIEW': 'Détails d\'un client B2B',
		'CUSTOMERB2B_TITLE_LIST': 'Liste des clients B2B',
		'CUSTOMERB2B_TITLE_EDIT': 'Editer un client B2B',
		'CUSTOMERB2B_TITLE_NEW': 'Créer un client B2B',
		'CUSTOMERB2B_TITLE_IMPORT_XLS': 'Importer en masse des clients B2B',
		'CUSTOMERB2B_TITLE_EXPORT_XLS': 'Exporter en masse des clients B2B'
	});

	$translateProvider.translations('en', {
		'CUSTOMERB2B_MENU': 'Customer B2B',
		'CUSTOMERB2B_LIST_COMPANY': 'Company',
		'CUSTOMERB2B_LIST_MAINCONTACTUSER': 'Main contact',
		'CUSTOMERB2B_LIST_COMMENT': 'Comments',
		'CUSTOMERB2B_FORM_COMPANY': 'Company',
		'CUSTOMERB2B_FORM_MAINCONTACTUSER': 'Main contact',
		'CUSTOMERB2B_FORM_DELIVERYADDRESS': 'Delivery address',
		'CUSTOMERB2B_FORM_DELIVERYADDRESS_ADD': 'Add a delivery address',
		'CUSTOMERB2B_FORM_EXTERNALID': 'External id',
		'CUSTOMERB2B_FORM_INVOICEADDRESS': 'Invoice address',
		'CUSTOMERB2B_FORM_INVOICEADDRESS_ADD': 'Add an invoice address',
		'CUSTOMERB2B_FORM_CURRENCY': 'Currency',
		'CUSTOMERB2B_FORM_ACCOUNT_CODE': 'Account Code',
		'CUSTOMERB2B_FORM_PRODUCT_UNIT_DEFAULT': 'Default product unit',
		'CUSTOMERB2B_FORM_FABRIC_RANGE_A': 'Fabric price A',
		'CUSTOMERB2B_FORM_FABRIC_RANGE_B': 'Fabric price B',
		'CUSTOMERB2B_FORM_FABRIC_RANGE_C': 'Fabric price C',
		'CUSTOMERB2B_FORM_FABRIC_RANGE_D': 'Fabric price D',
		'CUSTOMERB2B_FORM_FABRIC_RANGE_E': 'Fabric price E',
		'CUSTOMERB2B_FORM_FABRIC_RANGE_AA': 'Fabric price AA',
		'CUSTOMERB2B_FORM_FABRIC_RANGE_AAA': 'Fabric price AAA',
		'CUSTOMERB2B_FORM_PRODUCT_UNIT_FABRIC_CUT': 'Fabric cut price',
		'CUSTOMERB2B_FORM_PRODUCT_UNIT_SHIRT_MAKING_STANDARD': 'Shirt making : Standard shirt price',
		'CUSTOMERB2B_FORM_PRODUCT_UNIT_SHIRT_MAKING_PACK': 'Shirt making : Pack Shirt price',
		'CUSTOMERB2B_FORM_PRODUCT_UNIT_SHIRT_MAKING_B2B': 'Shirt making : B2B shirt price',
		'CUSTOMERB2B_FORM_PRODUCT_UNIT_SHIRT_REMAKE': 'Shirt remake price',
		'CUSTOMERB2B_FORM_PRODUCT_UNIT_SHIRT_FIX': 'Shirt fixing price',
		'CUSTOMERB2B_FORM_PRODUCT_UNIT_COLLAR_MAKING': 'Collar making price',
		'CUSTOMERB2B_FORM_PRODUCT_UNIT_CUFF_MAKING': 'Cuff making price',
		'CUSTOMERB2B_FORM_PRODUCT_UNIT_FREE_REMAKE': 'Free remake price',
		'CUSTOMERB2B_FORM_PRODUCT_UNIT_INSPECTION': 'QC & Inspection price',
		'CUSTOMERB2B_FORM_PRODUCT_UNIT_SAMPLE': 'Sample price',
		'CUSTOMERB2B_FORM_SHIPPINGPRICEB2B': 'Shipping price B2B',
		'CUSTOMERB2B_FORM_WRAPPINGPRICEB2B': 'Wrapping price B2B',
		'CUSTOMERB2B_FORM_SHIPPINGPRICEB2C': 'Shipping price B2C',
		'CUSTOMERB2B_FORM_WRAPPINGPRICEB2C': 'Wrapping price B2C',
		'CUSTOMERB2B_FORM_COMMENT': 'Comment',
		'CUSTOMERB2B_FORM_BLOCK_PRICE': 'Prices',
		'CUSTOMERB2B_TITLE_DELETE': 'Customer B2B delete',
		'CUSTOMERB2B_TITLE_VIEW': 'Customer B2B details',
		'CUSTOMERB2B_TITLE_LIST': 'Customer B2B list',
		'CUSTOMERB2B_TITLE_EDIT': 'Customer B2B edition',
		'CUSTOMERB2B_TITLE_NEW': 'Customer B2B creation',
		'CUSTOMERB2B_TITLE_IMPORT_XLS': 'Customer B2B mass import',
		'CUSTOMERB2B_TITLE_EXPORT_XLS': 'Customer B2B mass export'
	});

	$translateProvider.translations('zh', {
		'CUSTOMERB2B_MENU': 'Customer B2B',
		'CUSTOMERB2B_LIST_COMPANY': 'Company',
		'CUSTOMERB2B_LIST_MAINCONTACTUSER': 'Main contact',
		'CUSTOMERB2B_LIST_COMMENT': 'Comments',
		'CUSTOMERB2B_FORM_COMPANY': 'Company',
		'CUSTOMERB2B_FORM_MAINCONTACTUSER': 'Main contact',
		'CUSTOMERB2B_FORM_DELIVERYADDRESS': 'Delivery address',
		'CUSTOMERB2B_FORM_DELIVERYADDRESS_ADD': 'Add a delivery address',
		'CUSTOMERB2B_FORM_EXTERNALID': 'External id',
		'CUSTOMERB2B_FORM_INVOICEADDRESS': 'Invoice address',
		'CUSTOMERB2B_FORM_INVOICEADDRESS_ADD': 'Add an invoice address',
		'CUSTOMERB2B_FORM_CURRENCY': 'Currency',
		'CUSTOMERB2B_FORM_ACCOUNT_CODE': 'Account code',
		'CUSTOMERB2B_FORM_PRODUCT_UNIT_DEFAULT': 'Default product unit',
		'CUSTOMERB2B_FORM_FABRIC_RANGE_A': 'Fabric price A',
		'CUSTOMERB2B_FORM_FABRIC_RANGE_B': 'Fabric price B',
		'CUSTOMERB2B_FORM_FABRIC_RANGE_C': 'Fabric price C',
		'CUSTOMERB2B_FORM_FABRIC_RANGE_D': 'Fabric price D',
		'CUSTOMERB2B_FORM_FABRIC_RANGE_E': 'Fabric price E',
		'CUSTOMERB2B_FORM_FABRIC_RANGE_AA': 'Fabric price AA',
		'CUSTOMERB2B_FORM_FABRIC_RANGE_AAA': 'Fabric price AAA',
		'CUSTOMERB2B_FORM_PRODUCT_UNIT_FABRIC_CUT': 'Fabric cut price',
		'CUSTOMERB2B_FORM_PRODUCT_UNIT_SHIRT_MAKING_STANDARD': 'Shirt making : Standard shirt price',
		'CUSTOMERB2B_FORM_PRODUCT_UNIT_SHIRT_MAKING_PACK': 'Shirt making : Pack Shirt price',
		'CUSTOMERB2B_FORM_PRODUCT_UNIT_SHIRT_MAKING_B2B': 'Shirt making : B2B shirt price',
		'CUSTOMERB2B_FORM_PRODUCT_UNIT_SHIRT_REMAKE': 'Shirt remake price',
		'CUSTOMERB2B_FORM_PRODUCT_UNIT_SHIRT_FIX': 'Shirt fixing price',
		'CUSTOMERB2B_FORM_PRODUCT_UNIT_COLLAR_MAKING': 'Collar making price',
		'CUSTOMERB2B_FORM_PRODUCT_UNIT_CUFF_MAKING': 'Cuff making price',
		'CUSTOMERB2B_FORM_PRODUCT_UNIT_FREE_REMAKE': 'Free remake price',
		'CUSTOMERB2B_FORM_PRODUCT_UNIT_INSPECTION': 'QC & Inspection price',
		'CUSTOMERB2B_FORM_PRODUCT_UNIT_SAMPLE': 'Sample price',
		'CUSTOMERB2B_FORM_SHIPPINGPRICEB2B': 'Shipping price B2B',
		'CUSTOMERB2B_FORM_WRAPPINGPRICEB2B': 'Wrapping price B2B',
		'CUSTOMERB2B_FORM_SHIPPINGPRICEB2C': 'Shipping price B2C',
		'CUSTOMERB2B_FORM_WRAPPINGPRICEB2C': 'Wrapping price B2C',
		'CUSTOMERB2B_FORM_COMMENT': 'Comment',
		'CUSTOMERB2B_FORM_BLOCK_PRICE': 'Prices',
		'CUSTOMERB2B_TITLE_DELETE': 'Customer B2B delete',
		'CUSTOMERB2B_TITLE_VIEW': 'Customer B2B details',
		'CUSTOMERB2B_TITLE_LIST': 'Customer B2B list',
		'CUSTOMERB2B_TITLE_EDIT': 'Customer B2B edition',
		'CUSTOMERB2B_TITLE_NEW': 'Customer B2B creation',
		'CUSTOMERB2B_TITLE_IMPORT_XLS': 'Customer B2B mass import',
		'CUSTOMERB2B_TITLE_EXPORT_XLS': 'Customer B2B mass export'
	});
});

angular.module('cottonMeApp').config(function(menuServiceProvider) {
	menuServiceProvider.addItem({
		labelKey: 'CUSTOMERB2B_MENU',
		url: '#/app/customersB2B',
		iconClass: 'glyphicon glyphicon-equalizer',
		rank: 220
	});
});

'use strict';

angular.module('cottonMeApp').component('booleanDisplay', {
	templateUrl: 'modules/boolean-display/boolean-display.html',
	bindings: {
		value: '<'
	}
});

'use strict';

angular.module('cottonMeApp').config(function ($stateProvider) {
  $stateProvider.state('app.billing', {
    abstract: true,
    url: '/billing',
    template: '<ui-view></ui-view>'
  });

  $stateProvider.state('app.billing.validation', {
    url: '/validation',
    templateUrl: 'modules/billing/billing-validation.html',
    data: {
      pageTitle: 'MENU_GROUP_BILLING_ITEM_VALIDATION_TITLE',
      authorities: ['ROLE_MANAGER', 'ROLE_ADMIN']
    }
  });

  $stateProvider.state('app.billing.pending', {
    url: '/pending',
    templateUrl: 'modules/billing/billing-pending.html',
    data: {
      pageTitle: 'MENU_GROUP_BILLING_ITEM_PENDING_TITLE',
      authorities: ['ROLE_MANAGER', 'ROLE_ADMIN']
    }
  });

  $stateProvider.state('app.billing.invoices', {
    url: '/invoices',
    templateUrl: 'modules/billing/billing-invoices.html',
    data: {
      pageTitle: 'MENU_GROUP_BILLING_ITEM_INVOICED_TITLE',
      authorities: ['ROLE_MANAGER', 'ROLE_ADMIN']
    }
  });

});

angular.module('cottonMeApp').config(function ($translateProvider) {
  $translateProvider.translations('en', {
    'MENU_GROUP_BILLING_TITLE': 'Billing',
    'MENU_GROUP_BILLING_ITEM_VALIDATION_TITLE': 'Needs Validation',
    'MENU_GROUP_BILLING_ITEM_PENDING_TITLE': 'To Be Invoiced',
    'MENU_GROUP_BILLING_ITEM_INVOICED_TITLE': 'Invoiced'
  });
  $translateProvider.translations('zh', {
    'MENU_GROUP_BILLING_TITLE': 'Billing',
    'MENU_GROUP_BILLING_ITEM_VALIDATION_TITLE': 'Needs Validation',
    'MENU_GROUP_BILLING_ITEM_PENDING_TITLE': 'To Be Invoiced',
    'MENU_GROUP_BILLING_ITEM_INVOICED_TITLE': 'Invoiced'
  });
  $translateProvider.translations('fr', {
    'MENU_GROUP_BILLING_TITLE': 'Billing',
    'MENU_GROUP_BILLING_ITEM_VALIDATION_TITLE': 'Needs Validation',
    'MENU_GROUP_BILLING_ITEM_PENDING_TITLE': 'To Be Invoiced',
    'MENU_GROUP_BILLING_ITEM_INVOICED_TITLE': 'Invoiced'
  });
});

angular.module('cottonMeApp').config(function (menuServiceProvider) {
  menuServiceProvider.addItem({
    labelKey: 'MENU_GROUP_BILLING_TITLE',
    divider: true,
    rank: 150
  });
  menuServiceProvider.addItem({
    labelKey: 'MENU_GROUP_BILLING_ITEM_VALIDATION_TITLE',
    url: '#/app/billing/validation',
    iconClass: 'fa fa-check-square-o',
    rank: 151,
    badge: {
      event: 'VALIDATION-event',
      color: 'billing'
    }
  });
  menuServiceProvider.addItem({
    labelKey: 'MENU_GROUP_BILLING_ITEM_PENDING_TITLE',
    url: '#/app/billing/pending',
    iconClass: 'fa fa-money',
    rank: 152,
    badge: {
      event: 'TO_BE_INVOICED-event',
      color: 'billing'
    }
  });
  menuServiceProvider.addItem({
    labelKey: 'MENU_GROUP_BILLING_ITEM_INVOICED_TITLE',
    url: '#/app/billing/invoices',
    iconClass: 'fa fa-list',
    rank: 153,
    badge: {
      event: 'INVOICED-event',
      color: 'billing'
    }
  });
});

'use strict';

angular.module('cottonMeApp').config(function($translateProvider) {
	$translateProvider.translations('fr', {
		'ADDRESS_FIRSTNAME': 'Prénom',
		'ADDRESS_LASTNAME': 'Nom',
		'ADDRESS_COMPANY': 'Entreprise',
		'ADDRESS_LINE1': 'Ligne 1',
		'ADDRESS_LINE2': 'Ligne 2',
		'ADDRESS_POSTALCODE': 'Code postal',
		'ADDRESS_CITY': 'Ville',
		'ADDRESS_PHONE': 'Téléphone',
		'ADDRESS_PHONEMOBILE': 'Mobile',
		'ADDRESS_STATEISO': 'Code ISO état',
		'ADDRESS_STATE': 'Etat',
		'ADDRESS_COUNTRYISO': 'Code ISO pays',
		'ADDRESS_COUNTRY': 'Pays'
	});

	$translateProvider.translations('en', {
		'ADDRESS_FIRSTNAME': 'Firstname',
		'ADDRESS_LASTNAME': 'Lastname',
		'ADDRESS_COMPANY': 'Comapny',
		'ADDRESS_LINE1': 'Line 1',
		'ADDRESS_LINE2': 'Line 2',
		'ADDRESS_POSTALCODE': 'Postal code',
		'ADDRESS_CITY': 'City',
		'ADDRESS_PHONE': 'Phone',
		'ADDRESS_PHONEMOBILE': 'Mobile',
		'ADDRESS_STATEISO': 'State ISO',
		'ADDRESS_STATE': 'State',
		'ADDRESS_COUNTRYISO': 'Counttry ISO',
		'ADDRESS_COUNTRY': 'Country'
	});
});

'use strict';

angular.module('cottonMeApp').component('addressView', {
	templateUrl: 'modules/address/address-view.html',
	require: {
		ngModel: 'ngModel'
	},
	bindings: {
		blockTitle: '@'
	}
});

'use strict';

angular.module('cottonMeApp').component('addressEdit', {
	templateUrl: 'modules/address/address-edit.html',
	bindings: {
		fieldNamePrefix: '@'
	},
	require: {
		ngModel: 'ngModel'
	}
});

'use strict';

angular.module('cottonMeApp').factory('shirtDetailsChoiceService', function(ShirtDetailsChoice) {
	var dictPromise = ShirtDetailsChoice.query({
		all: true
	}).$promise.then(function(choiceList) {
		var dict = {};
		angular.forEach(choiceList, function(choice) {
			dict[choice.category] = dict[choice.category] || {};
			dict[choice.category][choice.code] = choice;
		});
		return dict;
	});

	var api = {};
	api.getDictionary = function() {
		return dictPromise;
	};
	return api;
});

'use strict';

angular.module('adama-web').provider('language', function() {
	var languages = ['en', 'fr', 'zh'];
	var selectorData = [{
		code: 'en',
		labelKey: 'FLAG_EN',
		cssCLass: 'us'
	}, {
		code: 'fr',
		labelKey: 'FLAG_FR',
		cssCLass: 'fr'
	}, {
		code: 'zh',
		labelKey: 'FLAG_CN',
		cssCLass: 'cn'
	}];

	this.setLanguages = function(newLanguages) {
		languages = newLanguages;
	};

	this.setSelectorData = function(newSelectorData) {
		selectorData = newSelectorData;
	};

	this.$get = function($q, $http, $translate) {
		var api = {};

		api.getCurrent = function() {
			var language = $translate.storage().get('NG_TRANSLATE_LANG_KEY');
			if (angular.isUndefined(language)) {
				language = 'en';
			}
			return $q.when(language);
		};

		api.getAll = function() {
			return $q.when(languages);
		};

		api.getSelectorData = function() {
			return $q.when(selectorData);
		};

		return api;
	};
});


'use strict';

angular.module('cottonMeApp').factory('Stats', function($resource, $http, adamaConstant, adamaResourceConfig) {
	var resourceApiRoot = adamaConstant.apiBase + 'stats';

    var config = angular.extend({}, adamaResourceConfig, {
		getKPI: {
			url: resourceApiRoot + '/dashboard',
			method : 'GET'
		}
	});

	return $resource(resourceApiRoot + '/', {}, config);
});

'use strict';

angular.module('cottonMeApp').factory('ShirtDetailsChoice', function($resource, adamaConstant, adamaResourceConfig) {
	var config = angular.extend({}, adamaResourceConfig, {
		// set specific resource config
	});
	return $resource(adamaConstant.apiBase + 'shirtDetailsChoices/:id', {}, config);
});

'use strict';

angular.module('cottonMeApp').factory('ShippingPackage', function($resource, $http, adamaConstant, adamaResourceConfig, binaryService) {
	var resourceApiRoot = adamaConstant.apiBase + 'shippingPackages';
	var prepareObject = function(entity) {
		if (entity.deliveryDate) {
			entity.deliveryDate = new Date(entity.deliveryDate);
		}
		return entity;
	};
	var config = angular.extend({}, adamaResourceConfig, {
		getProductList : {
			url : resourceApiRoot + '/:id/products',
			isArray : true,
			method : 'GET'
		},
		syncCNEInfo: {
			url: resourceApiRoot + '/updateStatusFromCNEForAllReadyForShipping',
			method: 'GET'
		},
		retrieveInfoForAllPrepared : {
			url : resourceApiRoot + '/infoForAllPrepared',
			method : 'GET',
			responseType : 'arraybuffer',
			transformResponse : binaryService.transformResponseToExcel
		},
		retrieveInfoForSelected : {
			url : resourceApiRoot + '/infoForSelected',
			method : 'GET',
			responseType : 'arraybuffer',
			transformResponse : binaryService.transformResponseToExcel
		},
		setAsShippedSelected : {
			url : resourceApiRoot + '/shipped?idList=:idList',
			method : 'POST',
			params : {
				idList : '@idList'
			}
		},
		setAsDeliveredSelected : {
			url : resourceApiRoot + '/delivered?idList=:idList',
			method : 'POST',
			params : {
				idList : '@idList'
			}
		},
		setAsCancelledSelected : {
			url : resourceApiRoot + '/cancelled?idList=:idList',
			method : 'POST',
			params : {
				idList : '@idList'
			}
		},
		massImportXls : {
			url : resourceApiRoot + '/excel',
			method : 'POST',
			params : {},
			headers : {
				'Content-Type' : undefined
			},
			transformRequest : function(data) {
				var formData = new window.FormData();
				var file = data.file;
				formData.append('file', file, file.name);
				return formData;
			}
		}
	});
	config.get.transformResponse = $http.defaults.transformResponse.concat(prepareObject);
	config.save.transformResponse = $http.defaults.transformResponse.concat(prepareObject);
	config.update.transformResponse = $http.defaults.transformResponse.concat(prepareObject);
	return $resource(resourceApiRoot + '/:id', {}, config);
});

'use strict';

angular.module('cottonMeApp').factory('Settings', function($resource, $http, adamaConstant, adamaResourceConfig) {
	var resourceApiRoot = adamaConstant.apiBase + 'settings';
    var config = angular.extend({}, adamaResourceConfig, {
		getSettings: {
			url: resourceApiRoot + '/',
			method : 'GET'
		}
	});
	return $resource(resourceApiRoot + '/', {}, config);
});

'use strict';

angular.module('cottonMeApp').factory('Product', function($resource, $http, adamaConstant, adamaResourceConfig, binaryService) {
	var resourceApiRoot = adamaConstant.apiBase + 'products';
	var prepareObject = function(entity) {
		if (entity.shirtProduct && entity.shirtProduct.measurement && entity.shirtProduct.measurement.modifiedDate) {
			entity.shirtProduct.measurement.modifiedDate = new Date(entity.shirtProduct.measurement.modifiedDate);
		}
		if (entity.bestShippedDate) {
			entity.bestShippedDate = new Date(entity.bestShippedDate);
		}
		if (entity.maxShippedDate) {
			entity.maxShippedDate = new Date(entity.maxShippedDate);
		}
		if (entity.mustShippedDate) {
			entity.mustShippedDate = new Date(entity.mustShippedDate);
		}
		return entity;
	};
	var config = angular.extend({}, adamaResourceConfig, {
		getTimeline : {
			url : resourceApiRoot + '/:id/timeline',
			isArray : true,
			method : 'GET'
		},
		allNotPrintedShirtToSheetPdf : {
			url : resourceApiRoot + '/shirts/allNotPrintedToSheetPdf',
			method : 'GET',
			responseType : 'arraybuffer',
			transformResponse : binaryService.transformResponseToPdf
		},
		selectedShirtToSheetPdf : {
			url : resourceApiRoot + '/shirts/selectedToSheetPdf',
			method : 'GET',
			responseType : 'arraybuffer',
			transformResponse : binaryService.transformResponseToPdf
		},
		allQualityOkShirtToTagPdf : {
			url : resourceApiRoot + '/shirts/allQualityOkToTagPdf',
			method : 'GET',
			responseType : 'arraybuffer',
			transformResponse : binaryService.transformResponseToPdf
		},
		selectedShirtToTagPdf : {
			url : resourceApiRoot + '/shirts/selectedToTagPdf',
			method : 'GET',
			responseType : 'arraybuffer',
			transformResponse : binaryService.transformResponseToPdf
		},
		generateTagPdf : {
			url : resourceApiRoot + '/shirts/generateTagPdf',
			method : 'GET',
			responseType : 'arraybuffer',
			transformResponse : binaryService.transformResponseToPdf
		},
		allQualityOkShirtGeneratePdf : {
			url : resourceApiRoot + '/shirts/allQualityOkGeneratePdf',
			method : 'GET',
			responseType : 'arraybuffer',
			transformResponse : binaryService.transformResponseToPdf
		},
		exportAllShirts : {
			url : resourceApiRoot + '/shirts/exportAllShirts',
			method : 'GET',
			responseType : 'arraybuffer',
			transformResponse : binaryService.transformResponseToExcel
		},
		allNotPrintedFabricToSheetPdf : {
			url : resourceApiRoot + '/fabrics/allNotPrintedToSheetPdf',
			method : 'GET',
			responseType : 'arraybuffer',
			transformResponse : binaryService.transformResponseToPdf
		},
		selectedFabricToSheetPdf : {
			url : resourceApiRoot + '/fabrics/selectedToSheetPdf',
			method : 'GET',
			responseType : 'arraybuffer',
			transformResponse : binaryService.transformResponseToPdf
		},
		rerieveBillingInfoForAll : {
			url : resourceApiRoot + '/billingInfoForAllBillable',
			method : 'GET',
			responseType : 'arraybuffer',
			transformResponse : binaryService.transformResponseToExcel
		},
		retrieveBillingInfoForSelected : {
			url : resourceApiRoot + '/billingInfoForSelected',
			method : 'GET',
			responseType : 'arraybuffer',
			transformResponse : binaryService.transformResponseToExcel
		},
		massImportXls : {
			url : resourceApiRoot + '/excel',
			method : 'POST',
			params : {},
			headers : {
				'Content-Type' : undefined
			},
			transformRequest : function(data) {
				var formData = new window.FormData();
				var file = data.file;
				formData.append('file', file, file.name);
				return formData;
			}
		},
        moveToEvent: {
            url: resourceApiRoot + '/event',
            method: 'POST',
			params : {
				idList : '@idList',
				timelineEventStatus: '@timelineEventStatus'
			}
        },
		setWatch: {
			url : resourceApiRoot + '/:id/watchStatus/watch',
			method : 'POST',
			params : {
				id: '@id',
				comment: '@comment',
				mustShippedDate: '@mustShippedDate'
			}
		},
		setUrgent: {
			url : resourceApiRoot + '/:id/watchStatus/urgent',
			method : 'POST',
			params : {
				id: '@id',
				comment: '@comment',
				mustShippedDate: '@mustShippedDate'
			}
		},
		setUntracked: {
			url : resourceApiRoot + '/untrack?idList=:idList',
			method : 'POST',
			params : {
				idList : '@idList'
			}
		}
	});
	config.get.transformResponse = $http.defaults.transformResponse.concat(prepareObject);
	config.save.transformResponse = $http.defaults.transformResponse.concat(prepareObject);
	config.update.transformResponse = $http.defaults.transformResponse.concat(prepareObject);
	return $resource(resourceApiRoot + '/:id', {}, config);
});

'use strict';

angular.module('cottonMeApp').factory('Order', function($resource, $http, adamaConstant, adamaResourceConfig) {
	var resourceApiRoot = adamaConstant.apiBase + 'orders';
	var prepareObject = function(entity) {
		if (entity.invoiceDate) {
			entity.invoiceDate = new Date(entity.invoiceDate);
		}
		if (entity.paymentDate) {
			entity.paymentDate = new Date(entity.paymentDate);
		}
		return entity;
	};
	var config = angular.extend({}, adamaResourceConfig, {
		getProductList : {
			url : resourceApiRoot + '/:id/products',
			isArray : true,
			method : 'GET'
		},
		massImportXls : {
			url : resourceApiRoot + '/excel',
			method : 'POST',
			params : {},
			headers : {
				'Content-Type' : undefined
			},
			transformRequest : function(data) {
				var formData = new window.FormData();
				var file = data.file;
				formData.append('file', file, file.name);
				return formData;
			}
		}
	});
	config.get.transformResponse = $http.defaults.transformResponse.concat(prepareObject);
	config.save.transformResponse = $http.defaults.transformResponse.concat(prepareObject);
	config.update.transformResponse = $http.defaults.transformResponse.concat(prepareObject);
	return $resource(resourceApiRoot + '/:id', {}, config);
});

'use strict';

angular.module('cottonMeApp').factory('Options', function($resource, adamaConstant, adamaResourceConfig) {
	var resourceApiRoot = adamaConstant.apiBase + 'shirtDetailsChoices';
	var config = angular.extend({}, adamaResourceConfig, {});
	return $resource(resourceApiRoot + '/:id', {}, config);
});

'use strict';

angular.module('cottonMeApp').factory('Invoices', function($resource, adamaConstant, adamaResourceConfig) {
    var resourceApiRoot = adamaConstant.apiBase + 'invoices';
    var config = angular.extend({}, adamaResourceConfig, {
        getSettings: {
            url: resourceApiRoot + '/',
            method : 'GET'
        }
    });
    return $resource(resourceApiRoot + '/:id', {}, config);
});

'use strict';

angular.module('cottonMeApp').factory('FabricAdjustment', function ($resource, adamaConstant, adamaResourceConfig) {
  var resourceApiRoot = adamaConstant.apiBase + 'fabricadjustments';
  var config = angular.extend({}, adamaResourceConfig, {
    massImportXls: {
      url: resourceApiRoot + '/excel',
      method: 'POST',
      params: {},
      headers: {
        'Content-Type': undefined
      },
      transformRequest: function (data) {
        var formData = new window.FormData();
        var file = data.file;
        formData.append('file', file, file.name);
        return formData;
      }
    }
  });
  return $resource(resourceApiRoot + '/:id', {}, config);
});

'use strict';

angular.module('cottonMeApp').factory('Fabric', function($resource, adamaConstant, adamaResourceConfig) {
	var resourceApiRoot = adamaConstant.apiBase + 'fabrics';
	var config = angular.extend({}, adamaResourceConfig, {
		massImportXls: {
			url: resourceApiRoot + '/excel',
			method : 'POST',
			params : {},
			headers : {
				'Content-Type' : undefined
			},
			transformRequest : function(data) {
				var formData = new window.FormData();
				var file = data.file;
				formData.append('file', file, file.name);
				return formData;
			}
		}
	});
	return $resource(resourceApiRoot + '/:id', {}, config);
});

'use strict';

angular.module('cottonMeApp').factory('CustomerB2B', function($resource, adamaConstant, adamaResourceConfig) {
	var resourceApiRoot = adamaConstant.apiBase + 'customersB2B';
	var config = angular.extend({}, adamaResourceConfig, {
		findAllBillable : {
			url : resourceApiRoot + '/billable',
			isArray : true,
			method : 'GET'
		},
		massImportXls : {
			url : resourceApiRoot + '/excel',
			method : 'POST',
			params : {},
			headers : {
				'Content-Type' : undefined
			},
			transformRequest : function(data) {
				var formData = new window.FormData();
				var file = data.file;
				formData.append('file', file, file.name);
				return formData;
			}
		}
	});
	return $resource(resourceApiRoot + '/:id', {}, config);
});

'use strict';

angular.module('cottonMeApp').filter('qrcodeUrl', function(adamaConstant) {
	return function(input) {
		return adamaConstant.apiBase + 'QRCode' + '?data=' + input + '&size=120';
	};
});

'use strict';

angular.module('cottonMeApp').filter('inch', function($sce) {
	var division = 8;
	var possibleResults8 = [ //
		'', //
		'&#x215b;', // 1/8
		'&#xbc;', // 1/4
		'&#x215c;', // 3/8
		'&#xbd;', // 1/2
		'&#x215d;', // 5/8
		'&#xbe;', // 3/4
		'&#x215e;' // 7/8
	];
	var possibleResults16 = [ //
		'', //
		'<sup>1</sup>&frasl;<sub>16</sub>', //
		'&#x215b;', // 1/8
		'<sup>3</sup>&frasl;<sub>16</sub>', //
		'&#xbc;', // 1/4
		'<sup>5</sup>&frasl;<sub>16</sub>', //
		'&#x215c;', // 3/8
		'<sup>7</sup>&frasl;<sub>16</sub>', //
		'&#xbd;', // 1/2
		'<sup>9</sup>&frasl;<sub>16</sub>', //
		'&#x215d;', // 5/8
		'<sup>11</sup>&frasl;<sub>16</sub>', //
		'&#xbe;', // 3/4
		'<sup>13</sup>&frasl;<sub>16</sub>', //
		'<sup>7</sup>&frasl;<sub>8</sub>', //
		'&#x215e;' // 7/8
	];
	var possibleResults = possibleResults8;
	if (division === 16) {
		possibleResults = possibleResults16;
	}
	var toInches = function(cm) {
		var inches = cm * 0.3937008;
		var floatPart = Math.round((inches - Math.floor(inches)) * division);
		return {
			integerPart: Math.floor(inches) + Math.floor(floatPart / division),
			floatPart: floatPart % division
		};
	};
	var getFraction = function(division) {
		return possibleResults[division];
	};
	return function(input) {
		var inchesDetails = toInches(input);
		return $sce.trustAsHtml('' + inchesDetails.integerPart + ' ' + getFraction(inchesDetails.floatPart));
	};
});

'use strict';

angular.module('cottonMeApp').directive('dsUsers', function($parse, User) {
	return {
		scope: false,
		link: function(scope, element, attrs) {
			User.query({
				all: true
			}).$promise.then(function(entities) {
				$parse(attrs.data).assign(scope, entities);
			});
		}
	};
});

'use strict';

angular.module('cottonMeApp').directive('dsShirtDetailsChoiceOptions', function($parse, shirtDetailsChoiceService) {
	return {
		scope: false,
		link: function(scope, element, attrs) {
			scope.$watch(attrs.category, function() {
				shirtDetailsChoiceService.getDictionary().then(function(dict) {
					$parse(attrs.data).assign(scope, dict);
				});
			});
		}
	};
});

'use strict';

angular.module('cottonMeApp').directive('dsShippingPackageStatus', function($parse) {
	return {
		scope: false,
		link: function(scope, element, attrs) {
			var status = ['PACKAGE_PREPARED', 'PACKAGE_PRINT', 'PACKAGE_READY_FOR_SHIPPING', 'PACKAGE_SHIPPED', 'PACKAGE_DELIVERED', 'PACKAGE_CANCELLED'];
			$parse(attrs.data).assign(scope, status);
		}
	};
});

'use strict';

angular.module('cottonMeApp').directive('dsShippingPackageCarrier', function($parse) {
	return {
		scope: false,
		link: function(scope, element, attrs) {
			var status = ['DHL', 'COLISSIMO', 'CHRONOPOST', 'SF'];
			$parse(attrs.data).assign(scope, status);
		}
	};
});


'use strict';

angular.module('cottonMeApp').directive('dsProduct', function ($parse, Product) {
  return {
    scope: false,
    link: function (scope, element, attrs) {
      Product.query({
        size: 50
      }).$promise.then(function (entities) {
        $parse(attrs.data).assign(scope, entities);
      });
    }
  };
});

'use strict';

angular.module('cottonMeApp').directive('dsProductUnit', function($parse, adamaConstant) {
	return {
		scope : false,
		link : function(scope, element, attrs) {
			$parse(attrs.data).assign(scope, adamaConstant.productUnits);
		}
	};
});

'use strict';

angular.module('cottonMeApp').directive('dsProductStates', function($parse) {
	return {
		scope: false,
		link: function(scope, element, attrs) {
			var status = ['CANCEL', 'START', 'PRINT', 'FABRIC_CUT', 'FABRIC_CUT_WAIT', 'FABRIC_WAITING',
			'FABRIC_NO_MORE','OFFICE_OUT', 'WORKSHOP_IN', 'PATTERN_CUT', 'PATTERN_MODIFOK',
			'PATTERN_OK', 'PATTERN_PROB', 'WORKSHOP_OUT', 'INITIALS', 'QUALITY_OK', 'TAGGED',
			'PACKAGED', 'READY_FOR_SHIPPING', 'SHIPPED', 'DELIVERED', 'DELIVERED_TO_SHOP'];
			$parse(attrs.data).assign(scope, status);
		}
	};
});

'use strict';

angular.module('cottonMeApp').directive('dsOptions', function($parse, Options) {
	return {
		scope: false,
		link: function(scope, element, attrs) {
			Options.query({
				all: true
			}).$promise.then(function(entities) {
				$parse(attrs.data).assign(scope, entities);
			});
		}
	};
});

'use strict';

angular.module('cottonMeApp').directive('dsFabricAdjustment', function ($parse, FabricAdjustment) {
  return {
    scope: false,
    link: function (scope, element, attrs) {
      FabricAdjustment.query({
        all: true
      }).$promise.then(function (entities) {
        $parse(attrs.data).assign(scope, entities);
      });
    }
  };
});

'use strict';

angular.module('cottonMeApp').directive('dsFabric', function ($parse, Fabric) {
  return {
    scope: false,
    link: function (scope, element, attrs) {
      Fabric.query({
        all: true
      }).$promise.then(function (entities) {
        $parse(attrs.data).assign(scope, entities);
      });
    }
  };
});

'use strict';

angular.module('cottonMeApp').directive('dsFabricRange', function($parse, adamaConstant) {
	return {
		scope : false,
		link : function(scope, element, attrs) {
			$parse(attrs.data).assign(scope, adamaConstant.fabricRanges);
		}
	};
});

'use strict';

angular.module('cottonMeApp').directive('dsCustomersB2B', function($parse, CustomerB2B) {
	return {
		scope: false,
		link: function(scope, element, attrs) {
			CustomerB2B.query({
				all: true
			}).$promise.then(function(entities) {
				$parse(attrs.data).assign(scope, entities);
			});
		}
	};
});

'use strict';

angular.module('cottonMeApp').directive('dsCustomersB2BBillable', function($parse, CustomerB2B) {
	return {
		scope: false,
		link: function(scope, element, attrs) {
			CustomerB2B.findAllBillable().$promise.then(function(entities) {
				$parse(attrs.data).assign(scope, entities);
			});
		}
	};
});

'use strict';

angular.module('cottonMeApp').config(function(adamaConstant) {
	adamaConstant.appModule = 'cottonMeApp';
	adamaConstant.fabricRanges = [ //
	'A', //
	'B', //
	'C', //
	'D', //
	'E', //
	'AA', //
	'AAA' //
	];
	adamaConstant.productUnits = [ //
	'SHIRT_MAKING_STANDARD', //
	'SHIRT_MAKING_PACK', //
	'SHIRT_MAKING_B2B', //
	'SHIRT_REMAKE', //
	'SHIRT_FIX', //
	'COLLAR_MAKING', //
	'CUFF_MAKING', //
	'FREE_REMAKE', //
	'INSPECTION', //
	'SAMPLE', //
	'FABRIC_CUT' //
	];
});

angular.module('cottonMeApp').config(function($translateProvider) {
	$translateProvider.registerAvailableLanguageKeys(['en', 'fr', 'zh'], {
		'en_*': 'en',
		'fr_*': 'fr',
		'zh_*': 'zh'
	});
	$translateProvider.determinePreferredLanguage();

	$translateProvider.translations('fr', {
		'APP_NAME' : 'CottonMe',
		'APP_NAME_SHORT' : 'CM',
		'MENU_DIVIDER_DATA' : 'Données',
		'MENU_DIVIDER_SECURITY' : 'Sécurité',
		'EVENT_STATUS_CANCEL' : 'Annulé',
		'EVENT_STATUS_START' : 'Nouveau',
		'EVENT_STATUS_PRINT' : 'Imprimé',
		'EVENT_STATUS_FABRIC_CUT' : 'Découpe tissus',
		'EVENT_STATUS_FABRIC_CUT_WAIT' : 'Tissus en attente',
		'EVENT_STATUS_FABRIC_WAITING' : 'Attente du tissus',
		'EVENT_STATUS_FABRIC_NO_MORE' : 'Pénurie tissus',
		'EVENT_STATUS_OFFICE_OUT' : 'Sortie bureau',
		'EVENT_STATUS_WORKSHOP_IN' : 'Entrée atelier',
		'EVENT_STATUS_PATTERN_CUT' : 'Découpe patron',
		'EVENT_STATUS_PATTERN_MODIF_OK' : 'Modification patron OK',
		'EVENT_STATUS_PATTERN_OK' : 'Patron OK',
		'EVENT_STATUS_PATTERN_PROB' : 'Problème patron',
		'EVENT_STATUS_WORKSHOP_OUT' : 'Sortie atelier',
		'EVENT_STATUS_INITIALS' : 'Intiales',
		'EVENT_STATUS_QUALITY_OK' : 'Qualité OK',
		'EVENT_STATUS_TAGGED' : 'Etiquetage',
		'EVENT_STATUS_PACKAGED' : 'Packaging',
		'EVENT_STATUS_READY_FOR_SHIPPING' : 'Prêt pour envoi',
		'EVENT_STATUS_SHIPPED' : 'Envoyé',
		'EVENT_STATUS_DELIVERED_TO_SHOP' : 'Livré en magasin',
		'EVENT_STATUS_DELIVERED' : 'Livré',
		'EVENT_STATUS_PACKAGE_PREPARED' : 'Empaqueté',
		'EVENT_STATUS_PACKAGE_PRINT' : 'Etiquette envoi',
		'EVENT_STATUS_PACKAGE_READY_FOR_SHIPPING' : 'Prêt pour envoi',
		'EVENT_STATUS_PACKAGE_SHIPPED': 'En cours de livraison',
		'EVENT_STATUS_PACKAGE_DELIVERED' : 'Livré'
	});

	$translateProvider.translations('en', {
		'APP_NAME' : 'CottonMe',
		'APP_NAME_SHORT' : 'CM',
		'MENU_DIVIDER_DATA' : 'Data',
		'MENU_DIVIDER_SECURITY' : 'Security',
		'EVENT_STATUS_CANCEL' : 'Canceled',
		'EVENT_STATUS_START' : 'Start',
		'EVENT_STATUS_PRINT' : 'Printed',
		'EVENT_STATUS_FABRIC_CUT' : 'Fabric cut',
		'EVENT_STATUS_FABRIC_CUT_WAIT' : 'Cut and wait',
		'EVENT_STATUS_FABRIC_WAITING' : 'Waiting for fabric',
		'EVENT_STATUS_FABRIC_NO_MORE' : 'No more fabric',
		'EVENT_STATUS_OFFICE_OUT' : 'Office out',
		'EVENT_STATUS_WORKSHOP_IN' : 'Factory in',
		'EVENT_STATUS_PATTERN_CUT' : 'Pattern cut',
		'EVENT_STATUS_PATTERN_MODIF_OK' : 'Pattern modification OK',
		'EVENT_STATUS_PATTERN_OK' : 'Pattern OK',
		'EVENT_STATUS_PATTERN_PROB' : 'Pattern problem',
		'EVENT_STATUS_WORKSHOP_OUT' : 'Factory out',
		'EVENT_STATUS_INITIALS' : 'Initials',
		'EVENT_STATUS_QUALITY_OK' : 'Quality OK',
		'EVENT_STATUS_TAGGED' : 'Tagged',
		'EVENT_STATUS_PACKAGED' : 'Packaged',
		'EVENT_STATUS_READY_FOR_SHIPPING' : 'Ready for shipping',
		'EVENT_STATUS_SHIPPED' : 'Shipped',
		'EVENT_STATUS_DELIVERED_TO_SHOP' : 'Delivered to shop',
		'EVENT_STATUS_DELIVERED' : 'Delivered',
		'EVENT_STATUS_PACKAGE_PREPARED' : 'Packaged',
		'EVENT_STATUS_PACKAGE_PRINT' : 'Shipping tag',
		'EVENT_STATUS_PACKAGE_READY_FOR_SHIPPING' : 'Ready for shipping',
		'EVENT_STATUS_PACKAGE_SHIPPED': 'Shipped',
		'EVENT_STATUS_PACKAGE_DELIVERED' : 'Delivered'
	});

	$translateProvider.translations('zh', {
		'APP_NAME' : 'CottonMe',
		'APP_NAME_SHORT' : 'CM',
		'MENU_DIVIDER_DATA' : 'Data',
		'MENU_DIVIDER_SECURITY' : 'Security',
		'EVENT_STATUS_CANCEL' : 'Canceled',
		'EVENT_STATUS_START' : 'Start',
		'EVENT_STATUS_PRINT' : 'Printed',
		'EVENT_STATUS_FABRIC_CUT' : 'Fabric cut',
		'EVENT_STATUS_FABRIC_CUT_WAIT' : 'Cut and wait',
		'EVENT_STATUS_FABRIC_WAITING' : 'Waiting for fabric',
		'EVENT_STATUS_FABRIC_NO_MORE' : 'No more fabric',
		'EVENT_STATUS_OFFICE_OUT' : 'Office out',
		'EVENT_STATUS_WORKSHOP_IN' : 'Factory in',
		'EVENT_STATUS_PATTERN_CUT' : 'Pattern cut',
		'EVENT_STATUS_PATTERN_MODIF_OK' : 'Pattern modification OK',
		'EVENT_STATUS_PATTERN_OK' : 'Pattern OK',
		'EVENT_STATUS_PATTERN_PROB' : 'Pattern problem',
		'EVENT_STATUS_WORKSHOP_OUT' : 'Factory out',
		'EVENT_STATUS_INITIALS' : 'Initials',
		'EVENT_STATUS_QUALITY_OK' : 'Quality OK',
		'EVENT_STATUS_TAGGED' : 'Tagged',
		'EVENT_STATUS_PACKAGED' : 'Packaged',
		'EVENT_STATUS_READY_FOR_SHIPPING' : 'Ready for shipping',
		'EVENT_STATUS_SHIPPED' : 'Shipped',
		'EVENT_STATUS_DELIVERED_TO_SHOP' : 'Delivered to shop',
		'EVENT_STATUS_DELIVERED' : 'Delivered',
		'EVENT_STATUS_PACKAGE_PREPARED' : 'Packaged',
		'EVENT_STATUS_PACKAGE_PRINT' : 'Shipping tag',
		'EVENT_STATUS_PACKAGE_READY_FOR_SHIPPING' : 'Ready for shipping',
		'EVENT_STATUS_PACKAGE_SHIPPED': 'Shipped',
		'EVENT_STATUS_PACKAGE_DELIVERED' : 'Delivered'
	});
});

angular.module('cottonMeApp').config(function(menuServiceProvider) {
	menuServiceProvider.addItem({
		labelKey : 'MENU_DIVIDER_DATA',
		divider : true,
		rank : 200
	});
	menuServiceProvider.addItem({
		labelKey : 'MENU_DIVIDER_SECURITY',
		divider : true,
		rank : 900
	});
});

// angular.module('cottonMeApp').run(function($rootScope, $state) {
// $rootScope.$on('$stateChangeStart', function(event, toState) {
// if (toState.name === 'app.main') {
// event.preventDefault();
// $state.go('app.calendar');
// }
// });
// });

angular.module('cottonMeApp').config(function(adamaConstant) {
	adamaConstant.authorities = [ 'ROLE_WORKSHOP', 'ROLE_SHIFU', 'ROLE_ADMIN', 'ROLE_SHOP', 'ROLE_CLIENT'];
	adamaConstant.adamaWebToolkitTemplateUrl.users = 'modules/user/user-list.html';
	adamaConstant.adamaWebToolkitTemplateUrl.userEdit = 'modules/user/user-edit.html';
	adamaConstant.adamaWebToolkitTemplateUrl.userCreate = 'modules/user/user-edit.html';
	adamaConstant.adamaWebToolkitTemplateUrl.userView = 'modules/user/user-view.html';
});

angular.module('cottonMeApp').run(function($rootScope, $log) {
	$rootScope.$on('$stateChangeError', function(event, toState, toParams, fromState, fromParams, error) {
		$log.getInstance('app.event.$stateChangeError').error('$stateChangeError', error);
	});
});

angular.module('cottonMeApp').run(function(ngTableDefaults) {
	ngTableDefaults.settings = angular.extend({}, ngTableDefaults.settings, {
		counts : [ 50, 100, 300 ]
	});
	ngTableDefaults.params = angular.extend({}, ngTableDefaults.params, {
		// init : show first page
		page : 1,
		// init : count per page
		count : 100
	});
});
